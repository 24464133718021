import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    Box,
    Paper,
    Typography,
    TextField,
    Button,
    Alert,
} from '@mui/material';
import api from '../api';
import logo from '../assets/logos/Logo Preto com Laranja.svg';

const ResetarSenha = () => {
    const [novaSenha, setNovaSenha] = useState('');
    const [confirmarSenha, setConfirmarSenha] = useState('');
    const [mensagem, setMensagem] = useState('');
    const [severity, setSeverity] = useState('info');
    const { token } = useParams();
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMensagem('');

        // Validações
        if (novaSenha.length < 6) {
            setMensagem('A senha deve ter no mínimo 6 caracteres');
            setSeverity('error');
            return;
        }

        if (novaSenha !== confirmarSenha) {
            setMensagem('As senhas não coincidem');
            setSeverity('error');
            return;
        }

        try {
            const response = await api.post('/auth/redefinir-senha', {
                token,
                novaSenha
            });

            if (response.data.sucesso) {
                setMensagem('Senha redefinida com sucesso! Redirecionando para o login...');
                setSeverity('success');
                setTimeout(() => {
                    navigate('/login');
                }, 3000);
            }
        } catch (error) {
            setMensagem(
                error.response?.data?.mensagem ||
                'Erro ao redefinir senha. Por favor, tente novamente.'
            );
            setSeverity('error');
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                backgroundColor: 'background.default',
                padding: '20px',
            }}
        >
            <Paper
                sx={{
                    padding: '30px',
                    maxWidth: '400px',
                    width: '100%',
                    textAlign: 'center',
                }}
                elevation={3}
            >
                <Box
                    sx={{
                        padding: 2,
                        textAlign: 'center',
                        borderBottom: '1px solid #ddd'
                    }}
                >
                    <img
                        src={logo}
                        alt="Logo"
                        style={{ width: 200, objectFit: 'contain', paddingTop: 10 }}
                    />
                </Box>

                <Typography variant="h5" color="primary" gutterBottom mt={5}>
                    Redefinir Senha
                </Typography>

                <form onSubmit={handleSubmit}>
                    <TextField
                        label="Nova Senha"
                        type="password"
                        value={novaSenha}
                        onChange={(e) => setNovaSenha(e.target.value)}
                        fullWidth
                        required
                        margin="normal"
                    />
                    <TextField
                        label="Confirmar Nova Senha"
                        type="password"
                        value={confirmarSenha}
                        onChange={(e) => setConfirmarSenha(e.target.value)}
                        fullWidth
                        required
                        margin="normal"
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        sx={{ mt: 3 }}
                    >
                        Redefinir Senha
                    </Button>
                </form>

                {mensagem && (
                    <Alert severity={severity} sx={{ mt: 2 }}>
                        {mensagem}
                    </Alert>
                )}

                <Button
                    variant="text"
                    color="primary"
                    onClick={() => navigate('/login')}
                    sx={{ mt: 2 }}
                >
                    Voltar para Login
                </Button>
            </Paper>
        </Box>
    );
};

export default ResetarSenha;
