import { alpha } from '@mui/material/styles';
import { STATUS_OPTIONS, PAYMENT_STATUS } from './constants';

export const isPaymentReceived = (pagamento, asaas) =>
    asaas?.paymentStatus ?
        Object.values(PAYMENT_STATUS).includes(asaas.paymentStatus) :
        pagamento?.includes('Recebido') || pagamento?.includes('Confirmado');

export const getRowBackgroundColor = (linha, theme) => {
    if (linha.Status === STATUS_OPTIONS.CANCELADO) {
        return alpha(theme.palette.error.main, 0.1);
    }
    if (linha.Status === STATUS_OPTIONS.CONCLUIDO || isPaymentReceived(linha.Pagamento, linha.asaas)) {
        return alpha(theme.palette.green.main, 0.1);
    }
    if (linha.boolean) {
        return alpha(theme.palette.alert.main, 0.1);
    }
    return theme.palette.background.paper;
};
