import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
    ButtonGroup,
    IconButton,
    Tooltip,
} from "@mui/material";
import {
    QrCode as QrCodeIcon,
    Receipt as ReceiptIcon,
    CreditCard as CreditCardIcon,
    Cancel as CancelIcon,
    Restore as RestoreIcon,
    Visibility as VisibilityIcon,
} from "@mui/icons-material";

const PaymentActions = ({ linha, readOnly, handlePaymentAction, allData }) => {
    const { ID, asaas } = linha;
    const hasPayment = asaas?.paymentId;

    // Use allData for additional context if needed
    const paymentContext = useMemo(() => {
        if (!allData || !ID) return null;

        // Find related payments or other contextual data
        const relatedPayments = allData.filter(item =>
            item.ID !== ID &&
            item.Cliente === linha.Cliente
        );

        return {
            hasRelatedPayments: relatedPayments.length > 0,
            relatedPaymentsStatus: relatedPayments.map(p => p.asaas?.paymentStatus)
        };
    }, [allData, ID, linha.Cliente]);

    if (hasPayment) {
        const canRestore = asaas?.paymentStatus === 'CANCELLED';
        const canCancel = !['CANCELLED', 'RECEIVED', 'CONFIRMED'].includes(asaas?.paymentStatus);

        return (
            <ButtonGroup size="small" sx={{ gap: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Tooltip title="Ver Detalhes">
                    <IconButton onClick={() => handlePaymentAction(ID, 'view')}>
                        <VisibilityIcon />
                    </IconButton>
                </Tooltip>
                {!readOnly && (
                    <>
                        {canCancel && (
                            <Tooltip title="Cancelar Cobrança">
                                <IconButton onClick={() => handlePaymentAction(ID, 'cancel')}>
                                    <CancelIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                        {canRestore && (
                            <Tooltip title="Restaurar Cobrança">
                                <IconButton onClick={() => handlePaymentAction(ID, 'restore')}>
                                    <RestoreIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                    </>
                )}
            </ButtonGroup>
        );
    }

    if (!readOnly) {
        const actions = [
            { type: 'pix', title: 'Gerar PIX', Icon: QrCodeIcon },
            { type: 'boleto', title: 'Gerar Boleto', Icon: ReceiptIcon },
            { type: 'credit_card', title: 'Cartão de Crédito', Icon: CreditCardIcon },
        ];

        // Use payment context to potentially modify available actions
        const availableActions = actions.filter(action => {
            if (!paymentContext) return true;

            // Add any context-based filtering logic here
            // For example, disable certain payment methods based on customer history
            return true;
        });

        return (
            <ButtonGroup size="small">
                {availableActions.map(({ type, title, Icon }) => (
                    <Tooltip key={type} title={title}>
                        <IconButton onClick={() => handlePaymentAction(ID, type)}>
                            <Icon />
                        </IconButton>
                    </Tooltip>
                ))}
            </ButtonGroup>
        );
    }

    return null;
};

PaymentActions.propTypes = {
    linha: PropTypes.shape({
        ID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        Cliente: PropTypes.string,
        asaas: PropTypes.shape({
            paymentId: PropTypes.string,
            paymentStatus: PropTypes.string,
        }),
    }).isRequired,
    readOnly: PropTypes.bool.isRequired,
    handlePaymentAction: PropTypes.func.isRequired,
    allData: PropTypes.arrayOf(PropTypes.object), // Add prop type for allData
};

// Improve memoization to consider all props and relevant data changes
export default React.memo(PaymentActions, (prevProps, nextProps) => {
    // Check if the row data changed
    if (prevProps.linha.ID !== nextProps.linha.ID ||
        prevProps.linha.Cliente !== nextProps.linha.Cliente ||
        prevProps.linha.asaas?.paymentId !== nextProps.linha.asaas?.paymentId ||
        prevProps.linha.asaas?.paymentStatus !== nextProps.linha.asaas?.paymentStatus) {
        return false;
    }

    // Check if readOnly status changed
    if (prevProps.readOnly !== nextProps.readOnly) {
        return false;
    }

    // Check if the payment action handler changed
    if (prevProps.handlePaymentAction !== nextProps.handlePaymentAction) {
        return false;
    }

    // Check if the overall data array reference changed
    if (prevProps.allData !== nextProps.allData) {
        return false;
    }

    return true;
});
