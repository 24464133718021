import React, { useState } from "react";
import {
  Autocomplete,
  TextField,
  Typography,
  Box,
  Paper,
  IconButton,
  Grid,
  Card,
  CardContent,
  Chip,
  useTheme,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import ConfirmationModal from "./ConfirmationModal";
import AlertaReutilizavel from "./AlertaReutilizavel";

export const ServicoList = ({
  servicos,
  novoPedido,
  handleServicoAdd,
  handleQuantidadeChange,
  handleDeleteServico,
  disabled,
}) => {
  const theme = useTheme();
  const [modalAberto, setModalAberto] = useState(false);
  const [alerta, setAlerta] = useState({ mensagem: "", tipo: "info", aberto: false });
  const [servicoSelecionado, setServicoSelecionado] = useState(null);

  const handleOpenModal = (index) => {
    setServicoSelecionado(index);
    setModalAberto(true);
  };

  const handleCloseModal = () => {
    setModalAberto(false);
    setServicoSelecionado(null);
  };

  const handleConfirmDelete = () => {
    if (servicoSelecionado !== null) {
      handleDeleteServico(servicoSelecionado);
      setModalAberto(false);
      setServicoSelecionado(null);
      setAlerta({ mensagem: "Serviço removido com sucesso!", tipo: "success", aberto: true });
    }
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: theme.palette.background.elevated,
          borderRadius: "8px",
          padding: "16px",
          mb: 3,
          boxShadow: theme.shadows[1],
        }}
      >
        <Box display="flex" alignItems="center">
          <PhotoCamera sx={{ fontSize: 20, color: "primary.main", mr: 2 }} />
          <Typography variant="h6" sx={{ fontWeight: "bold", color: theme.palette.text.primary }}>
            Serviços do Pedido
          </Typography>
        </Box>
      </Box>

      {/* Área de seleção de serviço */}
      <Box sx={{ mb: 3, backgroundColor: theme.palette.background.elevated, p: 2, borderRadius: '8px' }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <Autocomplete
              options={servicos}
              getOptionLabel={(option) => `${option.nome} - R$${option.preco.toFixed(2)}`}
              onChange={(event, newValue) => handleServicoAdd(event, newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Adicionar Serviço"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <>
                        <AddCircleIcon sx={{ color: 'primary.main', mr: 1 }} />
                        {params.InputProps.startAdornment}
                      </>
                    ),
                  }}
                />
              )}
              disabled={disabled}
            />
          </Grid>
        </Grid>
      </Box>

      {/* Lista de serviços selecionados */}
      <Grid container spacing={2}>
        {novoPedido.servicos.map((servico, index) => (
          <Grid item xs={12} key={index}>
            <Card
              sx={{
                borderRadius: '8px',
                backgroundColor: theme.palette.background.paper,
                transition: 'transform 0.2s, box-shadow 0.2s',
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: theme.shadows[4],
                },
              }}
            >
              <CardContent>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="h6" sx={{ color: theme.palette.text.primary, fontWeight: 500 }}>
                      {servico.servicoId?.nome || "Serviço não encontrado"}
                    </Typography>
                    <Chip
                      label={`R$ ${servico.servicoId?.preco?.toFixed(2) || '0.00'} / unidade`}
                      sx={{
                        mt: 1,
                        backgroundColor: theme.palette.background.elevated,
                        color: theme.palette.primary.main,
                        fontWeight: 500
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      type="number"
                      label="Quantidade"
                      value={servico.quantidade}
                      onChange={(e) => handleQuantidadeChange(index, Number(e.target.value))}
                      variant="outlined"
                      size="small"
                      fullWidth
                      InputProps={{ inputProps: { min: 1 } }}
                      disabled={disabled}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <Typography
                      sx={{
                        color: theme.palette.primary.main,
                        fontWeight: 'bold',
                        fontSize: '1.1rem',
                        textAlign: { xs: 'left', sm: 'right' }
                      }}
                    >
                      R$ {servico.subtotal.toFixed(2)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={1} sx={{ textAlign: 'right' }}>
                    <IconButton
                      color="error"
                      onClick={() => handleOpenModal(index)}
                      disabled={disabled}
                      sx={{
                        '&:hover': {
                          backgroundColor: theme.palette.error.light,
                        },
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <ConfirmationModal
        show={modalAberto}
        handleClose={handleCloseModal}
        handleConfirm={handleConfirmDelete}
        title="Confirmar Exclusão"
        body="Tem certeza que deseja remover este serviço?"
      />

      <AlertaReutilizavel
        mensagem={alerta.mensagem}
        tipo={alerta.tipo}
        aberto={alerta.aberto}
        onFechar={() => setAlerta({ ...alerta, aberto: false })}
      />
    </Box>
  );
};

export default ServicoList;
