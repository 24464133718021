import React, { useState } from 'react';
import {
    Box,
    Typography,
    Paper,
    Chip,
    IconButton,
    useTheme,
    Tooltip,
    Button
} from '@mui/material';
import {
    Visibility,
    AccessTime,
    CheckCircle,
    Cancel,
    RadioButtonUncheckedOutlined as Circle,
    Payment
} from '@mui/icons-material';
import OrderDetailsModal from './OrderDetailsModal';

const UserOrders = ({ pedidos = [], onViewOrder }) => {
    const theme = useTheme();
    const [selectedOrder, setSelectedOrder] = useState(null);

    const getStatusConfig = (status) => {
        const configs = {
            'aberto': {
                color: theme.palette.info.main,
                icon: Circle,
                label: 'Aberto'
            },
            'em andamento': {
                color: theme.palette.alert.main,
                icon: AccessTime,
                label: 'Em andamento'
            },
            'concluido': {
                color: theme.palette.green.main,
                icon: CheckCircle,
                label: 'Concluído'
            },
            'cancelado': {
                color: theme.palette.error.main,
                icon: Cancel,
                label: 'Cancelado'
            }
        };
        return configs[status] || configs['aberto'];
    };

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        }).format(value || 0);
    };

    const getPaymentStatusChip = (statusPagamento) => {
        const configs = {
            'pendente': {
                color: theme.palette.warning.main,
                label: 'Pagamento Pendente'
            },
            'pago': {
                color: theme.palette.success.main,
                label: 'Pago'
            },
            'pago_com_saldo': {
                color: theme.palette.success.main,
                label: 'Pago com Saldo'
            },
            'pago_parcial_saldo': {
                color: theme.palette.info.main,
                label: 'Pago Parcialmente'
            }
        };
        const config = configs[statusPagamento] || configs['pendente'];
        return (
            <Chip
                label={config.label}
                size="small"
                sx={{
                    bgcolor: theme.palette.mode === 'dark'
                        ? 'rgba(255,255,255,0.05)'
                        : 'rgba(0,0,0,0.05)',
                    color: config.color,
                    border: `1px solid ${config.color}`
                }}
            />
        );
    };

    if (!Array.isArray(pedidos) || pedidos.length === 0) {
        return (
            <Box p={3}>
                <Box
                    sx={{
                        textAlign: 'center',
                        py: 8,
                        color: theme.palette.text.secondary
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: theme.typography.fontFamily,
                            mb: 1
                        }}
                    >
                        Nenhum pedido encontrado
                    </Typography>
                </Box>
            </Box>
        );
    }

    const handleViewOrder = (pedido) => {
        setSelectedOrder(pedido);
    };

    return (
        <Box
            p={3}
        >
            {pedidos.map((pedido) => {
                const statusConfig = getStatusConfig(pedido?.status);
                const StatusIcon = statusConfig.icon;

                return (
                    <Paper
                        key={pedido?._id}
                        elevation={0}
                        sx={{
                            mb: 2,
                            background: theme.palette.background.paper,
                            borderRadius: '16px',
                            border: `1px solid ${theme.palette.divider}`,
                            transition: 'transform 0.3s, box-shadow 0.3s',
                            '&:hover': {
                                transform: 'translateY(-2px)',
                                boxShadow: theme.shadows[4]
                            }
                        }}
                    >
                        <Box
                            sx={{
                                p: 3,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                flexWrap: 'wrap',
                                gap: 2
                            }}
                        >
                            <Box sx={{ flex: 1, minWidth: '200px' }}>
                                <Typography
                                    sx={{
                                        color: theme.palette.text.primary,
                                        fontFamily: theme.typography.fontFamily,
                                        fontWeight: 500,
                                        mb: 1
                                    }}
                                >
                                    Pedido #{pedido?._id?.slice(-4).toUpperCase() || 'N/A'}
                                </Typography>
                                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', flexWrap: 'wrap' }}>
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            color: theme.palette.text.secondary,
                                            fontFamily: theme.typography.fontFamily
                                        }}
                                    >
                                        {pedido?.createdAt ? new Date(pedido.createdAt).toLocaleDateString('pt-BR', {
                                            day: '2-digit',
                                            month: 'long',
                                            year: 'numeric'
                                        }) : 'Data não disponível'}
                                    </Typography>
                                    {getPaymentStatusChip(pedido?.statusPagamento)}
                                </Box>
                            </Box>

                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 2,
                                flexWrap: 'wrap'
                            }}>
                                <Chip
                                    icon={<StatusIcon />}
                                    label={statusConfig.label}
                                    size="small"
                                    sx={{
                                        bgcolor: theme.palette.mode === 'dark'
                                            ? 'rgba(255,255,255,0.05)'
                                            : 'rgba(0,0,0,0.05)',
                                        color: statusConfig.color,
                                        border: `1px solid ${statusConfig.color}`,
                                        '& .MuiChip-icon': {
                                            color: statusConfig.color
                                        }
                                    }}
                                />

                                <Typography
                                    sx={{
                                        color: theme.palette.primary.main,
                                        fontFamily: theme.typography.fontFamily,
                                        fontWeight: 600,
                                        minWidth: '100px',
                                        textAlign: 'right'
                                    }}
                                >
                                    {formatCurrency(pedido?.valorPagar)}
                                </Typography>

                                {pedido?.asaas?.paymentLink && pedido?.statusPagamento === 'pendente' && (
                                    <Button
                                        startIcon={<Payment />}
                                        variant="outlined"
                                        color="primary"
                                        size="small"
                                        href={pedido.asaas.paymentLink}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Pagar
                                    </Button>
                                )}

                                <Tooltip title="Detalhes do Pedido">
                                    <IconButton
                                        onClick={() => handleViewOrder(pedido)}
                                        sx={{
                                            color: theme.palette.text.secondary,
                                            '&:hover': {
                                                color: theme.palette.primary.main,
                                                bgcolor: theme.palette.mode === 'dark'
                                                    ? 'rgba(235, 91, 37, 0.1)'
                                                    : 'rgba(235, 91, 37, 0.05)'
                                            }
                                        }}
                                    >
                                        <Visibility />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Box>
                    </Paper>
                );
            })}

            <OrderDetailsModal
                open={!!selectedOrder}
                onClose={() => setSelectedOrder(null)}
                pedido={selectedOrder}
            />
        </Box>
    );
};

export default UserOrders;
