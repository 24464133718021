import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  Paper,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { Save, Cancel, Delete } from "@mui/icons-material";
import ServicoList from "../components/ServicoList";
import ResumoPedido from "../components/ResumoPedido";
import AlertaReutilizavel from "../components/AlertaReutilizavel";
import ConfirmationModal from "../components/ConfirmationModal";
import { useCadastroPedidos } from "../hooks/useCadastroPedidos";
import ZoomPlusCard from "../components/ZoomPlusCard";
import api from "../api";

const EditarPedido = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const {
    servicos,
    novoPedido,
    clienteZoomPlus,
    alerta,
    totais,
    dataEntrega,
    handleServicoAdd,
    handleQuantidadeChange,
    handleDeleteServico,
    handleUrgenciaChange,
    setNovoPedido,
    loadPedidoForEdit,
    setAlerta,
    fetchData,
    handleSubmit
  } = useCadastroPedidos('edit');

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (id) {
      loadPedidoForEdit(id);
    }
  }, [id, loadPedidoForEdit]);

  const handleDelete = async () => {
    try {
      await api.delete(`/pedidos/${id}`);
      setAlerta({
        mensagem: "Pedido excluído com sucesso!",
        tipo: "success",
        aberto: true,
      });
      navigate("/cadastro-pedidos");
    } catch (error) {
      setAlerta({
        mensagem: "Erro ao excluir pedido.",
        tipo: "error",
        aberto: true,
      });
    }
  };

  const navigateAfterSuccess = () => {
    setTimeout(() => {
      navigate("/cadastro-pedidos");
    }, 2000);
  };

  const handleZoomPlusBenefitChange = (benefitType) => {
    setNovoPedido((prev) => ({
      ...prev,
      zoomPlus: {
        ...prev.zoomPlus,
        beneficiosUtilizados: {
          ...prev.zoomPlus.beneficiosUtilizados,
          [benefitType]: !prev.zoomPlus.beneficiosUtilizados?.[benefitType]
        }
      }
    }));
  };

  return (
    <Box sx={{ padding: "20px" }}>
      <Typography variant="h4" color="primary" gutterBottom>
        Editar Pedido
      </Typography>

      {alerta.aberto && (
        <AlertaReutilizavel
          mensagem={alerta.mensagem}
          tipo={alerta.tipo}
          aberto={alerta.aberto}
          onFechar={() => setAlerta({ ...alerta, aberto: false })}
        />
      )}

      <form onSubmit={(e) => handleSubmit(e, id, navigateAfterSuccess)}>
        <Paper sx={{ p: 2, mb: 2 }}>
          <Typography variant="h6" gutterBottom>
            Cliente: {novoPedido.cliente?.nome || "Cliente não encontrado"}
          </Typography>
          {clienteZoomPlus && (
            <ZoomPlusCard
              clienteZoomPlus={clienteZoomPlus}
              totais={totais}
            />
          )}
        </Paper>

        <Paper sx={{ p: 2, mb: 2 }}>
          <ServicoList
            servicos={servicos}
            novoPedido={novoPedido}
            handleServicoAdd={handleServicoAdd}
            handleQuantidadeChange={handleQuantidadeChange}
            handleDeleteServico={handleDeleteServico}
          />
        </Paper>

        <Paper sx={{ p: 2, mb: 2 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={novoPedido.isUrgent}
                onChange={(e) => handleUrgenciaChange(e.target.checked)}
              />
            }
            label="Ativar Taxa de Urgência"
          />
          {clienteZoomPlus?.planoAtivo && (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={novoPedido.zoomPlus?.beneficiosUtilizados?.taxaUrgenciaGratis || false}
                    onChange={() => handleZoomPlusBenefitChange('taxaUrgenciaGratis')}
                    disabled={!novoPedido.isUrgent}
                  />
                }
                label="Usar Taxa de Urgência Gratuita"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={novoPedido.zoomPlus?.beneficiosUtilizados?.modeloCompletaGratis || false}
                    onChange={() => handleZoomPlusBenefitChange('modeloCompletaGratis')}
                  />
                }
                label="Usar Modelo Completa Grátis"
              />
            </>
          )}
        </Paper>

        <Paper sx={{ p: 2, mb: 2 }}>
          <ResumoPedido
            totais={totais}
            dataEntrega={dataEntrega}
            clienteZoomPlus={clienteZoomPlus}
          />
        </Paper>

        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<Save />}
              sx={{ mr: 2 }}
            >
              Salvar Alterações
            </Button>
            <Button
              variant="outlined"
              color="error"
              onClick={() => setShowDeleteModal(true)}
              startIcon={<Delete />}
            >
              Excluir Pedido
            </Button>
          </Box>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => navigate("/cadastro-pedidos")}
            startIcon={<Cancel />}
          >
            Cancelar
          </Button>
        </Box>
      </form>

      <ConfirmationModal
        show={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        handleConfirm={handleDelete}
        title="Confirmar Exclusão"
        body="Tem certeza que deseja excluir este pedido? Esta ação não pode ser desfeita."
      />
    </Box>
  );
};

export default EditarPedido;
