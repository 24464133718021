import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import {
    IconButton,
    Box,
    Checkbox,
    Typography,
    Paper
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { useTheme } from '@mui/material/styles';

const TodoItem = ({ todo, index, onToggle, onDelete }) => {
    const theme = useTheme();

    return (
        <Draggable draggableId={todo._id} index={index}>
            {(provided, snapshot) => (
                <Paper
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    elevation={snapshot.isDragging ? 3 : 1}
                    sx={{
                        p: 1,
                        mb: 1,
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: theme.palette.background.paper,
                        borderRadius: 1,
                        transition: 'all 0.2s ease',
                        '&:hover': {
                            backgroundColor: theme.palette.action.hover,
                        },
                        ...(snapshot.isDragging && {
                            backgroundColor: theme.palette.background.default,
                        }),
                    }}
                >
                    <Box
                        {...provided.dragHandleProps}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'grab',
                            color: theme.palette.text.secondary,
                            '&:hover': {
                                color: theme.palette.text.primary,
                            },
                        }}
                    >
                        <DragIndicatorIcon />
                    </Box>

                    <Checkbox
                        checked={todo.completed}
                        onChange={() => onToggle(todo._id, !todo.completed)}
                        color="primary"
                        sx={{ ml: 1 }}
                    />

                    <Typography
                        variant="body1"
                        sx={{
                            flex: 1,
                            ml: 1,
                            textDecoration: todo.completed ? 'line-through' : 'none',
                            color: todo.completed ? theme.palette.text.secondary : theme.palette.text.primary,
                            wordBreak: 'break-word',
                        }}
                    >
                        {todo.title}
                    </Typography>

                    <IconButton
                        onClick={() => onDelete(todo._id)}
                        size="small"
                        color="error"
                        sx={{
                            opacity: 0.7,
                            '&:hover': {
                                opacity: 1,
                            },
                        }}
                    >
                        <DeleteIcon />
                    </IconButton>
                </Paper>
            )}
        </Draggable>
    );
};

export default TodoItem;
