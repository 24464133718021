import { green } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

const getDesignTokens = (mode) => ({
  palette: {
    mode,
    ...(mode === 'light' ? {
      // Light mode
      primary: { main: "#eb5b25" }, // Laranja
      secondary: { main: "#222224" }, // Cinza escuro
      background: {
        default: "#F5F5F5",
        paper: "#fff",
        elevated: "#f8f9fa"
      },
      text: {
        primary: "#212121",
        secondary: "#757575"
      },
      divider: "rgba(0, 0, 0, 0.12)",
      planLevels: {
        essencial: "#CD7F32",
        profissional: "#C0C0C0",
        premium: "#FFD700"
      }
    } : {
      // Dark mode
      primary: { main: "#eb5b25" }, // Laranja mais vibrante para dark mode
      secondary: { main: "#b0bec5" },
      background: {
        default: "#121212",
        paper: "#1e1e1e",
        elevated: "#2c2c2c"
      },
      text: {
        primary: "#ffffff",
        secondary: "#b3b3b3"
      },
      divider: "rgba(255, 255, 255, 0.12)",
      planLevels: {
        essencial: "#CD7F32",
        profissional: "#e0e0e0",
        premium: "#ffd700"
      }
    }),
    green: { main: green[500] },
    blueDrive: { main: "#2882FB" },
    error: { main: "#e57373" },
    alert: { main: "#ffb74d" },
    danger: { main: '#7a0012' },
  },
  typography: {
    fontFamily: "Roboto, Arial, sans-serif",
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          marginBottom: "0px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          marginTop: "0px",
          textTransform: "none",
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginBottom: "0px",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: "none",
        },
      },
    },
  },
});

const lightTheme = createTheme(getDesignTokens('light'));
const darkTheme = createTheme(getDesignTokens('dark'));

export { lightTheme, darkTheme };
