import React from 'react';
import { IconButton } from '@mui/material';
import { Brightness4, Brightness7 } from '@mui/icons-material';
import { useTheme } from '../contexts/ThemeContext';

const ThemeToggle = () => {
    const { mode, toggleTheme } = useTheme();

    return (
        <IconButton
            onClick={toggleTheme}
            sx={{
                color: mode === 'dark' ? 'white' : 'grey.900',
                '&:hover': {
                    color: 'primary.main',
                    backgroundColor: 'action.hover',
                }
            }}
        >
            {mode === 'dark' ? <Brightness7 /> : <Brightness4 />}
        </IconButton>
    );
};

export default ThemeToggle;
