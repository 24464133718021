import React from 'react';
import {
    Box,
    Container,
    Typography,
    Paper,
    useTheme,
    Card,
    CardContent,
    CardActionArea,
    Grid,
    Button
} from '@mui/material';
import { Article as ArticleIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import SectionTitle from '../../components/SectionTitle';

const articles = [
    {
        id: 'comparativo-plataformas',
        title: 'Comparativo das Principais Plataformas de E-commerce no Brasil',
        description: 'Uma análise detalhada das principais plataformas de e-commerce disponíveis no mercado brasileiro.',
        category: 'Plataformas'
    },
    {
        id: 'otimizacao-textos',
        title: 'Otimização de Textos para Vendas Online (SEO)',
        description: 'Guia completo sobre como otimizar seus textos para melhor visibilidade nos mecanismos de busca.',
        category: 'Marketing'
    },
    {
        id: 'gestao-estoque',
        title: 'Gestão de Estoque no E-commerce',
        description: 'Aprenda as melhores práticas para gerenciar seu estoque de forma eficiente.',
        category: 'Operações'
    },
    {
        id: 'estrategias-precificacao',
        title: 'Estratégias de Precificação em E-commerce',
        description: 'Descubra as principais estratégias para precificar seus produtos de forma competitiva.',
        category: 'Vendas'
    },
    {
        id: 'logistica-entrega',
        title: 'Logística e Entrega no E-commerce',
        description: 'Tudo sobre como estruturar uma operação logística eficiente para seu e-commerce.',
        category: 'Operações'
    },
    {
        id: 'marketing-digital',
        title: 'Marketing Digital para E-commerce',
        description: 'Estratégias eficazes de marketing digital para aumentar suas vendas online.',
        category: 'Marketing'
    },
    {
        id: 'atendimento-cliente',
        title: 'Atendimento ao Cliente no E-commerce',
        description: 'Como oferecer um atendimento excepcional para fidelizar seus clientes.',
        category: 'Atendimento'
    },
    {
        id: 'analise-dados',
        title: 'Análise de Dados no E-commerce',
        description: 'Como usar dados para tomar decisões mais assertivas em seu negócio online.',
        category: 'Analytics'
    },
    {
        id: 'seguranca-protecao',
        title: 'Segurança e Proteção de Dados no E-commerce',
        description: 'Práticas essenciais para garantir a segurança dos dados em sua operação.',
        category: 'Segurança'
    },
    {
        id: 'tendencias',
        title: 'Tendências em E-commerce',
        description: 'As principais tendências que estão moldando o futuro do comércio eletrônico.',
        category: 'Tendências'
    },
    {
        id: 'gestao-financeira',
        title: 'Gestão Financeira no E-commerce',
        description: 'Como manter uma gestão financeira sólida em seu negócio online.',
        category: 'Finanças'
    },
    {
        id: 'fotografia-profissional',
        title: 'O Impacto da Fotografia Profissional no E-commerce',
        description: 'A importância da fotografia de qualidade para o sucesso das vendas online.',
        category: 'Marketing'
    },
    {
        id: 'videos-360',
        title: 'Vídeos 360°: A Revolução na Experiência de Compra Online',
        description: 'Como os vídeos 360° estão transformando a experiência de compra online.',
        category: 'Marketing'
    },
    {
        id: 'fotografias-ambientadas',
        title: 'Fotografias Ambientadas: Criando Conexões Emocionais',
        description: 'O poder das fotografias ambientadas na conexão com o consumidor.',
        category: 'Marketing'
    },
    {
        id: 'clipes-mercado-livre',
        title: 'O Poder dos Clipes no Mercado Livre',
        description: 'Como utilizar os Clipes do Mercado Livre para aumentar suas vendas.',
        category: 'Marketing'
    },
    {
        id: 'catalogos-digitais',
        title: 'Catálogos Digitais: Marketing Estratégico para Vendas',
        description: 'A importância dos catálogos digitais na estratégia de marketing.',
        category: 'Marketing'
    },
    {
        id: 'imagens-ecommerce',
        title: 'Imagens para E-commerce: A Arte de Vender Online',
        description: 'Como criar imagens que vendem no comércio eletrônico.',
        category: 'Marketing'
    }
];

const KnowledgeBase = () => {
    const theme = useTheme();
    const navigate = useNavigate();

    const handleArticleClick = (articleId) => {
        navigate(`/base-de-conhecimento/artigo/${articleId}`);
    };

    return (
        <Box sx={{
            minHeight: "100vh",
            background: theme.palette.background.default,
            p: { xs: 2, sm: 4 }
        }}>
            <Container maxWidth="lg">
                <Box sx={{ mb: 4 }}>
                    <SectionTitle
                        variant="h4"
                        mb={6}
                        width={300}
                    >
                        Base de Conhecimento
                    </SectionTitle>

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => navigate(-1)}
                    >
                        Voltar
                    </Button>
                </Box>

                <Paper
                    elevation={0}
                    sx={{
                        p: { xs: 2, sm: 3 },
                        mb: 4,
                        background: theme.palette.background.elevated,
                        borderRadius: '16px',
                        border: `1px solid ${theme.palette.divider}`
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 3 }}>
                        <Box
                            sx={{
                                width: 48,
                                height: 48,
                                borderRadius: '12px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.primary.light} 90%)`,
                            }}
                        >
                            <ArticleIcon sx={{ color: 'white' }} />
                        </Box>
                        <Box>
                            <Typography variant="h6" fontWeight={600}>
                                Artigos e Tutoriais
                            </Typography>
                            <Typography color="text.secondary">
                                Explore nossa coleção de artigos sobre e-commerce e vendas online
                            </Typography>
                        </Box>
                    </Box>

                    <Grid container spacing={3}>
                        {articles.map((article) => (
                            <Grid item xs={12} sm={6} md={4} key={article.id}>
                                <Card
                                    sx={{
                                        height: '100%',
                                        bgcolor: theme.palette.background.paper,
                                        borderRadius: 2,
                                        border: `1px solid ${theme.palette.divider}`,
                                        transition: 'transform 0.2s',
                                        '&:hover': {
                                            transform: 'translateY(-4px)'
                                        }
                                    }}
                                >
                                    <CardActionArea
                                        onClick={() => handleArticleClick(article.id)}
                                        sx={{ height: '100%' }}
                                    >
                                        <CardContent>
                                            <Typography
                                                variant="caption"
                                                sx={{
                                                    color: theme.palette.primary.main,
                                                    fontWeight: 600,
                                                    mb: 1,
                                                    display: 'block'
                                                }}
                                            >
                                                {article.category}
                                            </Typography>
                                            <Typography
                                                variant="h6"
                                                sx={{
                                                    mb: 1,
                                                    fontWeight: 600,
                                                    fontSize: '1.1rem',
                                                    lineHeight: 1.3
                                                }}
                                            >
                                                {article.title}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                color="text.secondary"
                                            >
                                                {article.description}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Paper>
            </Container>
        </Box>
    );
};

export default KnowledgeBase;
