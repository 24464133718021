import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";

const PaymentStatus = ({ pagamento, currentRow, allData }) => {
    const getColor = () => {
        if (!pagamento) return 'text.primary';
        if (pagamento.includes('Aguardando')) return 'error.main';
        if (pagamento.includes('Vencido')) return 'danger.main';
        if (pagamento.includes('Pendente')) return 'alert.main';
        if (pagamento.includes('Parcial')) return 'warning.main';
        return 'green.main';
    };

    // Use currentRow and allData for additional context if needed
    const getDisplayStatus = () => {
        if (!pagamento) return '-';

        // If we have asaas payment info in the current row, use it for additional context
        if (currentRow?.asaas?.paymentStatus) {
            const status = currentRow.asaas.paymentStatus;
            // Add any specific status handling based on asaas payment status
            if (status === 'OVERDUE' && pagamento.includes('Aguardando')) {
                return 'Vencido';
            }
        }

        return pagamento;
    };

    return (
        <Typography sx={{ color: getColor(), fontWeight: 'medium' }}>
            {getDisplayStatus()}
        </Typography>
    );
};

PaymentStatus.propTypes = {
    pagamento: PropTypes.string,
    currentRow: PropTypes.object, // Add prop type for currentRow
    allData: PropTypes.arrayOf(PropTypes.object), // Add prop type for allData
};

// Improve memoization check to consider all props
export default React.memo(PaymentStatus, (prevProps, nextProps) => {
    // Check if payment status changed
    if (prevProps.pagamento !== nextProps.pagamento) {
        return false;
    }

    // Check if current row changed (specifically the asaas payment status)
    if (prevProps.currentRow?.asaas?.paymentStatus !== nextProps.currentRow?.asaas?.paymentStatus) {
        return false;
    }

    // Check if the overall data array reference changed
    if (prevProps.allData !== nextProps.allData) {
        return false;
    }

    return true;
});
