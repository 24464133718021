import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  useTheme,
} from "@mui/material";
import { STYLES } from "./table/constants";
import { createCellRenderers } from "./table/cellRenderers";
import { getRowBackgroundColor } from "./table/rowUtils";

const TabelaReutilizavel = ({
  cabecalhos,
  dados,
  handleStatusChange,
  handleDetalhes,
  handleRetiradaChange,
  handlePaymentAction,
  readOnly = false,
}) => {
  const theme = useTheme();

  // Include dados in dependency array to ensure re-render when data changes via websocket
  const cellRenderers = useMemo(() => createCellRenderers({
    readOnly,
    handleStatusChange,
    handleDetalhes,
    handleRetiradaChange,
    handlePaymentAction,
    dados, // Pass dados to cell renderers
  }), [
    readOnly,
    handleStatusChange,
    handleDetalhes,
    handleRetiradaChange,
    handlePaymentAction,
    dados // Add dados to dependency array
  ]);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {cabecalhos.map((cabecalho, index) => (
              <TableCell key={index} sx={STYLES.header}>
                {cabecalho}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {dados.map((linha, index) => {

            // Generate a unique key for each row based on its data
            const rowKey = `${linha.ID}-${linha.Pagamento}-${index}`;
            return (
              <TableRow
                key={rowKey}
                sx={{ backgroundColor: getRowBackgroundColor(linha, theme) }}
              >
                {cabecalhos.map((cabecalho, cellIndex) => {
                  const cellRenderer = cellRenderers[cabecalho] || (() => linha[cabecalho] || '-');
                  // Generate a unique key for each cell
                  const cellKey = `${rowKey}-${cabecalho}-${cellIndex}`;
                  return (
                    <TableCell key={cellKey}>
                      {cellRenderer(linha)}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

TabelaReutilizavel.propTypes = {
  cabecalhos: PropTypes.arrayOf(PropTypes.string).isRequired,
  dados: PropTypes.arrayOf(PropTypes.shape({
    ID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    Status: PropTypes.string,
    Cliente: PropTypes.string,
    Origem: PropTypes.string,
    DataCadastro: PropTypes.string,
    DataVencimento: PropTypes.string,
    DataEntrega: PropTypes.string,
    Retirada: PropTypes.bool,
    Detalhes: PropTypes.string,
    Total: PropTypes.string,
    Pagamento: PropTypes.string,
    Telefone: PropTypes.string,
    DriveLink: PropTypes.string,
    boolean: PropTypes.bool,
    asaas: PropTypes.shape({
      paymentId: PropTypes.string,
      paymentStatus: PropTypes.string,
    }),
  })).isRequired,
  handleStatusChange: PropTypes.func,
  handleDetalhes: PropTypes.func.isRequired,
  handleRetiradaChange: PropTypes.func,
  handlePaymentAction: PropTypes.func,
  readOnly: PropTypes.bool,
};

// Add proper equality check for memoization
export default React.memo(TabelaReutilizavel, (prevProps, nextProps) => {
  // Always re-render if dados changes
  if (prevProps.dados !== nextProps.dados) {
    return false;
  }

  // Check other props for changes
  return (
    prevProps.readOnly === nextProps.readOnly &&
    prevProps.handleStatusChange === nextProps.handleStatusChange &&
    prevProps.handleDetalhes === nextProps.handleDetalhes &&
    prevProps.handleRetiradaChange === nextProps.handleRetiradaChange &&
    prevProps.handlePaymentAction === nextProps.handlePaymentAction &&
    JSON.stringify(prevProps.cabecalhos) === JSON.stringify(nextProps.cabecalhos)
  );
});
