import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Select, MenuItem, Typography } from "@mui/material";
import { STATUS_OPTIONS } from "./constants";

const StatusDisplay = ({ status, id, readOnly, handleStatusChange, allData }) => {
    // Use allData for context-aware status options
    const availableStatuses = useMemo(() => {
        const baseStatuses = Object.entries(STATUS_OPTIONS);

        if (!allData || !id) return baseStatuses;

        // Find the current row in allData for context
        const currentRow = allData.find(row => row.ID === id);
        if (!currentRow) return baseStatuses;

        // Filter or modify status options based on context
        // For example, if payment is pending, don't allow certain status changes
        if (currentRow.asaas?.paymentStatus === 'PENDING') {
            return baseStatuses.filter(([_, value]) =>
                !['entregue', 'finalizado'].includes(value.toLowerCase())
            );
        }

        // If payment is overdue, highlight certain statuses
        if (currentRow.asaas?.paymentStatus === 'OVERDUE') {
            return baseStatuses.map(([key, value]) => ({
                key,
                value,
                highlight: ['pendente', 'atrasado'].includes(value.toLowerCase())
            }));
        }

        return baseStatuses;
    }, [allData, id]);

    if (readOnly) {
        return (
            <Typography
                variant="body2"
                sx={{
                    textTransform: 'capitalize',
                    fontWeight: status.toLowerCase() === 'urgente' ? 'bold' : 'normal'
                }}
            >
                {status}
            </Typography>
        );
    }

    return (
        <Select
            value={status}
            onChange={(e) => handleStatusChange(id, e.target.value)}
            sx={{
                width: "150px",
                // Add any conditional styling based on status
                ...(status.toLowerCase() === 'urgente' && {
                    '& .MuiSelect-select': {
                        color: 'error.main',
                        fontWeight: 'bold'
                    }
                })
            }}
        >
            {availableStatuses.map((statusOption) => {
                // Handle both regular status arrays and enhanced status objects
                const [key, value] = Array.isArray(statusOption)
                    ? statusOption
                    : [statusOption.key, statusOption.value];
                const highlight = !Array.isArray(statusOption) && statusOption.highlight;

                return (
                    <MenuItem
                        key={key}
                        value={value}
                        sx={{
                            ...(highlight && {
                                fontWeight: 'bold',
                                color: 'warning.main'
                            })
                        }}
                    >
                        {value.charAt(0).toUpperCase() + value.slice(1)}
                    </MenuItem>
                );
            })}
        </Select>
    );
};

StatusDisplay.propTypes = {
    status: PropTypes.string.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    readOnly: PropTypes.bool.isRequired,
    handleStatusChange: PropTypes.func,
    allData: PropTypes.arrayOf(PropTypes.object), // Add prop type for allData
};

// Improve memoization to consider all props and relevant data changes
export default React.memo(StatusDisplay, (prevProps, nextProps) => {
    // Check if basic props changed
    if (prevProps.status !== nextProps.status ||
        prevProps.id !== nextProps.id ||
        prevProps.readOnly !== nextProps.readOnly ||
        prevProps.handleStatusChange !== nextProps.handleStatusChange) {
        return false;
    }

    // Check if the data array reference changed
    if (prevProps.allData !== nextProps.allData) {
        // If allData changed, check if the relevant row data changed
        const prevRow = prevProps.allData?.find(row => row.ID === prevProps.id);
        const nextRow = nextProps.allData?.find(row => row.ID === nextProps.id);

        if (!prevRow || !nextRow) return false;

        // Check if payment status changed
        if (prevRow.asaas?.paymentStatus !== nextRow.asaas?.paymentStatus) {
            return false;
        }
    }

    return true;
});
