import React, { useCallback } from "react";
import PropTypes from "prop-types";
import debounce from 'lodash/debounce';
import {
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Button,
    useTheme,
} from "@mui/material";

const PaymentsFilterBar = ({ filters, onFilterChange, filterOptions }) => {
    const theme = useTheme();

    // Debounced filter change for text inputs
    const debouncedFilterChange = useCallback(
        debounce((name, value) => {
            const newFilters = {
                ...filters,
                [name]: value
            };
            onFilterChange(newFilters);
        }, 500),
        [filters, onFilterChange]
    );

    const handleFilterChange = (name, value, immediate = false) => {
        if (immediate) {
            // For select and date inputs, apply immediately
            const newFilters = {
                ...filters,
                [name]: value
            };
            onFilterChange(newFilters);
        } else {
            // For text inputs, apply with debounce
            debouncedFilterChange(name, value);
        }
    };

    const handleClearFilters = () => {
        const clearedFilters = {};
        filterOptions.forEach(option => {
            clearedFilters[option.name] = '';
        });
        onFilterChange(clearedFilters);
    };

    return (
        <Box
            sx={{
                backgroundColor: theme.palette.mode === 'light'
                    ? theme.palette.background.paper
                    : theme.palette.background.default,
                padding: 2,
                borderRadius: 2,
                boxShadow: theme.shadows[1],
                marginBottom: 3,
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                gap: 2,
            }}
        >
            {filterOptions.map((option, index) => {
                if (option.type === 'select') {
                    return (
                        <FormControl key={index} size="small" sx={{ flex: 1, minWidth: "150px" }}>
                            <InputLabel>{option.label}</InputLabel>
                            <Select
                                value={filters[option.name] || ''}
                                onChange={(e) => handleFilterChange(option.name, e.target.value, true)}
                                label={option.label}
                            >
                                {option.options.map((opt, i) => (
                                    <MenuItem key={i} value={opt.value}>
                                        {opt.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    );
                }

                if (option.type === 'text') {
                    return (
                        <TextField
                            key={index}
                            label={option.label}
                            variant="outlined"
                            size="small"
                            value={filters[option.name] || ''}
                            onChange={(e) => handleFilterChange(option.name, e.target.value, false)}
                            sx={{ flex: 1, minWidth: "200px" }}
                        />
                    );
                }

                if (option.type === 'date') {
                    return (
                        <TextField
                            key={index}
                            label={option.label}
                            type="date"
                            variant="outlined"
                            size="small"
                            value={filters[option.name] || ''}
                            onChange={(e) => handleFilterChange(option.name, e.target.value, true)}
                            InputLabelProps={{ shrink: true }}
                            sx={{ flex: 1, minWidth: "150px" }}
                        />
                    );
                }

                return null;
            })}

            <Button
                variant="outlined"
                color="error"
                onClick={handleClearFilters}
                size="small"
                sx={{
                    flexShrink: 0,
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                }}
            >
                Limpar Filtros
            </Button>
        </Box>
    );
};

PaymentsFilterBar.propTypes = {
    filters: PropTypes.object.isRequired,
    onFilterChange: PropTypes.func.isRequired,
    filterOptions: PropTypes.arrayOf(PropTypes.shape({
        type: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        options: PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        })),
    })).isRequired,
};

export default PaymentsFilterBar;
