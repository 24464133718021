import React, { useState, useEffect, useCallback } from 'react';
import {
    Box,
    Container,
    Typography,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Chip,
    useTheme,
    useMediaQuery,
    Card,
    CardContent,
    Button,
    CircularProgress,
    Alert,
} from '@mui/material';
import { Receipt, ShoppingBag, Autorenew } from '@mui/icons-material';
import paymentService from '../../services/paymentService';
import PaginationControls from '../../components/PaginationControls';
import SectionTitle from '../../components/SectionTitle';

// Cache para armazenar os dados por 5 minutos
const cache = {
    data: new Map(),
    timestamp: new Map(),
    CACHE_DURATION: 5 * 60 * 1000, // 5 minutos em milissegundos
};

const usePaymentsData = (fetchFunction, dependencies) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [total, setTotal] = useState(0);

    const fetchData = useCallback(async () => {
        try {
            setLoading(true);
            setError(null);

            // Gera uma chave única para o cache baseada na função e seus parâmetros
            const cacheKey = JSON.stringify({ fn: fetchFunction.name, deps: dependencies });

            // Verifica se há dados em cache válidos
            const cachedData = cache.data.get(cacheKey);
            const cachedTimestamp = cache.timestamp.get(cacheKey);
            const now = Date.now();

            if (cachedData && cachedTimestamp && (now - cachedTimestamp) < cache.CACHE_DURATION) {
                setData(cachedData.items);
                setTotal(cachedData.total);
                setLoading(false);
                return;
            }

            const response = await fetchFunction(...dependencies);

            // Atualiza o cache
            cache.data.set(cacheKey, response);
            cache.timestamp.set(cacheKey, now);

            setData(response.items);
            setTotal(response.total);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    }, [fetchFunction, ...dependencies]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return { data, loading, error, total, refetch: fetchData };
};

const StatusChip = React.memo(({ status }) => {
    const getStatusColor = () => {
        switch (status?.toLowerCase()) {
            case 'pago':
            case 'received':
            case 'confirmed':
                return 'success';
            case 'pendente':
            case 'pending':
                return 'warning';
            case 'falhou':
            case 'failed':
            case 'overdue':
                return 'error';
            default:
                return 'default';
        }
    };

    return (
        <Chip
            label={paymentService.formatPaymentStatus(status)}
            color={getStatusColor()}
            size="small"
            sx={{
                fontWeight: 500,
                minWidth: 80
            }}
        />
    );
});

const PaymentCard = React.memo(({ payment }) => {
    const theme = useTheme();

    return (
        <Card
            sx={{
                mb: 2,
                bgcolor: theme.palette.background.elevated,
                borderRadius: 2,
                border: `1px solid ${theme.palette.divider}`
            }}
        >
            <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                    <Box>
                        <Typography variant="subtitle1" fontWeight={600}>
                            {payment.description}
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 0.5 }}>
                            {payment.paymentType === 'subscription' ? (
                                <Autorenew fontSize="small" color="primary" />
                            ) : (
                                <ShoppingBag fontSize="small" color="primary" />
                            )}
                            <Typography variant="body2" color="text.secondary">
                                {payment.paymentType === 'subscription' ? 'Assinatura' : 'Pedido'}
                            </Typography>
                        </Box>
                    </Box>
                    <StatusChip status={payment.status} />
                </Box>
                <Typography color="text.secondary" sx={{ mb: 1 }}>
                    {new Date(payment.dueDate).toLocaleDateString('pt-BR')}
                </Typography>
                <Typography variant="h6" color="primary" fontWeight={600}>
                    R$ {Number(payment.value).toFixed(2)}
                </Typography>
                {payment.invoiceUrl && (
                    <Button
                        startIcon={<Receipt />}
                        variant="outlined"
                        size="small"
                        sx={{ mt: 1 }}
                        href={payment.invoiceUrl}
                        target="_blank"
                    >
                        Ver Fatura
                    </Button>
                )}
            </CardContent>
        </Card>
    );
});

const PaymentsTable = React.memo(({
    payments,
    title,
    icon: Icon,
    page,
    limit,
    total,
    onPageChange,
    onLimitChange,
    loading,
    error
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    if (error) {
        return (
            <Alert severity="error" sx={{ mb: 4 }}>
                {error}
            </Alert>
        );
    }

    return (
        <Box sx={{ mb: 4 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                <Icon color="primary" />
                <Typography variant="h6" fontWeight={600}>
                    {title}
                </Typography>
            </Box>

            {loading && payments.length === 0 ? (
                <Box display="flex" justifyContent="center" p={3}>
                    <CircularProgress />
                </Box>
            ) : isMobile ? (
                <Box>
                    {payments.map((payment) => (
                        <PaymentCard key={payment.id} payment={payment} />
                    ))}
                    {payments.length === 0 && (
                        <Typography color="text.secondary" align="center">
                            Nenhum pagamento encontrado.
                        </Typography>
                    )}
                    <Box sx={{ mt: 2 }}>
                        <PaginationControls
                            page={page}
                            limit={limit}
                            total={total}
                            onPageChange={onPageChange}
                            onLimitChange={onLimitChange}
                            loading={loading}
                        />
                    </Box>
                </Box>
            ) : (
                <>
                    <TableContainer
                        component={Paper}
                        elevation={0}
                        sx={{
                            background: theme.palette.background.elevated,
                            borderRadius: '16px',
                            border: `1px solid ${theme.palette.divider}`
                        }}
                    >
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Data</TableCell>
                                    <TableCell>Descrição</TableCell>
                                    <TableCell align="right">Valor</TableCell>
                                    <TableCell align="right">Status</TableCell>
                                    <TableCell align="right">Fatura</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {payments.map((payment) => (
                                    <TableRow key={payment.id}>
                                        <TableCell>
                                            {new Date(payment.dueDate).toLocaleDateString('pt-BR')}
                                        </TableCell>
                                        <TableCell>{payment.description}</TableCell>
                                        <TableCell align="right">
                                            R$ {Number(payment.value).toFixed(2)}
                                        </TableCell>
                                        <TableCell align="right">
                                            <StatusChip status={payment.status} />
                                        </TableCell>
                                        <TableCell align="right">
                                            {payment.invoiceUrl && (
                                                <Button
                                                    startIcon={<Receipt />}
                                                    variant="outlined"
                                                    size="small"
                                                    href={payment.invoiceUrl}
                                                    target="_blank"
                                                >
                                                    Ver Fatura
                                                </Button>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                                {payments.length === 0 && (
                                    <TableRow>
                                        <TableCell colSpan={5} align="center">
                                            <Typography color="text.secondary">
                                                Nenhum pagamento encontrado.
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box sx={{ mt: 2 }}>
                        <PaginationControls
                            page={page}
                            limit={limit}
                            total={total}
                            onPageChange={onPageChange}
                            onLimitChange={onLimitChange}
                            loading={loading}
                        />
                    </Box>
                </>
            )}
        </Box>
    );
});

const UserPayments = () => {
    const theme = useTheme();

    // Estados de paginação para pagamentos de assinatura
    const [subPage, setSubPage] = useState(1);
    const [subLimit, setSubLimit] = useState(10);

    // Estados de paginação para pagamentos de pedidos
    const [orderPage, setOrderPage] = useState(1);
    const [orderLimit, setOrderLimit] = useState(10);

    // Busca dados da assinatura ativa
    const {
        data: activeSubscription,
        loading: loadingSubscription,
        error: subscriptionError
    } = usePaymentsData(
        paymentService.getUserActiveSubscription,
        []
    );

    // Busca pagamentos de assinatura
    const {
        data: subscriptionPayments,
        loading: loadingSubPayments,
        error: subPaymentsError,
        total: subTotal,
    } = usePaymentsData(
        paymentService.getUserSubscriptionPayments,
        [subPage, subLimit]
    );

    // Busca pagamentos de pedidos
    const {
        data: orderPayments,
        loading: loadingOrderPayments,
        error: orderPaymentsError,
        total: orderTotal,
    } = usePaymentsData(
        paymentService.getUserOrderPayments,
        [orderPage, orderLimit]
    );


    // Loading inicial enquanto não temos nenhum dado
    if (loadingSubscription && loadingSubPayments && loadingOrderPayments &&
        !subscriptionPayments.length && !orderPayments.length) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box sx={{
            minHeight: "100vh",
            background: theme.palette.background.default,
            p: { xs: 2, sm: 4 }
        }}>
            <Container maxWidth="lg">
                <SectionTitle
                    variant="h3"
                    width={280}
                    mb={6}
                    sx={{
                        fontFamily: theme.typography.fontFamily,
                        fontWeight: 600,
                    }}
                >
                    Pagamentos
                </SectionTitle>

                <PaymentsTable
                    payments={subscriptionPayments}
                    title="Pagamentos de Assinatura"
                    icon={Autorenew}
                    page={subPage}
                    limit={subLimit}
                    total={subTotal}
                    onPageChange={(e, newPage) => setSubPage(newPage)}
                    onLimitChange={(e) => {
                        setSubLimit(e.target.value);
                        setSubPage(1);
                    }}
                    loading={loadingSubPayments}
                    error={subPaymentsError}
                />

                <PaymentsTable
                    payments={orderPayments}
                    title="Pagamentos de Pedidos"
                    icon={ShoppingBag}
                    page={orderPage}
                    limit={orderLimit}
                    total={orderTotal}
                    onPageChange={(e, newPage) => setOrderPage(newPage)}
                    onLimitChange={(e) => {
                        setOrderLimit(e.target.value);
                        setOrderPage(1);
                    }}
                    loading={loadingOrderPayments}
                    error={orderPaymentsError}
                />

                {!loadingSubPayments && !loadingOrderPayments &&
                    subscriptionPayments.length === 0 && orderPayments.length === 0 && (
                        <Typography color="text.secondary" align="center">
                            Nenhum pagamento encontrado.
                        </Typography>
                    )}
            </Container>
        </Box>
    );
};

export default UserPayments;
