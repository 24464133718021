import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Box,
    Typography,
    Chip,
    IconButton,
    useTheme,
    Button,
    Divider,
    Link
} from '@mui/material';
import {
    Close,
    AccessTime,
    CheckCircle,
    Cancel,
    FiberNew,
    LocalShipping,
    Payment,
    DriveFileMove
} from '@mui/icons-material';

const OrderDetailsModal = ({ open, onClose, pedido }) => {
    console.log(pedido);
    const theme = useTheme();

    if (!pedido) return null;

    const getStatusConfig = (status) => {
        const configs = {
            'aberto': {
                color: theme.palette.info.main,
                icon: FiberNew,
                label: 'Aberto'
            },
            'em andamento': {
                color: theme.palette.alert.main,
                icon: AccessTime,
                label: 'Em andamento'
            },
            'concluido': {
                color: theme.palette.green.main,
                icon: CheckCircle,
                label: 'Concluído'
            },
            'cancelado': {
                color: theme.palette.error.main,
                icon: Cancel,
                label: 'Cancelado'
            }
        };
        return configs[status] || configs['aberto'];
    };

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        }).format(value || 0);
    };

    const formatDate = (date) => {
        if (!date) return 'Não definida';
        return new Date(date).toLocaleDateString('pt-BR', {
            day: '2-digit',
            month: 'long',
            year: 'numeric'
        });
    };

    const statusConfig = getStatusConfig(pedido.status);
    const StatusIcon = statusConfig.icon;

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
            PaperProps={{
                sx: {
                    borderRadius: 2,
                    bgcolor: theme.palette.background.paper
                }
            }}
        >
            <DialogTitle sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                pb: 1
            }}>
                <Typography variant="h6" sx={{ fontWeight: 600 }}>
                    Detalhes do Pedido #{pedido._id?.slice(-4).toUpperCase()}
                </Typography>
                <IconButton onClick={onClose} size="small">
                    <Close />
                </IconButton>
            </DialogTitle>

            <DialogContent>
                <Box sx={{ mb: 3 }}>
                    <Box sx={{ display: 'flex', gap: 2, mb: 2, flexWrap: 'wrap' }}>
                        <Chip
                            icon={<StatusIcon />}
                            label={statusConfig.label}
                            sx={{
                                bgcolor: theme.palette.mode === 'dark'
                                    ? 'rgba(255,255,255,0.05)'
                                    : 'rgba(0,0,0,0.05)',
                                color: statusConfig.color,
                                border: `1px solid ${statusConfig.color}`,
                                '& .MuiChip-icon': {
                                    color: statusConfig.color
                                }
                            }}
                        />
                        {pedido.isUrgent && (
                            <Chip
                                label="Urgente"
                                color="error"
                                size="small"
                            />
                        )}
                        {pedido.retirada && (
                            <Chip
                                icon={<LocalShipping />}
                                label="Retirada"
                                color="primary"
                                size="small"
                            />
                        )}
                    </Box>

                    <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                        Criado em: {formatDate(pedido.createdAt)}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                        Data de Entrega: {formatDate(pedido.dataEntrega)}
                    </Typography>
                    {pedido.dataLimiteRetirada && (
                        <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                            Limite para Retirada: {formatDate(pedido.dataLimiteRetirada)}
                        </Typography>
                    )}
                </Box>

                <Divider sx={{ my: 2 }} />

                <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 2 }}>
                    Serviços
                </Typography>
                <Box sx={{ mb: 3 }}>
                    {pedido.servicos?.map((servico, index) => (
                        <Box
                            key={index}
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                mb: 1,
                                p: 1,
                                bgcolor: theme.palette.mode === 'dark'
                                    ? 'rgba(255,255,255,0.05)'
                                    : 'rgba(0,0,0,0.02)',
                                borderRadius: 1
                            }}
                        >
                            <Typography variant="body2">
                                {servico.servicoId?.nome || 'Serviço não encontrado'} ({servico.quantidade}x)
                            </Typography>
                            <Typography variant="body2" sx={{ fontWeight: 500 }}>
                                {formatCurrency(servico.subtotal)}
                            </Typography>
                        </Box>
                    ))}
                </Box>

                <Box sx={{ mb: 3 }}>
                    {pedido.valorUrgencia > 0 && (
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                            <Typography variant="body2">Taxa de Urgência</Typography>
                            <Typography variant="body2" sx={{ fontWeight: 500 }}>
                                {formatCurrency(pedido.valorUrgencia)}
                            </Typography>
                        </Box>
                    )}

                    {pedido.isZoomPlus && (
                        <>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                                <Typography variant="body2">Desconto ZoomPlus</Typography>
                                <Typography variant="body2" sx={{ fontWeight: 500, color: theme.palette.success.main }}>
                                    -{formatCurrency(pedido.zoomPlus?.descontoAplicado || 0)}
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                                <Typography variant="body2">Saldo Anterior</Typography>
                                <Typography variant="body2" sx={{ fontWeight: 500 }}>
                                    {formatCurrency((pedido.zoomPlus?.saldoUtilizado || 0) + (pedido.zoomPlus?.saldoRestante || 0))}
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                                <Typography variant="body2">Saldo Utilizado</Typography>
                                <Typography variant="body2" sx={{ fontWeight: 500 }}>
                                    {formatCurrency(pedido.zoomPlus?.saldoUtilizado || 0)}
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                                <Typography variant="body2">Saldo Restante</Typography>
                                <Typography variant="body2" sx={{ fontWeight: 500 }}>
                                    {formatCurrency(pedido.zoomPlus?.saldoRestante || 0)}
                                </Typography>
                            </Box>
                        </>
                    )}

                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        mt: 2,
                        pt: 2,
                        borderTop: `1px solid ${theme.palette.divider}`
                    }}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                            Total a Pagar
                        </Typography>
                        <Typography variant="subtitle1" sx={{ fontWeight: 600, color: theme.palette.primary.main }}>
                            {formatCurrency(pedido.valorPagar)}
                        </Typography>
                    </Box>
                </Box>

                {pedido.mensagemResumo && (
                    <Box sx={{ mb: 3 }}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 1 }}>
                            Observações
                        </Typography>
                        <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
                            {pedido.mensagemResumo}
                        </Typography>
                    </Box>
                )}

                {pedido.linkDrive && (
                    <Box sx={{ mb: 3 }}>
                        <Button
                            startIcon={<DriveFileMove />}
                            variant="outlined"
                            component={Link}
                            href={pedido.linkDrive}
                            target="_blank"
                            rel="noopener noreferrer"
                            fullWidth
                        >
                            Acessar Arquivos no Drive
                        </Button>
                    </Box>
                )}

                {pedido.asaas?.paymentLink && pedido.statusPagamento === 'pendente' && (
                    <Button
                        startIcon={<Payment />}
                        variant="contained"
                        color="primary"
                        component={Link}
                        href={pedido.asaas.paymentLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        fullWidth
                    >
                        Realizar Pagamento
                    </Button>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default OrderDetailsModal;
