import React from "react";
import PropTypes from "prop-types";
import {
    Box,
    Typography,
    Grid,
} from "@mui/material";
import {
    Loupe as LoupeIcon,
    Event as EventIcon,
    QueryBuilder as QueryBuilderIcon,
    MonetizationOn as MonetizationOnIcon,
    Woman as WomanIcon,
    Bolt as BoltIcon,
} from "@mui/icons-material";

const ClientZoomPlusCard = ({ cliente }) => {
    const formatDate = (dateString) => {
        if (!dateString) return "Não informado";
        try {
            return new Date(dateString).toLocaleDateString();
        } catch {
            return "Data inválida";
        }
    };

    return (
        <Box
            sx={{
                backgroundColor: "background.paper",
                borderRadius: "4px",
                padding: "12px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.05)",
                border: "1px solid #eee",
            }}
        >
            <Typography
                variant="subtitle1"
                color="primary.main"
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 0.5,
                    marginBottom: 1,
                    "& .MuiSvgIcon-root": {
                        fontSize: "1rem"
                    }
                }}
            >
                <LoupeIcon /> Zoom+ {cliente.planoNome || ""}
            </Typography>

            <Grid container spacing={1} sx={{ fontSize: "0.875rem" }}>
                <Grid item xs={6}>
                    <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 0.5,
                        "& .MuiSvgIcon-root": {
                            fontSize: "0.875rem"
                        }
                    }}>
                        <MonetizationOnIcon color="primary" />
                        <Typography variant="body2">
                            Saldo: R$ {cliente.saldo ? cliente.saldo.toFixed(2) : "0,00"}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 0.5,
                        "& .MuiSvgIcon-root": {
                            fontSize: "0.875rem"
                        }
                    }}>
                        <QueryBuilderIcon color="primary" />
                        <Typography variant="body2">
                            Venc.: {cliente.diaVencimento || "-"}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 0.5,
                        "& .MuiSvgIcon-root": {
                            fontSize: "0.875rem"
                        }
                    }}>
                        <BoltIcon color="primary" />
                        <Typography variant="body2">
                            Urgências: {cliente.taxasUrgenciaDisponiveis || 0}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 0.5,
                        "& .MuiSvgIcon-root": {
                            fontSize: "0.875rem"
                        }
                    }}>
                        <WomanIcon color="primary" />
                        <Typography variant="body2">
                            Modelos: {cliente.usoModelosDisponiveis || 0}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

ClientZoomPlusCard.propTypes = {
    cliente: PropTypes.shape({
        planoNome: PropTypes.string,
        dataInicioPlano: PropTypes.string,
        diaVencimento: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        dataFinalPlano: PropTypes.string,
        saldo: PropTypes.number,
        taxasUrgenciaDisponiveis: PropTypes.number,
        usoModelosDisponiveis: PropTypes.number,
    }).isRequired,
};

export default ClientZoomPlusCard;
