import React from 'react';
import { Grid, TextField } from '@mui/material';

const BasicInfoForm = ({ cliente, onChange, formErrors = {}, disabled = false }) => {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
                <TextField
                    label="Nome"
                    value={cliente.nome}
                    onChange={(e) => onChange('nome', e.target.value)}
                    fullWidth
                    required
                    error={!!formErrors.nome}
                    helperText={formErrors.nome}
                    disabled={disabled}
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    label="Email"
                    type="email"
                    value={cliente.email}
                    onChange={(e) => onChange('email', e.target.value)}
                    fullWidth
                    required
                    error={!!formErrors.email}
                    helperText={formErrors.email}
                    disabled={disabled}
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    label="Documento"
                    value={cliente.documento}
                    onChange={(e) => onChange('documento', e.target.value)}
                    fullWidth
                    required
                    error={!!formErrors.documento}
                    helperText={formErrors.documento}
                    disabled={disabled}
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    label="Telefone"
                    value={cliente.telefone}
                    onChange={(e) => onChange('telefone', e.target.value)}
                    fullWidth
                    error={!!formErrors.telefone}
                    helperText={formErrors.telefone}
                    disabled={disabled}
                />
            </Grid>

            <Grid item xs={12}>
                <TextField
                    label="Endereço"
                    value={cliente.endereco}
                    onChange={(e) => onChange('endereco', e.target.value)}
                    fullWidth
                    disabled={disabled}
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    label="Link do Drive"
                    value={cliente.linkDrive}
                    onChange={(e) => onChange('linkDrive', e.target.value)}
                    fullWidth
                    placeholder="https://drive.google.com/..."
                    disabled={disabled}
                />
            </Grid>
        </Grid>
    );
};

export default BasicInfoForm;
