import React, { useEffect, useState, forwardRef, useImperativeHandle, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Paper,
  IconButton,
  CircularProgress,
} from "@mui/material";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ClearIcon from '@mui/icons-material/Clear';
import api from "../api";
import TabelaReutilizavel from "./TabelaReutilizavel";
import AlertaReutilizavel from "./AlertaReutilizavel";
import PedidosFilter from "./PedidosFilter";
import PaymentDetailsDialog from "./PaymentDetailsDialog";
import PaginationControls from "./PaginationControls";
import { useWebSocket } from "../hooks/useWebSocket";
import { usePaymentHandling } from "../hooks/usePaymentHandling";
import { formatarValor, getStatusPagamentoLabel } from "../utils/pedidoUtils";
import paymentService from "../services/paymentService";

const ListaPedidos = forwardRef(({ initialFilters = {}, readOnly = false }, ref) => {
  const navigate = useNavigate();
  const [pedidos, setPedidos] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);
  const isMounted = useRef(true);
  const initialLoadDone = useRef(false);

  // Estado para paginação
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    total: 0,
    pages: 0
  });

  const [alerta, setAlerta] = useState({
    mensagem: "",
    tipo: "",
    aberto: false,
  });

  const [filters, setFilters] = useState({
    clienteId: "",
    status: "",
    statusPagamento: "",
    retirada: "",
    isUrgent: "",
    mesCriacao: "",
    valorMaximo: "",
    ...initialFilters
  });

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  // Fetch payment statuses for pedidos
  const fetchPaymentStatuses = async (pedidosData) => {
    // Get IDs of pedidos with payment IDs
    const pedidosWithPayments = pedidosData.filter(pedido => pedido.asaas?.paymentId);
    if (pedidosWithPayments.length === 0) {
      return pedidosData;
    }

    try {
      // Update payment statuses in bulk
      const updatedPedidos = await paymentService.updatePaymentStatuses(
        pedidosWithPayments.map(pedido => pedido._id)
      );

      // Create a map of updated pedidos for easy lookup
      const updatedPedidosMap = updatedPedidos.reduce((acc, pedido) => {
        acc[pedido._id] = pedido;
        return acc;
      }, {});

      // Merge updated pedidos with original data, preserving client data
      return pedidosData.map(pedido => {
        const updatedPedido = updatedPedidosMap[pedido._id];
        if (updatedPedido) {
          return {
            ...pedido,
            statusPagamento: updatedPedido.statusPagamento,
            asaas: updatedPedido.asaas,
            clienteId: updatedPedido.clienteId || pedido.clienteId // Preserve client data
          };
        }
        return pedido;
      });
    } catch (error) {
      console.error('Error fetching payment statuses:', error);
      return pedidosData;
    }
  };

  const carregarPedidos = useCallback(async (skipLoading = false) => {
    if (!isMounted.current) return;
    if (!skipLoading) setIsLoading(true);

    try {
      const filtrosValidos = Object.entries(filters).reduce((acc, [key, value]) => {
        if (value !== "" && value !== null && value !== undefined) {
          acc[key] = value;
        }
        return acc;
      }, {});

      // Adicionar parâmetros de paginação
      filtrosValidos.page = pagination.page;
      filtrosValidos.limit = pagination.limit;

      const queryString = new URLSearchParams(filtrosValidos).toString();
      const response = await api.get(`/pedidos?${queryString}`);

      if (isMounted.current) {
        setPedidos(response.data.pedidos || []);
        setPagination(prev => ({
          ...prev,
          total: response.data.pagination.total,
          pages: response.data.pagination.pages
        }));
        initialLoadDone.current = true;
      }
    } catch (error) {
      console.error('Error loading pedidos:', error);
      if (isMounted.current) {
        setAlerta({
          mensagem: "Erro ao carregar pedidos",
          tipo: "error",
          aberto: true,
        });
      }
    } finally {
      if (isMounted.current && !skipLoading) {
        setIsLoading(false);
      }
    }
  }, [filters, pagination.page, pagination.limit]);

  const {
    paymentDetails,
    showPaymentDetails,
    isLoadingPayment,
    handlePaymentAction,
    handlePaymentStatusChange,
    setShowPaymentDetails,
    getOptimisticState
  } = usePaymentHandling(carregarPedidos, setAlerta);

  const updatePedidoFromWebSocket = useCallback((pedidoId, updateData) => {
    setPedidos(currentPedidos => {
      return currentPedidos.map(pedido => {
        if (pedido._id === pedidoId) {
          return {
            ...pedido,
            ...updateData,
            asaas: {
              ...pedido.asaas,
              ...updateData.asaas
            }
          };
        }
        return pedido;
      });
    });
  }, []);

  const handleWebSocketMessage = useCallback(async (data) => {
    if (!isMounted.current) return;


    if (data.event === 'connected') {
      if (!initialLoadDone.current) {
        await carregarPedidos(true);
      }
      return;
    }

    if (data.event === 'payment_updated') {
      const { pedidoId, status, statusPagamento, payment } = data.data;

      if (pedidoId) {
        updatePedidoFromWebSocket(pedidoId, {
          statusPagamento,
          asaas: {
            paymentStatus: status,
            paymentData: payment
          }
        });
      }
    } else if (data.event === 'pedido_updated') {
      const { pedidoId, status, statusPagamento, asaas } = data.data;

      if (pedidoId) {
        updatePedidoFromWebSocket(pedidoId, {
          status,
          statusPagamento,
          asaas
        });
      }
    }
  }, [carregarPedidos, updatePedidoFromWebSocket]);

  const { isConnected, reconnect } = useWebSocket(handleWebSocketMessage, {
    reconnectOnClose: true,
    retryAttempts: 5
  });

  useEffect(() => {
    if (!isConnected) {
      reconnect();
    }
  }, [isConnected, reconnect]);

  const carregarClientes = async () => {
    if (!isMounted.current) return;

    try {
      const response = await api.get("/clientes");
      if (isMounted.current) {
        setClientes(response.data || []);
      }
    } catch (error) {
      console.error('Error loading clients:', error);
      if (isMounted.current) {
        setAlerta({
          mensagem: "Erro ao carregar clientes",
          tipo: "error",
          aberto: true,
        });
      }
    }
  };

  useImperativeHandle(ref, () => ({
    carregarPedidos,
  }));

  // Initial load
  useEffect(() => {
    carregarClientes();
    if (!initialLoadDone.current) {
      carregarPedidos();
    }
  }, [carregarPedidos]);

  // Combined effect for filters and pagination changes
  useEffect(() => {
    if (initialLoadDone.current) {
      carregarPedidos();
    }
  }, [filters, pagination.page, pagination.limit, carregarPedidos]);

  const handleFilterChange = (field) => (event) => {
    if (!isMounted.current) return;
    setFilters(prev => ({
      ...prev,
      [field]: event.target.value
    }));
    // Reset page to 1 when filters change
    setPagination(prev => ({
      ...prev,
      page: 1
    }));
  };

  const clearFilters = () => {
    if (!isMounted.current) return;
    setFilters({
      ...initialFilters,
      status: "",
      statusPagamento: "",
      retirada: "",
      isUrgent: "",
      mesCriacao: "",
      valorMinimo: "",
      valorMaximo: ""
    });
    // Reset page to 1 when clearing filters
    setPagination(prev => ({
      ...prev,
      page: 1
    }));
  };

  const handlePageChange = (event, newPage) => {
    if (!isMounted.current) return;
    setPagination(prev => ({
      ...prev,
      page: newPage
    }));
  };

  const handleLimitChange = (event) => {
    if (!isMounted.current) return;
    setPagination(prev => ({
      ...prev,
      limit: event.target.value,
      page: 1 // Reset to first page when changing limit
    }));
  };

  const atualizarStatus = async (pedidoId, campo, valor) => {
    if (readOnly || !isMounted.current) return;

    setIsUpdatingStatus(true);
    try {
      await api.patch(`/pedidos/${pedidoId}`, { [campo]: valor });
      setAlerta({
        mensagem: "Status atualizado com sucesso",
        tipo: "success",
        aberto: true,
      });
    } catch (error) {
      console.error('Error updating status:', error);
      setAlerta({
        mensagem: "Erro ao atualizar status",
        tipo: "error",
        aberto: true,
      });
    } finally {
      if (isMounted.current) {
        setIsUpdatingStatus(false);
      }
    }
  };

  const handleRetiradaChange = async (pedidoId, retirada) => {
    if (readOnly || !isMounted.current) return;
    await atualizarStatus(pedidoId, "retirada", retirada);
  };

  const handleDetalhes = (pedidoId) => {
    if (!isMounted.current) return;
    if (readOnly) {
      navigate(`/visualizar-pedido/${pedidoId}`);
    } else {
      navigate(`/editar-pedido/${pedidoId}`);
    }
  };

  const showClienteFilter = !initialFilters.clienteId;

  const getCabecalhos = () => {
    const baseCabecalhos = [
      "ID",
      "Status",
      ...(showClienteFilter ? ["Cliente"] : []),
      "Data Cadastro",
      "Data Entrega",
      "Data Limite Retirada",
      "Retirada",
      "Detalhes",
      "Total",
      "Valor a Pagar",
      "Pagamento",
      "Ações de Pagamento"
    ];

    if (!readOnly) {
      baseCabecalhos.push("WhatsApp");
    }

    return [
      ...baseCabecalhos,
      "Drive",
      "Urgente",
      "ZoomPlus"
    ];
  };

  // Format date helper function
  const formatDate = (dateString) => {
    if (!dateString) return "Não definida";
    const [year, month, day] = dateString.split("T")[0].split("-");
    return `${day}/${month}/${year.slice(-2)}`;
  };

  // Apply optimistic updates to pedidos
  const optimisticPedidos = getOptimisticState(pedidos);

  return (
    <Box sx={{ padding: "20px", position: "relative" }}>
      {(isLoading || isLoadingPayment || isUpdatingStatus) && (
        <Box
          position="fixed"
          top={80}
          left={0}
          right={0}
          bottom={0}
          display="flex"
          justifyContent="center"
          zIndex={1000}
        >
          <CircularProgress />
        </Box>
      )}

      <AlertaReutilizavel
        mensagem={alerta.mensagem}
        tipo={alerta.tipo}
        aberto={alerta.aberto}
        onFechar={() => setAlerta({ ...alerta, aberto: false })}
      />

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
        {!initialFilters.clienteId && (
          <Typography variant="h4" color="primary">
            Lista de Pedidos
          </Typography>
        )}
        <Box>
          <IconButton onClick={() => setShowFilters(!showFilters)} color="primary">
            <FilterAltIcon />
          </IconButton>
          {showFilters && (
            <IconButton onClick={clearFilters} color="secondary">
              <ClearIcon />
            </IconButton>
          )}
        </Box>
      </Box>

      {showFilters && (
        <PedidosFilter
          filters={filters}
          handleFilterChange={handleFilterChange}
          showClienteFilter={showClienteFilter}
          readOnly={readOnly}
          clientes={clientes}
        />
      )}

      <Paper sx={{ padding: "20px" }}>
        <TabelaReutilizavel
          cabecalhos={getCabecalhos()}
          dados={optimisticPedidos.map((pedido) => ({
            ID: pedido._id,
            Status: pedido.status,
            ...(showClienteFilter ? { Cliente: pedido.clienteId?.nome || "Cliente não encontrado" } : {}),
            DataCadastro: formatDate(pedido.createdAt),
            DataEntrega: formatDate(pedido.dataEntrega),
            DataLimiteRetirada: formatDate(pedido.dataLimiteRetirada),
            Retirada: pedido.retirada,
            Total: formatarValor(pedido.total),
            "Valor a Pagar": formatarValor(pedido.valorPagar),
            Pagamento: getStatusPagamentoLabel(pedido.statusPagamento, pedido.valorPagar, pedido.asaas?.paymentStatus),
            asaas: pedido.asaas,
            ...(readOnly ? {} : { Telefone: pedido.clienteId?.telefone || "" }),
            DriveLink: pedido.linkDrive || "",
            boolean: pedido.isUrgent,
            ZoomPlus: pedido.isZoomPlus ? `Saldo: ${formatarValor(pedido.zoomPlus?.saldoRestante || 0)}` : "Não"
          }))}
          handleStatusChange={!readOnly ? ((id, novoStatus) => atualizarStatus(id, "status", novoStatus)) : undefined}
          handleDetalhes={handleDetalhes}
          handleRetiradaChange={!readOnly ? handleRetiradaChange : undefined}
          handlePaymentAction={(pedidoId, action) => handlePaymentAction(pedidoId, action, optimisticPedidos)}
          readOnly={readOnly}
        />

        <PaginationControls
          page={pagination.page}
          limit={pagination.limit}
          total={pagination.total}
          onPageChange={handlePageChange}
          onLimitChange={handleLimitChange}
          loading={isLoading || isLoadingPayment || isUpdatingStatus}
          itemsPerPageOptions={[10, 25, 50]}
        />
      </Paper>

      <PaymentDetailsDialog
        open={showPaymentDetails}
        onClose={() => setShowPaymentDetails(false)}
        payment={paymentDetails}
      />
    </Box>
  );
});

export default ListaPedidos;
