import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Paper,
  Grid,
  Divider,
  Alert,
  CircularProgress,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import LockIcon from '@mui/icons-material/Lock';
import api from "../api";
import BasicInfoForm from "../components/cliente/BasicInfoForm";
import PlanConfigForm from "../components/cliente/PlanConfigForm";
import useClienteForm from "../hooks/useClienteForm";

const EditarCliente = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [isPlanEditing, setIsPlanEditing] = useState(false);
  const [planos, setPlanos] = useState([]);
  const [alerta, setAlerta] = useState({ mensagem: "", tipo: "info", aberto: false });
  const [originalData, setOriginalData] = useState(null);

  const {
    cliente,
    setCliente,
    formErrors,
    selectedPlano,
    modifiedFields,
    handleFieldChange,
    validateForm,
    getModifiedData
  } = useClienteForm();

  useEffect(() => {
    const fetchClienteAndPlanos = async () => {
      setLoading(true);
      try {
        const [clienteRes, planosRes] = await Promise.all([
          api.get(`/clientes/${id}`),
          api.get("/planos"),
        ]);

        // Format dates for input fields
        const clienteData = clienteRes.data;
        if (clienteData.dataInicioPlano) {
          clienteData.dataInicioPlano = new Date(clienteData.dataInicioPlano).toISOString().split('T')[0];
        }
        if (clienteData.dataFinalPlano) {
          clienteData.dataFinalPlano = new Date(clienteData.dataFinalPlano).toISOString().split('T')[0];
        }

        setCliente(clienteData);
        setOriginalData(clienteData);
        setPlanos(planosRes.data);
      } catch (error) {
        console.error("Erro ao carregar dados:", error);
        setAlerta({
          mensagem: error.response?.data?.error || "Erro ao carregar cliente ou planos.",
          tipo: "error",
          aberto: true
        });
      } finally {
        setLoading(false);
      }
    };
    fetchClienteAndPlanos();
  }, [id, setCliente]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      setAlerta({
        mensagem: "Por favor, corrija os erros no formulário antes de continuar.",
        tipo: "error",
        aberto: true
      });
      return;
    }

    setLoading(true);
    try {
      // Get only modified fields
      const updateData = getModifiedData();

      // Always include isZoomPlus status
      updateData.isZoomPlus = cliente.isZoomPlus;

      // If plan editing was enabled and plan fields were modified,
      // include all plan-related fields to ensure consistency
      if (isPlanEditing && (
        modifiedFields.has('planoId') ||
        modifiedFields.has('dataInicioPlano')
      )) {
        updateData.planoId = cliente.planoId;
        updateData.dataInicioPlano = cliente.dataInicioPlano;
        updateData.saldo = cliente.saldo;
        updateData.taxasUrgenciaDisponiveis = cliente.taxasUrgenciaDisponiveis;
        updateData.usoModelosDisponiveis = cliente.usoModelosDisponiveis;
      }

      await api.put(`/clientes/${id}`, updateData);
      setAlerta({ mensagem: "Cliente atualizado com sucesso!", tipo: "success", aberto: true });
      setTimeout(() => navigate(`/dados-cliente/${id}`), 1500);
    } catch (error) {
      setAlerta({
        mensagem: error.response?.data?.error || "Erro ao atualizar cliente.",
        tipo: "error",
        aberto: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const togglePlanEditing = () => {
    if (!isPlanEditing) {
      setConfirmDialogOpen(true);
    } else {
      setIsPlanEditing(false);
      // Reset plan-related fields to original values
      if (originalData) {
        setCliente(prev => ({
          ...prev,
          planoId: originalData.planoId,
          planoNome: originalData.planoNome,
          dataInicioPlano: originalData.dataInicioPlano,
          dataFinalPlano: originalData.dataFinalPlano,
          diaVencimento: originalData.diaVencimento,
          valorMensalidade: originalData.valorMensalidade,
          saldo: originalData.saldo,
          taxasUrgenciaDisponiveis: originalData.taxasUrgenciaDisponiveis,
          usoModelosDisponiveis: originalData.usoModelosDisponiveis
        }));
      }
    }
  };

  const confirmPlanEditing = () => {
    setIsPlanEditing(true);
    setConfirmDialogOpen(false);
  };

  if (loading && !cliente.id) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Paper>
      <Box p={3}>
        <Typography variant="h5" gutterBottom>
          Editar Cliente
        </Typography>
        {alerta.aberto && (
          <Alert severity={alerta.tipo} sx={{ mb: 2 }}>
            {alerta.mensagem}
          </Alert>
        )}
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            {/* Informações Básicas */}
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Informações Básicas
              </Typography>
              <Divider sx={{ mb: 2 }} />
            </Grid>

            <Grid item xs={12}>
              <BasicInfoForm
                cliente={cliente}
                onChange={handleFieldChange}
                formErrors={formErrors}
              />
            </Grid>

            {/* Configurações do Plano */}
            <Grid item xs={12}>
              <Box display="flex" alignItems="center" gap={2}>
                <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                  Configurações do Plano
                </Typography>
                {cliente.isZoomPlus && (
                  <Tooltip title={isPlanEditing ? "Cancelar edição do plano" : "Habilitar edição do plano"}>
                    <IconButton
                      onClick={togglePlanEditing}
                      color={isPlanEditing ? "error" : "primary"}
                      size="small"
                    >
                      {isPlanEditing ? <LockIcon /> : <EditIcon />}
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
              <Divider sx={{ mb: 2 }} />
            </Grid>

            <Grid item xs={12}>
              <PlanConfigForm
                cliente={cliente}
                onChange={handleFieldChange}
                planos={planos}
                formErrors={formErrors}
                disabled={!isPlanEditing && cliente.isZoomPlus}
                selectedPlano={selectedPlano}
                showAdminSwitch={true}
              />
            </Grid>

            {/* Botões de Ação */}
            <Grid item xs={12}>
              <Box display="flex" gap={2} justifyContent="flex-end" mt={2}>
                <Button
                  variant="outlined"
                  onClick={() => navigate(`/dados-cliente/${id}`)}
                  disabled={loading}
                >
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={loading}
                >
                  {loading ? <CircularProgress size={24} /> : "Atualizar Cliente"}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>

      {/* Confirmation Dialog */}
      <Dialog open={confirmDialogOpen} onClose={() => setConfirmDialogOpen(false)}>
        <DialogTitle>Confirmar Edição do Plano</DialogTitle>
        <DialogContent>
          <Typography>
            Tem certeza que deseja editar as configurações do plano? Esta ação pode afetar o funcionamento do plano do cliente.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDialogOpen(false)}>Cancelar</Button>
          <Button onClick={confirmPlanEditing} color="primary">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default EditarCliente;
