import { useEffect, useCallback, useRef } from 'react';

export const useWebSocket = (onMessage, options = {}) => {
    const {
        retryAttempts = 5,
        retryDelay = 1000,
        reconnectOnClose = true,
        heartbeatInterval = 15000, // Match server's heartbeat interval
        connectionTimeout = 10000  // Match server's connection timeout
    } = options;

    const ws = useRef(null);
    const retryCount = useRef(0);
    const isConnected = useRef(false);
    const messageQueue = useRef([]);
    const processingMessage = useRef(false);
    const reconnectTimeout = useRef(null);
    const heartbeatTimeout = useRef(null);
    const lastPongTime = useRef(Date.now());
    const mounted = useRef(true);

    const processMessageQueue = useCallback(async () => {
        if (!mounted.current || processingMessage.current || messageQueue.current.length === 0) return;

        processingMessage.current = true;
        try {
            const messages = [...messageQueue.current];
            messageQueue.current = [];

            for (const message of messages) {
                if (!mounted.current) break;
                try {
                    onMessage(message);
                } catch (error) {
                }
            }
        } finally {
            processingMessage.current = false;
            if (messageQueue.current.length > 0 && mounted.current) {
                setTimeout(processMessageQueue, 0);
            }
        }
    }, [onMessage]);

    const startHeartbeat = useCallback(() => {
        if (heartbeatTimeout.current) {
            clearInterval(heartbeatTimeout.current);
        }

        heartbeatTimeout.current = setInterval(() => {
            if (ws.current?.readyState === WebSocket.OPEN) {
                // Check if we haven't received a pong in twice the heartbeat interval
                if (Date.now() - lastPongTime.current > heartbeatInterval * 2) {
                    if (ws.current) {
                        ws.current.close();
                    }
                    return;
                }

                try {
                    ws.current.send(JSON.stringify({ event: 'ping' }));
                } catch (error) {
                    if (ws.current) {
                        ws.current.close();
                    }
                }
            }
        }, heartbeatInterval);
    }, [heartbeatInterval]);

    const connect = useCallback(() => {
        if (ws.current?.readyState === WebSocket.OPEN) {
            return;
        }

        if (reconnectTimeout.current) {
            clearTimeout(reconnectTimeout.current);
            reconnectTimeout.current = null;
        }

        try {
            const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
            const host = process.env.REACT_APP_WS_URL || options.url || `${protocol}//${window.location.hostname}:5000`;

            ws.current = new WebSocket(host);

            // Set connection timeout
            const connectionTimeoutId = setTimeout(() => {
                if (ws.current?.readyState === WebSocket.CONNECTING) {
                    ws.current.close();
                }
            }, connectionTimeout);

            ws.current.onopen = () => {
                clearTimeout(connectionTimeoutId);
                isConnected.current = true;
                retryCount.current = 0;
                lastPongTime.current = Date.now();
                startHeartbeat();

                if (messageQueue.current.length > 0) {
                    processMessageQueue();
                }
            };

            ws.current.onclose = (event) => {
                clearTimeout(connectionTimeoutId);
                isConnected.current = false;

                if (heartbeatTimeout.current) {
                    clearInterval(heartbeatTimeout.current);
                }

                if (mounted.current && reconnectOnClose && retryCount.current < retryAttempts) {
                    const delay = retryDelay * Math.pow(2, retryCount.current);

                    reconnectTimeout.current = setTimeout(() => {
                        retryCount.current++;
                        connect();
                    }, delay);
                } else if (retryCount.current >= retryAttempts) {
                    console.error('[WebSocket] Max retry attempts reached');
                }
            };

            ws.current.onerror = (error) => {
                if (options.onError) {
                    options.onError(error);
                }
            };

            ws.current.onmessage = (event) => {
                if (!mounted.current) return;

                try {
                    const data = JSON.parse(event.data);

                    // Update last pong time for any message received
                    lastPongTime.current = Date.now();

                    if (data.event === 'pong') {
                        return; // Don't process pong messages
                    }

                    messageQueue.current.push(data);
                    if (!processingMessage.current) {
                        processMessageQueue();
                    }
                } catch (error) {
                }
            };
        } catch (error) {
            console.error('[WebSocket] Connection error:', error);

            if (mounted.current && reconnectOnClose && retryCount.current < retryAttempts) {
                const delay = retryDelay * Math.pow(2, retryCount.current);

                reconnectTimeout.current = setTimeout(() => {
                    retryCount.current++;
                    connect();
                }, delay);
            }
        }
    }, [onMessage, retryAttempts, retryDelay, reconnectOnClose, processMessageQueue, options, startHeartbeat, connectionTimeout]);

    useEffect(() => {
        mounted.current = true;
        connect();

        return () => {
            mounted.current = false;
            if (reconnectTimeout.current) {
                clearTimeout(reconnectTimeout.current);
            }
            if (heartbeatTimeout.current) {
                clearInterval(heartbeatTimeout.current);
            }
            if (ws.current) {
                ws.current.close();
            }
            messageQueue.current = [];
            processingMessage.current = false;
        };
    }, [connect]);

    const reconnect = useCallback(() => {
        if (ws.current) {
            ws.current.close();
        }
        retryCount.current = 0;
        connect();
    }, [connect]);

    return {
        isConnected: isConnected.current,
        reconnect,
        sendMessage: (message) => {
            if (ws.current?.readyState === WebSocket.OPEN) {
                try {
                    ws.current.send(JSON.stringify(message));
                } catch (error) {
                    // Attempt reconnection on send error
                    reconnect();
                }
            }
        }
    };
};
