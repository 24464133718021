import React, { useMemo, useCallback } from 'react';
import { Box, Typography, CircularProgress, useTheme } from '@mui/material';
import PaymentsFilterBar from './PaymentsFilterBar';
import TabelaReutilizavel from './TabelaReutilizavel';
import PaymentDetailsDialog from './PaymentDetailsDialog';
import PaginationControls from './PaginationControls';
import { getStatusPagamentoLabel } from '../utils/pedidoUtils';
import { usePaymentHandling } from '../hooks/usePaymentHandling';

const PaymentsTable = ({
    payments = [],
    loading = false,
    onLoadPayments,
    page,
    limit,
    totalCount,
    filters,
    onFilterChange,
    onPageChange,
    onLimitChange,
    showFilters = true,
    showPagination = true,
    filterOptions,
}) => {
    const theme = useTheme();

    const {
        paymentDetails,
        showPaymentDetails,
        isLoadingPayment,
        handlePaymentAction,
        setShowPaymentDetails,
        getOptimisticState
    } = usePaymentHandling(onLoadPayments, (alert) => {
        console.log('Payment alert:', alert);
    });

    // Apply optimistic updates to the payment data
    const optimisticPayments = useMemo(() =>
        getOptimisticState(payments),
        [payments, getOptimisticState]);

    const formatDate = useCallback((dateString) => {
        if (!dateString) return '-';
        return new Date(dateString).toLocaleDateString('pt-BR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
    }, []);

    const getPaymentOrigin = useCallback((payment) => {
        if (!payment) return '-';

        if (payment.subscription || (payment.externalReference && payment.externalReference.startsWith('subscription-'))) {
            return 'Assinatura';
        }

        if (payment.description && payment.description.toLowerCase().includes('pedido')) {
            const parts = payment.description.split(' ');
            if (parts.length > 1 && parts[0].toLowerCase() === 'pedido') {
                return `Pedido ${parts[1]}`;
            }
            return 'Pedido';
        }

        return '-';
    }, []);

    const handleDetalhes = useCallback((payment) => {
        const paymentData = optimisticPayments.find(p => p.id === payment);
        if (paymentData) {
            handlePaymentAction(paymentData.id, 'view', optimisticPayments);
        }
    }, [handlePaymentAction, optimisticPayments]);

    const handleCloseDialog = useCallback(() => {
        setShowPaymentDetails(false);
    }, [setShowPaymentDetails]);

    const cabecalhos = useMemo(() => [
        'ID',
        'Cliente',
        'Origem',
        'Data Cadastro',
        'Data Vencimento',
        'Detalhes',
        'Total',
        'Pagamento',
        'Ações de Pagamento'
    ], []);

    const transformedData = useMemo(() => optimisticPayments.map(payment => ({
        ID: payment.id || '',
        Cliente: payment.customerDetails?.name || '',
        Origem: getPaymentOrigin(payment),
        DataCadastro: formatDate(payment.dateCreated),
        DataVencimento: formatDate(payment.dueDate),
        Detalhes: 'Visualizar',
        Total: `R$ ${Number(payment.value || 0).toFixed(2)}`,
        Pagamento: getStatusPagamentoLabel(payment.status, payment.value, payment.status),
        asaas: {
            paymentId: payment.id,
            paymentStatus: payment.status
        },
        customer: payment.customer || payment.customerDetails?.id,
        value: payment.value,
        description: payment.description,
        externalReference: payment.externalReference,
        dueDate: payment.dueDate,
        _raw: {
            description: payment.description
        }
    })), [optimisticPayments, formatDate, getPaymentOrigin]);

    return (
        <Box sx={{ position: 'relative', minHeight: '200px' }}>
            {showFilters && filterOptions && (
                <Box sx={{ mb: 3 }}>
                    <PaymentsFilterBar
                        filters={filters}
                        onFilterChange={onFilterChange}
                        filterOptions={filterOptions}
                    />
                </Box>
            )}

            <Box sx={{ position: 'relative' }}>
                {(loading || isLoadingPayment) && (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: theme.palette.mode === 'light'
                                ? 'rgba(255, 255, 255, 0.7)'
                                : 'rgba(18, 18, 18, 0.7)',
                            zIndex: 1,
                        }}
                    >
                        <CircularProgress />
                    </Box>
                )}

                <Box sx={{ mt: 3 }}>
                    {transformedData.length > 0 ? (
                        <TabelaReutilizavel
                            cabecalhos={cabecalhos}
                            dados={transformedData}
                            handleDetalhes={handleDetalhes}
                            handlePaymentAction={(id, action) => handlePaymentAction(id, action, payments)}
                            readOnly={false}
                        />
                    ) : !loading && (
                        <Typography variant="body1" sx={{ textAlign: 'center', py: 4 }}>
                            Nenhum pagamento encontrado
                        </Typography>
                    )}
                </Box>

                {showPagination && transformedData.length > 0 && (
                    <PaginationControls
                        page={Math.max(1, Number(page))}
                        limit={Number(limit)}
                        total={Number(totalCount)}
                        onPageChange={onPageChange}
                        onLimitChange={onLimitChange}
                        loading={loading || isLoadingPayment}
                        itemsPerPageOptions={[5, 10, 25, 50]}
                    />
                )}
            </Box>

            <PaymentDetailsDialog
                open={showPaymentDetails}
                onClose={handleCloseDialog}
                payment={paymentDetails}
            />
        </Box>
    );
};

export default React.memo(PaymentsTable);
