import React from 'react';
import { Box, Typography, FormControl, InputLabel, Select, MenuItem, Pagination } from '@mui/material';

const PaginationControls = ({
    page,
    limit,
    total,
    onPageChange,
    onLimitChange,
    loading = false,
    itemsPerPageOptions = [5, 10, 25, 50]
}) => {
    const totalPages = Math.ceil(total / limit);

    // Ensure page is treated as a number and is 1-based
    const currentPage = Math.max(1, Number(page));

    const handlePageChange = (event, newPage) => {
        if (newPage >= 1 && newPage <= totalPages && !loading) {
            onPageChange(event, newPage);
        }
    };

    return (
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <FormControl variant="outlined" size="small" sx={{ minWidth: 120 }}>
                <InputLabel>Itens por página</InputLabel>
                <Select
                    value={limit}
                    onChange={onLimitChange}
                    label="Itens por página"
                    disabled={loading}
                >
                    {itemsPerPageOptions.map(option => (
                        <MenuItem key={option} value={option}>{option}</MenuItem>
                    ))}
                </Select>
            </FormControl>

            <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
                disabled={loading}
                showFirstButton
                showLastButton
                siblingCount={1}
                boundaryCount={1}
            />

            <Typography variant="body2" color="text.secondary">
                Total: {total} {total === 1 ? 'item' : 'itens'}
            </Typography>
        </Box>
    );
};

export default React.memo(PaginationControls);
