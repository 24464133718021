import React from "react";
import { Typography, TextField, Box, Divider, useTheme, Paper } from "@mui/material";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import DiscountIcon from "@mui/icons-material/Discount";
import SummarizeIcon from "@mui/icons-material/Summarize";
import SpeedIcon from "@mui/icons-material/Speed";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import PaidIcon from "@mui/icons-material/Paid";
import { Savings, CreditCard, EventAvailable, Payments as PaymentIcon } from "@mui/icons-material";
import { getStatusPagamentoLabel } from "../utils/pedidoUtils";

export const ResumoPedido = ({
  totais,
  dataEntrega,
  dataLimiteRetirada,
  setDataEntrega,
  beneficiosUtilizados = { taxaUrgenciaGratis: false, modeloCompletaGratis: false },
  clienteZoomPlus = null,
}) => {
  const theme = useTheme();

  const ResumoItem = ({ icon: Icon, label, value, color = "primary", highlighted, strikethrough, additionalValue }) => (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        mb: 1,
        p: 1,
        borderRadius: 1,
        backgroundColor: highlighted ? theme.palette.background.elevated : 'transparent',
      }}
    >
      <Typography display="flex" alignItems="center" color={theme.palette.text.primary}>
        <Icon sx={{ color: theme.palette[color].main, mr: 1 }} />
        {label}
      </Typography>
      <Box sx={{ textAlign: 'right' }}>
        <Typography
          sx={{
            fontWeight: "bold",
            color: theme.palette[color].main,
            textDecoration: strikethrough ? "line-through" : "none",
          }}
        >
          {value}
        </Typography>
        {additionalValue && (
          <Typography
            sx={{
              fontWeight: "bold",
              color: theme.palette.primary.main,
              fontSize: "0.9rem",
            }}
          >
            {additionalValue}
          </Typography>
        )}
      </Box>
    </Box>
  );

  return (
    <Box sx={{ p: 0 }}>
      {/* Título */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: theme.palette.background.elevated,
          borderRadius: "8px",
          padding: "16px",
          mb: 3,
          boxShadow: theme.shadows[1],
        }}
      >
        <Box display="flex" alignItems="center">
          <SummarizeIcon sx={{ fontSize: 20, color: "primary.main", mr: 2 }} />
          <Typography variant="h6" sx={{ fontWeight: "bold", color: theme.palette.text.primary }}>
            Resumo do Pedido
          </Typography>
        </Box>
      </Box>

      <Paper sx={{ p: 2, mb: 2, backgroundColor: theme.palette.background.paper }}>

        {/* Total dos Serviços */}
        <ResumoItem
          icon={AttachMoneyIcon}
          label="Total dos Serviços"
          value={`R$ ${totais.total.toFixed(2)}`}
          color="primary"
        />

        {/* Taxa de Urgência */}
        {totais.taxaUrgencia > 0 && (
          <ResumoItem
            icon={beneficiosUtilizados.taxaUrgenciaGratis ? CardGiftcardIcon : SpeedIcon}
            label="Taxa de Urgência"
            value={`R$ ${totais.taxaUrgencia.toFixed(2)}`}
            additionalValue={beneficiosUtilizados.taxaUrgenciaGratis ? "R$ 0,00" : null}
            color={beneficiosUtilizados.taxaUrgenciaGratis ? "primary" : "secondary"}
            strikethrough={beneficiosUtilizados.taxaUrgenciaGratis}
          />
        )}

        {/* Total com Urgência */}
        {totais.totalComUrgencia !== totais.total && !beneficiosUtilizados.taxaUrgenciaGratis && (
          <ResumoItem
            icon={AttachMoneyIcon}
            label="Total com Urgência"
            value={`R$ ${totais.totalComUrgencia.toFixed(2)}`}
            color="primary"
          />
        )}

        {/* Desconto do Plano */}
        {totais.desconto > 0 && (
          <ResumoItem
            icon={DiscountIcon}
            label="Desconto do Plano"
            value={`- R$ ${totais.desconto.toFixed(2)}`}
            color="primary"
          />
        )}

        <Divider sx={{ my: 2 }} />

        {/* Total Final */}
        <ResumoItem
          icon={PaidIcon}
          label="Total Final"
          value={`R$ ${totais.totalFinal.toFixed(2)}`}
          color="primary"
          highlighted
        />

        {/* Status de Pagamento */}
        <ResumoItem
          icon={PaymentIcon}
          label="Status do Pagamento"
          value={getStatusPagamentoLabel(totais.statusPagamento, totais.valorPagar)}
          color={totais.statusPagamento === "pago" || totais.statusPagamento === "pago_com_saldo" ? "primary" : "secondary"}
          highlighted
        />
      </Paper>

      {/* Seção de Saldo - Apenas para clientes Zoom+ */}
      {clienteZoomPlus && clienteZoomPlus.saldo > 0 && (
        <Paper sx={{ p: 2, mb: 2, backgroundColor: theme.palette.background.paper }}>
          <Typography variant="h6" sx={{ mb: 2, color: theme.palette.text.primary }}>
            Saldo ZOOM+
          </Typography>

          <ResumoItem
            icon={AccountBalanceWalletIcon}
            label="Saldo Disponível"
            value={`R$ ${clienteZoomPlus.saldo.toFixed(2)}`}
            color="primary"
          />

          {totais.saldoUtilizado > 0 && (
            <>
              <ResumoItem
                icon={CardGiftcardIcon}
                label="Saldo Utilizado"
                value={`- R$ ${totais.saldoUtilizado.toFixed(2)}`}
                color="secondary"
              />

              <ResumoItem
                icon={Savings}
                label="Saldo Restante"
                value={`R$ ${totais.saldoRestante.toFixed(2)}`}
                color="primary"
              />

              <Divider sx={{ my: 2 }} />

              <ResumoItem
                icon={PaidIcon}
                label="Valor a Pagar"
                value={`R$ ${totais.valorPagar.toFixed(2)}`}
                color={totais.valorPagar === 0 ? "primary" : "secondary"}
                highlighted
              />
            </>
          )}
        </Paper>
      )}

      {/* Datas */}
      <Paper sx={{ p: 2, backgroundColor: theme.palette.background.paper }}>

        <TextField
          label="Data de Entrega"
          value={
            dataEntrega
              ? new Date(dataEntrega).toLocaleDateString("pt-BR", {
                day: "2-digit",
                month: "2-digit",
              })
              : ""
          }
          onChange={(e) => setDataEntrega(e.target.value)}
          variant="outlined"
          fullWidth
          sx={{ mb: 2 }}
          InputProps={{
            readOnly: true,
          }}
        />
      </Paper>
    </Box>
  );
};

export default ResumoPedido;
