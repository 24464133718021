import React, { useState } from 'react';
import {
    Box,
    Container,
    Typography,
    Paper,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Button,
    useTheme,
    useMediaQuery,
    Card,
    CardContent,
    Link
} from '@mui/material';
import {
    ExpandMore,
    Help,
    Email,
    WhatsApp,
    LiveHelp,
    Article
} from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';
import SectionTitle from '../../components/SectionTitle';

const UserHelp = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const faqs = [
        {
            question: 'Qual é o prazo de entrega dos serviços?',
            answer: 'Os prazos variam de acordo com o tipo de assinatura: Clube Premium: até 3 dias úteis, Clube Profissional: até 4 dias úteis, Clube Essencial: até 5 dias úteis. Para não-assinantes: até 7 dias úteis.'
        },
        {
            question: 'Como funciona o pagamento?',
            answer: 'Oferecemos diversas formas de pagamento: PIX, Transferência Bancária, Dinheiro em Espécie e Cartão de Crédito (com taxa adicional). O pagamento deve ser efetuado antes da liberação dos arquivos.'
        },
        {
            question: 'Como funciona a entrega dos arquivos?',
            answer: 'Os arquivos são enviados via Google Drive, mantendo a qualidade original. Recomendamos baixar os arquivos em até 15 dias, pois após esse período eles podem ser removidos da plataforma.'
        },
        {
            question: 'O que acontece com os produtos após a sessão?',
            answer: 'É importante retirar os produtos do estúdio o quanto antes após a finalização do pedido. Produtos que permanecerem por mais de 30 dias no estúdio serão doados para instituições de caridade sem aviso prévio.'
        },
        {
            question: 'Como funciona a taxa de urgência?',
            answer: 'Com a taxa de urgência (acréscimo de 40% sobre o valor total), entregamos em até 48h corridas. Assinantes do clube têm direito a urgências gratuitas mensais: Premium: 3, Profissional: 2 e Essencial: 1.'
        }
    ];

    const supportChannels = [
        {
            icon: WhatsApp,
            title: 'WhatsApp',
            description: 'Atendimento rápido via mensagem',
            action: 'Iniciar conversa',
            link: '#'
        },
        {
            icon: Email,
            title: 'E-mail',
            description: 'suporte@zoompro.com.br',
            action: 'Enviar e-mail',
            link: 'mailto:suporte@zoompro.com.br'
        },
        {
            icon: Article,
            title: 'Base de Conhecimento',
            description: 'Artigos e tutoriais detalhados',
            action: 'Acessar artigos',
            link: '/base-de-conhecimento',
            isInternal: true
        }
    ];

    const SupportCard = ({ icon: Icon, title, description, action, link, isInternal }) => (
        <Card
            sx={{
                height: '100%',
                bgcolor: theme.palette.background.elevated,
                borderRadius: 2,
                border: `1px solid ${theme.palette.divider}`,
                transition: 'transform 0.2s',
                '&:hover': {
                    transform: 'translateY(-4px)'
                }
            }}
        >
            <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                    <Box
                        sx={{
                            width: 48,
                            height: 48,
                            borderRadius: '12px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.primary.light} 90%)`,
                        }}
                    >
                        <Icon sx={{ color: 'white' }} />
                    </Box>
                    <Typography variant="h6" fontWeight={600}>
                        {title}
                    </Typography>
                </Box>
                <Typography color="text.secondary" sx={{ mb: 2 }}>
                    {description}
                </Typography>
                <Button
                    component={isInternal ? RouterLink : Link}
                    to={isInternal ? link : undefined}
                    href={!isInternal ? link : undefined}
                    variant="outlined"
                    color="primary"
                    startIcon={<Icon />}
                    sx={{
                        borderColor: theme.palette.primary.main,
                        '&:hover': {
                            borderColor: theme.palette.primary.main,
                            backgroundColor: `${theme.palette.primary.main}15`
                        }
                    }}
                >
                    {action}
                </Button>
            </CardContent>
        </Card>
    );

    return (
        <Box sx={{
            minHeight: "100vh",
            background: theme.palette.background.default,
            p: { xs: 2, sm: 4 }
        }}>
            <Container maxWidth="lg">
                <SectionTitle
                    variant="h4"
                    mb={6}
                    width={250}
                >
                    Central de Ajuda
                </SectionTitle>

                <Box sx={{ mb: 6 }}>
                    <Paper
                        elevation={0}
                        sx={{
                            p: { xs: 2, sm: 3 },
                            background: theme.palette.background.elevated,
                            borderRadius: '16px',
                            border: `1px solid ${theme.palette.divider}`
                        }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 3 }}>
                            <Box
                                sx={{
                                    width: 48,
                                    height: 48,
                                    borderRadius: '12px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.primary.light} 90%)`,
                                }}
                            >
                                <LiveHelp sx={{ color: 'white' }} />
                            </Box>
                            <Box>
                                <Typography variant="h6" fontWeight={600}>
                                    Como podemos ajudar?
                                </Typography>
                                <Typography color="text.secondary">
                                    Escolha um canal de atendimento ou consulte nossas FAQs
                                </Typography>
                            </Box>
                        </Box>

                        <Box sx={{
                            display: 'grid',
                            gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr', md: '1fr 1fr 1fr' },
                            gap: 3
                        }}>
                            {supportChannels.map((channel, index) => (
                                <SupportCard key={index} {...channel} />
                            ))}
                        </Box>
                    </Paper>
                </Box>

                <Typography
                    variant="h5"
                    sx={{
                        mb: 3,
                        fontWeight: 600,
                        color: theme.palette.text.primary
                    }}
                >
                    Perguntas Frequentes
                </Typography>

                <Box>
                    {faqs.map((faq, index) => (
                        <Accordion
                            key={index}
                            expanded={expanded === `panel${index}`}
                            onChange={handleChange(`panel${index}`)}
                            sx={{
                                background: theme.palette.background.elevated,
                                borderRadius: '12px !important',
                                mb: 2,
                                border: `1px solid ${theme.palette.divider}`,
                                '&:before': {
                                    display: 'none',
                                },
                                '&.Mui-expanded': {
                                    margin: '0 0 16px 0',
                                }
                            }}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                sx={{
                                    '& .MuiAccordionSummary-content': {
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 2
                                    }
                                }}
                            >
                                <Help sx={{ color: theme.palette.primary.main }} />
                                <Typography fontWeight={500}>
                                    {faq.question}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography color="text.secondary">
                                    {faq.answer}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Box>
            </Container>
        </Box>
    );
};

export default UserHelp;
