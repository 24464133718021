import React, { useState } from 'react';
import {
    Box,
    Container,
    Typography,
    Paper,
    TextField,
    Button,
    useTheme,
    Alert,
    IconButton,
    InputAdornment
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import SectionTitle from '../../components/SectionTitle';
import api from '../../api';

const UserSettings = () => {
    const theme = useTheme();
    const [formData, setFormData] = useState({
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
    });
    const [errors, setErrors] = useState({});
    const [showPasswords, setShowPasswords] = useState({
        current: false,
        new: false,
        confirm: false
    });
    const [feedback, setFeedback] = useState({ type: '', message: '' });
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleChange = (field) => (event) => {
        setFormData(prev => ({
            ...prev,
            [field]: event.target.value
        }));
        setErrors(prev => ({
            ...prev,
            [field]: ''
        }));
        setFeedback({ type: '', message: '' });
    };

    const togglePasswordVisibility = (field) => {
        setShowPasswords(prev => ({
            ...prev,
            [field]: !prev[field]
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors({});
        setFeedback({ type: '', message: '' });

        // Validações
        if (!formData.currentPassword || !formData.newPassword) {
            setErrors(prev => ({
                ...prev,
                newPassword: 'Senha atual e nova senha são obrigatórias'
            }));
            return;
        }

        if (formData.newPassword.length < 6) {
            setErrors(prev => ({
                ...prev,
                newPassword: 'A nova senha deve ter no mínimo 6 caracteres'
            }));
            return;
        }

        if (formData.newPassword !== formData.confirmPassword) {
            setErrors(prev => ({
                ...prev,
                confirmPassword: 'As senhas não coincidem'
            }));
            return;
        }

        if (formData.newPassword === formData.currentPassword) {
            setErrors(prev => ({
                ...prev,
                newPassword: 'A nova senha deve ser diferente da senha atual'
            }));
            return;
        }

        setIsSubmitting(true);
        try {
            await api.put('/user/change-password', {
                currentPassword: formData.currentPassword,
                newPassword: formData.newPassword
            });
            setFeedback({
                type: 'success',
                message: 'Senha alterada com sucesso!'
            });
            setFormData({
                currentPassword: '',
                newPassword: '',
                confirmPassword: ''
            });
        } catch (error) {
            setFeedback({
                type: 'error',
                message: error.response?.data?.message || 'Erro ao alterar a senha. Tente novamente.'
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Box sx={{
            minHeight: "100vh",
            background: theme.palette.background.default,
            p: { xs: 2, sm: 4 }
        }}>
            <Container maxWidth="sm">
                <SectionTitle
                    variant="h4"
                    mb={6}
                    width={250}
                >
                    Alterar Senha
                </SectionTitle>

                <Paper
                    elevation={0}
                    sx={{
                        p: { xs: 2, sm: 3 },
                        background: theme.palette.background.elevated,
                        borderRadius: '16px',
                        border: `1px solid ${theme.palette.divider}`,
                    }}
                >
                    {feedback.message && (
                        <Alert
                            severity={feedback.type}
                            sx={{ mb: 2 }}
                            onClose={() => setFeedback({ type: '', message: '' })}
                        >
                            {feedback.message}
                        </Alert>
                    )}

                    <form onSubmit={handleSubmit}>
                        <TextField
                            fullWidth
                            label="Senha Atual"
                            type={showPasswords.current ? 'text' : 'password'}
                            value={formData.currentPassword}
                            onChange={handleChange('currentPassword')}
                            error={!!errors.currentPassword}
                            helperText={errors.currentPassword}
                            margin="normal"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => togglePasswordVisibility('current')}
                                            edge="end"
                                        >
                                            {showPasswords.current ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <TextField
                            fullWidth
                            label="Nova Senha"
                            type={showPasswords.new ? 'text' : 'password'}
                            value={formData.newPassword}
                            onChange={handleChange('newPassword')}
                            error={!!errors.newPassword}
                            helperText={errors.newPassword}
                            margin="normal"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => togglePasswordVisibility('new')}
                                            edge="end"
                                        >
                                            {showPasswords.new ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <TextField
                            fullWidth
                            label="Confirmar Nova Senha"
                            type={showPasswords.confirm ? 'text' : 'password'}
                            value={formData.confirmPassword}
                            onChange={handleChange('confirmPassword')}
                            error={!!errors.confirmPassword}
                            helperText={errors.confirmPassword}
                            margin="normal"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => togglePasswordVisibility('confirm')}
                                            edge="end"
                                        >
                                            {showPasswords.confirm ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <Typography
                            variant="caption"
                            color="textSecondary"
                            sx={{ display: 'block', mt: 1, mb: 2 }}
                        >
                            A senha deve ter no mínimo 6 caracteres.
                        </Typography>

                        <Button
                            type="submit"
                            variant="contained"
                            fullWidth
                            disabled={isSubmitting}
                            sx={{
                                mt: 2,
                                background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.primary.light} 90%)`,
                                color: 'white',
                                '&:hover': {
                                    background: `linear-gradient(45deg, ${theme.palette.primary.dark} 30%, ${theme.palette.primary.main} 90%)`
                                }
                            }}
                        >
                            {isSubmitting ? 'Alterando...' : 'Alterar Senha'}
                        </Button>
                    </form>
                </Paper>
            </Container>
        </Box>
    );
};

export default UserSettings;
