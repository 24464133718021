import { useState, useCallback } from 'react';
import paymentService from '../services/paymentService';

const MAX_RETRIES = 3;
const INITIAL_RETRY_DELAY = 1000;

export const usePaymentHandling = (carregarPedidos, setAlerta) => {
    const [paymentDetails, setPaymentDetails] = useState(null);
    const [showPaymentDetails, setShowPaymentDetails] = useState(false);
    const [isLoadingPayment, setIsLoadingPayment] = useState(false);
    const [optimisticUpdates, setOptimisticUpdates] = useState({});

    const retryOperation = async (operation, retries = 0) => {
        try {
            return await operation();
        } catch (error) {
            if (retries < MAX_RETRIES) {
                const delay = INITIAL_RETRY_DELAY * Math.pow(2, retries);
                await new Promise(resolve => setTimeout(resolve, delay));
                return retryOperation(operation, retries + 1);
            }
            throw error;
        }
    };

    const handlePaymentStatusChange = useCallback(async (pedidoId, newStatus) => {
        try {
            await carregarPedidos(true);
        } catch (error) {
            console.error('Error refreshing data:', error);
            setAlerta({
                mensagem: "Erro ao atualizar dados",
                tipo: "error",
                aberto: true,
            });
        }
    }, [carregarPedidos, setAlerta]);

    const handlePaymentAction = useCallback(async (pedidoId, action, pedidos) => {
        setIsLoadingPayment(true);
        const pedido = pedidos.find(p => p._id === pedidoId || p.id === pedidoId);
        if (!pedido) return;

        try {
            let newPayment;
            switch (action) {
                case 'view':
                    // Handle both payment and order contexts
                    if (pedido.id) {
                        // Direct payment object
                        setPaymentDetails(pedido);
                        setShowPaymentDetails(true);
                    } else if (pedido.asaas?.paymentId) {
                        // Order object with payment reference
                        const details = await retryOperation(() =>
                            paymentService.getPayment(pedido.asaas.paymentId)
                        );
                        setPaymentDetails(details);
                        setShowPaymentDetails(true);

                        // Immediately update status if it has changed
                        if (['RECEIVED', 'CONFIRMED', 'RECEIVED_IN_CASH'].includes(details.status) &&
                            pedido.statusPagamento !== 'pago') {
                            // Apply optimistic update
                            setOptimisticUpdates(prev => ({
                                ...prev,
                                [pedidoId]: {
                                    statusPagamento: 'pago',
                                    asaas: {
                                        ...pedido.asaas,
                                        paymentStatus: details.status
                                    }
                                }
                            }));

                            // Update in background
                            handlePaymentStatusChange(pedidoId, details.status);
                        } else if (details.status === 'OVERDUE' && pedido.statusPagamento !== 'pendente') {
                            // Apply optimistic update for overdue status
                            setOptimisticUpdates(prev => ({
                                ...prev,
                                [pedidoId]: {
                                    statusPagamento: 'pendente',
                                    asaas: {
                                        ...pedido.asaas,
                                        paymentStatus: details.status
                                    }
                                }
                            }));

                            // Update in background
                            handlePaymentStatusChange(pedidoId, details.status);
                        }
                    }
                    break;

                case 'pix':
                case 'boleto':
                case 'credit_card':
                    const paymentData = {
                        customer: pedido.asaas?.customerId,
                        billingType: action === 'pix' ? 'PIX' : action === 'boleto' ? 'BOLETO' : 'CREDIT_CARD',
                        value: pedido.valorPagar,
                        dueDate: pedido.dataEntrega,
                        description: `Pedido #${pedido._id}`,
                        externalReference: pedido._id
                    };

                    newPayment = await retryOperation(async () => {
                        if (action === 'pix') {
                            return await paymentService.createPixPayment(paymentData);
                        } else {
                            return await paymentService.createPayment(paymentData);
                        }
                    });

                    // Apply optimistic update for new payment
                    setOptimisticUpdates(prev => ({
                        ...prev,
                        [pedidoId]: {
                            statusPagamento: 'pendente',
                            asaas: {
                                paymentId: newPayment.id,
                                paymentStatus: newPayment.status,
                                billingType: newPayment.billingType,
                                dueDate: newPayment.dueDate,
                                paymentLink: newPayment.invoiceUrl || newPayment.bankSlipUrl
                            }
                        }
                    }));

                    await carregarPedidos(true);
                    break;

                case 'cancel':
                    const paymentId = pedido.id || pedido.asaas?.paymentId;
                    if (paymentId) {
                        await retryOperation(() =>
                            paymentService.cancelPayment(paymentId)
                        );

                        // Apply optimistic update for cancellation
                        setOptimisticUpdates(prev => ({
                            ...prev,
                            [pedidoId]: {
                                statusPagamento: 'pendente',
                                asaas: {
                                    ...pedido.asaas,
                                    paymentStatus: 'CANCELLED'
                                }
                            }
                        }));

                        await carregarPedidos(true);
                    }
                    break;

                case 'restore':
                    const restorePaymentId = pedido.id || pedido.asaas?.paymentId;
                    if (restorePaymentId) {
                        const restoredPayment = await retryOperation(() =>
                            paymentService.restorePayment(restorePaymentId)
                        );

                        // Apply optimistic update for restoration
                        setOptimisticUpdates(prev => ({
                            ...prev,
                            [pedidoId]: {
                                statusPagamento: 'pendente',
                                asaas: {
                                    ...pedido.asaas,
                                    paymentStatus: restoredPayment.status
                                }
                            }
                        }));

                        await carregarPedidos(true);
                    }
                    break;
            }

            setAlerta({
                mensagem: "Operação de pagamento realizada com sucesso",
                tipo: "success",
                aberto: true,
            });
        } catch (error) {
            const errorMessage = error.response?.data?.message || error.message;
            setAlerta({
                mensagem: `Erro na operação de pagamento: ${errorMessage}`,
                tipo: "error",
                aberto: true,
            });
        } finally {
            setIsLoadingPayment(false);
        }
    }, [carregarPedidos, setAlerta, handlePaymentStatusChange]);

    // Apply optimistic updates to pedidos list
    const getOptimisticState = useCallback((pedidos) => {
        return pedidos.map(pedido => {
            const update = optimisticUpdates[pedido._id];
            if (update) {
                return {
                    ...pedido,
                    ...update
                };
            }
            return pedido;
        });
    }, [optimisticUpdates]);

    return {
        paymentDetails,
        showPaymentDetails,
        isLoadingPayment,
        handlePaymentAction,
        handlePaymentStatusChange,
        setShowPaymentDetails,
        getOptimisticState
    };
};
