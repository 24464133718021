import React, { useState } from 'react';
import { Box, IconButton, useTheme, useMediaQuery, Drawer } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import { Outlet } from 'react-router-dom';
import UserSidebar from './UserSidebar';

const UserLayout = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return (
        <Box sx={{
            minHeight: "100vh",
            bgcolor: theme.palette.background.default,
            display: 'flex',
            position: 'relative',
            color: theme.palette.text.primary,
            '&::before': {
                content: '""',
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: theme.palette.mode === 'dark'
                    ? `radial-gradient(circle at top right, ${theme.palette.primary.dark}15, transparent 60%)`
                    : `radial-gradient(circle at top right, ${theme.palette.primary.light}15, transparent 60%)`,
                pointerEvents: 'none'
            }
        }}>
            {/* Mobile menu button */}
            {isMobile && (
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{
                        position: 'fixed',
                        left: 16,
                        top: 16,
                        zIndex: 1200,
                        bgcolor: theme.palette.background.paper,
                        boxShadow: theme.shadows[2],
                        '&:hover': {
                            bgcolor: theme.palette.background.paper,
                        }
                    }}
                >
                    <MenuIcon />
                </IconButton>
            )}

            {/* Sidebar for mobile */}
            {isMobile ? (
                <Drawer
                    variant="temporary"
                    anchor="left"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile
                    }}
                    sx={{
                        '& .MuiDrawer-paper': {
                            width: 280,
                            border: 'none',
                            bgcolor: 'transparent'
                        },
                    }}
                >
                    <UserSidebar onMobileClose={handleDrawerToggle} />
                </Drawer>
            ) : (
                // Sidebar for desktop
                <UserSidebar />
            )}

            {/* Main content */}
            <Box sx={{
                flexGrow: 1,
                ml: { xs: 0, sm: '280px' },
                minHeight: '100vh',
                bgcolor: theme.palette.background.default,
                borderLeft: { xs: 'none', sm: `1px solid ${theme.palette.divider}` },
                position: 'relative',
                zIndex: 1,
                pt: { xs: '64px', sm: 0 } // Add padding top for mobile to account for menu button
            }}>
                <Outlet />
            </Box>
        </Box>
    );
};

export default UserLayout;
