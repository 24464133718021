import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  CircularProgress,
  Container,
  useTheme,
} from "@mui/material";
import api from "../api";
import { getToken, getUserId } from "../utils/auth";
import UserProfile from "../components/user/UserProfile";

const UserPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const [cliente, setCliente] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const token = getToken();
    const userId = getUserId();

    if (!token || !userId || String(userId) !== String(id)) {
      console.log("Auth check failed:", { hasToken: !!token, userId, paramId: id });
      navigate("/");
      return;
    }

    const fetchData = async () => {
      try {
        setLoading(true);
        setError(null);

        // Fetch cliente data
        const clienteResponse = await api.get(`/clientes/${userId}`);
        setCliente(clienteResponse.data);
      } catch (error) {
        console.error("Erro ao carregar dados:", error);
        setError("Erro ao carregar dados. Por favor, tente novamente.");
        if (error.response?.status === 401) {
          navigate("/");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id, navigate]);

  if (loading) {
    return (
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        bgcolor: theme.palette.background.default
      }}>
        <CircularProgress sx={{ color: theme.palette.primary.main }} />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        bgcolor: theme.palette.background.default
      }}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  if (!cliente) {
    return null;
  }

  return (
    <Box sx={{
      p: 4,
      bgcolor: theme.palette.background.default,
      minHeight: '100vh'
    }}>
      <Container maxWidth="lg">
        <Box sx={{
          bgcolor: theme.palette.background.paper,
          borderRadius: 2,
          boxShadow: theme.shadows[1]
        }}>
          <UserProfile cliente={cliente} />
        </Box>
      </Container>
    </Box>
  );
};

export default UserPage;
