import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  Stack,
} from "@mui/material";
import {
  ArrowBack as ArrowBackIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import api from "../api";
import ClientDetails from "../components/ClientDetails";
import ListaPedidos from "../components/ListaPedidos";

const DadosCliente = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [cliente, setCliente] = useState(null);
  const listaPedidosRef = useRef();

  useEffect(() => {
    const fetchCliente = async () => {
      try {
        const response = await api.get(`/clientes/${id}`);
        setCliente(response.data);
      } catch (error) {
        console.error("Erro ao carregar dados do cliente:", error.message);
      }
    };

    fetchCliente();
  }, [id]);

  if (!cliente) {
    return <Typography>Carregando...</Typography>;
  }

  return (
    <Box sx={{ padding: "25px" }}>
      {/* Botões de ação */}
      <Stack direction="row" spacing={2} sx={{ marginBottom: "20px" }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate("/cadastro-clientes")}
        >
          Voltar
        </Button>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<EditIcon />}
          onClick={() => navigate(`/editar-cliente/${id}`)}
        >
          Editar Cliente
        </Button>
      </Stack>

      <Typography variant="h4" color="primary" gutterBottom>
        Dados do Cliente
      </Typography>

      <ClientDetails cliente={cliente} />

      {/* Seção de Pedidos do Cliente */}
      <Box sx={{ marginTop: "30px" }}>
        <Typography variant="h5" color="primary" gutterBottom>
          Pedidos do Cliente
        </Typography>
        <ListaPedidos
          ref={listaPedidosRef}
          initialFilters={{ clienteId: id }} // Pré-configura o filtro para mostrar apenas pedidos deste cliente
        />
      </Box>
    </Box>
  );
};

export default DadosCliente;
