import api from '../api';

class PaymentService {
    async createCustomer(customerData) {
        try {
            const response = await api.post('/pagamentos/customers', customerData);
            return response.data;
        } catch (error) {
            console.error('Error creating customer:', error);
            throw new Error(`Erro ao criar cliente no Asaas: ${error.message}`);
        }
    }

    async getCustomer(customerId) {
        try {
            const response = await api.get(`/pagamentos/customers/${customerId}`);
            return response.data;
        } catch (error) {
            console.error('Error getting customer:', error);
            throw new Error(`Erro ao obter detalhes do cliente: ${error.message}`);
        }
    }

    async createPayment(paymentData) {
        try {
            const response = await api.post('/pagamentos/payments', paymentData);
            return response.data;
        } catch (error) {
            console.error('Error creating payment:', error);
            throw new Error(`Erro ao criar cobrança: ${error.message}`);
        }
    }

    async createPixPayment(paymentData) {
        try {
            const response = await api.post('/pagamentos/payments/pix', paymentData);
            return response.data;
        } catch (error) {
            console.error('Error creating PIX payment:', error);
            throw new Error(`Erro ao criar cobrança PIX: ${error.message}`);
        }
    }

    async getPayment(paymentId) {
        try {
            const response = await api.get(`/pagamentos/payments/${paymentId}`);
            return response.data;
        } catch (error) {
            console.error('Error getting payment:', error);
            throw new Error(`Erro ao consultar cobrança: ${error.message}`);
        }
    }

    async updatePaymentStatuses(pedidoIds) {
        try {
            const response = await api.post('/pedidos/update-payment-statuses', { pedidoIds });
            return response.data;
        } catch (error) {
            console.error('Error updating payment statuses:', error);
            throw new Error(`Erro ao atualizar status dos pagamentos: ${error.message}`);
        }
    }

    async listPayments(filters = {}) {
        try {
            const cleanFilters = Object.entries({
                ...filters,
                page: filters.page || 1,
                limit: filters.limit || 10
            }).reduce((acc, [key, value]) => {
                if (value !== '' && value !== null && value !== undefined) {
                    acc[key] = value;
                }
                return acc;
            }, {});

            const response = await api.get('/pagamentos/payments', {
                params: cleanFilters
            });

            return response.data;
        } catch (error) {
            console.error('Error in listPayments:', error);
            throw new Error(`Erro ao listar cobranças: ${error.message}`);
        }
    }

    // Métodos separados para cada tipo de pagamento do usuário
    async getUserSubscriptionPayments(page = 1, limit = 10) {
        try {
            const response = await api.get('/pagamentos/user/subscription-payments', {
                params: { page, limit }
            });
            return response.data;
        } catch (error) {
            console.error('Error getting user subscription payments:', error);
            throw new Error(`Erro ao obter pagamentos de assinatura: ${error.message}`);
        }
    }

    async getUserOrderPayments(page = 1, limit = 10) {
        try {
            const response = await api.get('/pagamentos/user/order-payments', {
                params: { page, limit }
            });
            return response.data;
        } catch (error) {
            console.error('Error getting user order payments:', error);
            throw new Error(`Erro ao obter pagamentos de pedidos: ${error.message}`);
        }
    }

    async getUserActiveSubscription() {
        try {
            const response = await api.get('/pagamentos/user/active-subscription');
            return response.data;
        } catch (error) {
            console.error('Error getting user active subscription:', error);
            throw new Error(`Erro ao obter assinatura ativa: ${error.message}`);
        }
    }

    // Método legado mantido para compatibilidade
    async getUserPayments() {
        try {
            const response = await api.get('/pagamentos/user/payments');
            return response.data;
        } catch (error) {
            console.error('Error getting user payments:', error);
            throw new Error(`Erro ao obter pagamentos do usuário: ${error.message}`);
        }
    }

    async getSubscription(subscriptionId) {
        try {
            const response = await api.get(`/pagamentos/subscriptions/${subscriptionId}`);
            return response.data;
        } catch (error) {
            console.error('Error getting subscription:', error);
            throw new Error(`Erro ao obter detalhes da assinatura: ${error.message}`);
        }
    }

    async listSubscriptions(filters = {}) {
        try {
            const response = await api.get('/pagamentos/subscriptions', {
                params: filters
            });
            return response.data;
        } catch (error) {
            console.error('Error listing subscriptions:', error);
            throw new Error(`Erro ao listar assinaturas: ${error.message}`);
        }
    }

    async cancelPayment(paymentId) {
        try {
            const response = await api.delete(`/pagamentos/payments/${paymentId}`);
            return response.data;
        } catch (error) {
            console.error('Error canceling payment:', error);
            throw new Error(`Erro ao cancelar cobrança: ${error.message}`);
        }
    }

    async restorePayment(paymentId) {
        try {
            const response = await api.post(`/pagamentos/payments/${paymentId}/restore`);
            return response.data;
        } catch (error) {
            console.error('Error restoring payment:', error);
            throw new Error(`Erro ao restaurar cobrança: ${error.message}`);
        }
    }

    formatPaymentStatus(status) {
        const statusMap = {
            PENDING: 'Aguardando pagamento',
            RECEIVED: 'Pagamento Recebido',
            CONFIRMED: 'Pagamento Confirmado',
            OVERDUE: 'Pagamento Vencido',
            REFUNDED: 'Pagamento Estornado',
            RECEIVED_IN_CASH: 'Pago em Dinheiro',
            REFUND_REQUESTED: 'Estorno Solicitado',
            CHARGEBACK_REQUESTED: 'Chargeback Solicitado',
            CHARGEBACK_DISPUTE: 'Em Disputa de Chargeback',
            AWAITING_CHARGEBACK_REVERSAL: 'Aguardando Reversão de Chargeback',
            DUNNING_REQUESTED: 'Em Processo de Dunning',
            DUNNING_RECEIVED: 'Recebido por Dunning',
            AWAITING_RISK_ANALYSIS: 'Em Análise de Risco',
            CANCELLED: 'Cancelado'
        };
        return statusMap[status] || status;
    }

    formatBillingType(type) {
        const typeMap = {
            BOLETO: 'Boleto',
            CREDIT_CARD: 'Cartão de Crédito',
            PIX: 'PIX',
            UNDEFINED: 'Não definido'
        };
        return typeMap[type] || type;
    }
}

export default new PaymentService();
