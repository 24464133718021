import React from "react";
import { Button, Typography, Checkbox, Tooltip, Box } from "@mui/material";
import { Link } from "react-router-dom";
import {
    Warning as WarningIcon,
    WhatsApp as WhatsAppIcon,
    DriveFileMove as DriveFileIcon,
} from "@mui/icons-material";
import StatusDisplay from "./StatusDisplay";
import PaymentActions from "./PaymentActions";
import PaymentStatus from "./PaymentStatus";

export const createCellRenderers = ({
    readOnly,
    handleStatusChange,
    handleDetalhes,
    handleRetiradaChange,
    handlePaymentAction,
    dados, // Add dados prop for context-aware rendering
}) => ({
    ID: (linha) => linha.ID ? linha.ID.toString().slice(-4).toUpperCase() : "-",
    Status: (linha) => (
        <StatusDisplay
            status={linha.Status}
            id={linha.ID}
            readOnly={readOnly}
            handleStatusChange={handleStatusChange}
            allData={dados} // Pass full dataset for context
        />
    ),
    Cliente: (linha) => linha.Cliente || '-',
    Origem: (linha) => {
        // Se for um pedido, extrair o ID e criar um link
        if (linha._raw?.description?.toLowerCase().includes('pedido')) {
            const parts = linha._raw.description.split(' ');
            if (parts.length > 1 && parts[0].toLowerCase() === 'pedido') {
                const orderId = parts[1];
                return (
                    <Box component={Link} to={`/orders/${orderId}`} color="primary.main">
                        Pedido
                    </Box>
                );
            }
        }
        return linha.Origem || '-';
    },
    'Data Cadastro': (linha) => linha.DataCadastro || '-',
    DataCadastro: (linha) => linha.DataCadastro || '-',
    'Data Vencimento': (linha) => linha.DataVencimento || '-',
    DataVencimento: (linha) => linha.DataVencimento || '-',
    'Data Entrega': (linha) => linha.DataEntrega || '-',
    DataEntrega: (linha) => linha.DataEntrega || '-',
    Retirada: (linha) => (
        readOnly ? (
            <Typography variant="body2">{linha.Retirada ? "Sim" : "Não"}</Typography>
        ) : (
            <Tooltip
                title={linha.DataEntrega ? new Date(linha.DataEntrega.split('/').reverse().join('-')).toLocaleDateString('pt-BR') : ''}
                placement="top"
            >
                <Checkbox
                    checked={linha.Retirada}
                    onChange={(e) => handleRetiradaChange(linha.ID, e.target.checked)}
                />
            </Tooltip>
        )
    ),
    Detalhes: (linha) => (
        <Button
            variant="text"
            color="primary"
            onClick={() => handleDetalhes(linha.ID)}
            sx={{ textTransform: 'none' }}
        >
            {linha.Detalhes || 'Detalhes'}
        </Button>
    ),
    Total: (linha) => linha.Total || 'R$ 0,00',
    'Valor a Pagar': (linha) => linha['Valor a Pagar'] || 'R$ 0,00',
    Pagamento: (linha) => (
        <PaymentStatus
            pagamento={linha.Pagamento}
            allData={dados} // Pass full dataset for context
            currentRow={linha} // Pass current row for context
        />
    ),
    'Ações de Pagamento': (linha) => (
        <PaymentActions
            linha={linha}
            readOnly={readOnly}
            handlePaymentAction={handlePaymentAction}
            allData={dados} // Pass full dataset for context
        />
    ),
    WhatsApp: (linha) => linha.Telefone && (
        <Button
            startIcon={<WhatsAppIcon />}
            onClick={() => window.open(`https://wa.me/+55${linha.Telefone}`)}
        >
            WhatsApp
        </Button>
    ),
    Drive: (linha) => linha.DriveLink && (
        <Button
            startIcon={<DriveFileIcon />}
            onClick={() => window.open(linha.DriveLink)}
        >
            Drive
        </Button>
    ),
    Urgente: (linha) => linha.boolean && (
        <WarningIcon color="warning" titleAccess="Urgente" />
    ),
    ZoomPlus: (linha) => linha.ZoomPlus || 'Não',
});
