import React from "react";
import PropTypes from "prop-types";
import { Typography, Grid, Box, Tooltip, Alert } from "@mui/material";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

const BenefitStatus = ({ active, label }) => (
    <Tooltip title={active ? "Disponível" : "Indisponível"}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {active ? (
                <CheckCircleIcon color="success" sx={{ fontSize: '0.9rem', mr: 0.5 }} />
            ) : (
                <CancelIcon color="error" sx={{ fontSize: '0.9rem', mr: 0.5 }} />
            )}
            <Typography variant="caption">{label}</Typography>
        </Box>
    </Tooltip>
);

BenefitStatus.propTypes = {
    active: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
};

const ZoomPlusCard = ({ clienteZoomPlus, totais }) => {
    // Validate required client data
    if (!clienteZoomPlus) {
        return (
            <Alert severity="error">
                Dados do cliente ZOOM+ não disponíveis
            </Alert>
        );
    }

    // Ensure all required fields have fallback values
    const saldoAtual = clienteZoomPlus?.saldo ?? 0;
    const saldoProjetado = totais?.saldoRestante ?? saldoAtual;
    const saldoUtilizado = totais?.saldoUtilizado ?? 0;


    // Ensure benefit fields have fallback values
    const taxasUrgenciaDisponiveis = clienteZoomPlus?.taxasUrgenciaDisponiveis ?? 0;
    const usoModelosDisponiveis = clienteZoomPlus?.usoModelosDisponiveis ?? 0;

    // Get benefits status with fallback values
    const taxaUrgenciaDisponivel = taxasUrgenciaDisponiveis > 0;
    const modeloCompletaDisponivel = usoModelosDisponiveis > 0;

    return (
        <Box sx={{ p: 1 }}>
            <Grid container spacing={1}>
                {/* Saldo Section */}
                <Grid item xs={12} sm={6}>
                    <Box>
                        <Typography variant="caption" color="text.secondary" display="block">
                            Saldo Atual
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                            <MonetizationOnIcon sx={{ fontSize: '0.9rem', color: 'secondary.main' }} />
                            <Typography variant="body2">
                                R$ {saldoAtual.toFixed(2)}
                            </Typography>
                        </Box>
                        <Typography variant="caption" color="text.secondary" display="block" sx={{ mt: 0.5 }}>
                            Após Pedido:
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                            <MonetizationOnIcon sx={{ fontSize: '0.9rem', color: saldoProjetado < saldoAtual ? 'error.main' : 'success.main' }} />
                            <Typography variant="body2" color={saldoProjetado < saldoAtual ? 'error.main' : 'success.main'}>
                                R$ {saldoProjetado.toFixed(2)}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>

                {/* Benefits Section */}
                <Grid item xs={12} sm={6}>
                    <Typography variant="caption" color="text.secondary" display="block" gutterBottom>
                        Benefícios Disponíveis
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <BenefitStatus
                                active={taxaUrgenciaDisponivel}
                                label={`Taxa de Urgência: ${taxasUrgenciaDisponiveis}`}
                            />
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <BenefitStatus
                                active={modeloCompletaDisponivel}
                                label={`Modelo Completo: ${usoModelosDisponiveis}`}
                            />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

ZoomPlusCard.propTypes = {
    clienteZoomPlus: PropTypes.shape({
        saldo: PropTypes.number,
        taxasUrgenciaDisponiveis: PropTypes.number,
        usoModelosDisponiveis: PropTypes.number,
    }),
    totais: PropTypes.shape({
        saldoRestante: PropTypes.number,
        saldoUtilizado: PropTypes.number,
    }),
};

ZoomPlusCard.defaultProps = {
    clienteZoomPlus: {
        saldo: 0,
        taxasUrgenciaDisponiveis: 0,
        usoModelosDisponiveis: 0,
    },
    totais: {
        saldoRestante: 0,
        saldoUtilizado: 0,
    },
};

export default ZoomPlusCard;
