import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
    Box,
    Typography,
    Paper,
    Grid,
    Button,
    Divider,
    List,
    ListItem,
    ListItemText,
    Chip,
} from "@mui/material";
import {
    ArrowBack as ArrowBackIcon,
    AccessTime as AccessTimeIcon,
    AttachMoney as AttachMoneyIcon,
    LocalShipping as LocalShippingIcon,
    Payment as PaymentIcon,
    Description as DescriptionIcon,
    Person as PersonIcon,
    Warning as WarningIcon,
    CheckCircle as CheckCircleIcon,
    Schedule as ScheduleIcon,
    Star as StarIcon,
} from "@mui/icons-material";
import api from "../api";

const VisualizarPedido = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [pedido, setPedido] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const carregarPedido = async () => {
            try {
                setLoading(true);
                const response = await api.get(`/pedidos/${id}`);
                setPedido(response.data);
            } catch (error) {
                console.error("Erro ao carregar pedido:", error);
                setError("Erro ao carregar o pedido. Por favor, tente novamente.");
            } finally {
                setLoading(false);
            }
        };

        carregarPedido();
    }, [id]);

    if (loading) {
        return <Typography>Carregando...</Typography>;
    }

    if (error) {
        return <Typography color="error">{error}</Typography>;
    }

    if (!pedido) {
        return <Typography>Pedido não encontrado</Typography>;
    }

    const formatarValor = (valor) => `R$ ${valor?.toFixed(2) || "0.00"}`;

    const getStatusPagamentoLabel = (statusPagamento) => {
        const statusMap = {
            "pago_com_saldo": "Pago com Saldo",
            "pago_parcial_saldo": "Pago Parcialmente com Saldo",
            "pago": "Pago",
            "pendente": "Pendente"
        };
        return statusMap[statusPagamento] || "Pendente";
    };

    const calcularDataLimite = (dataEntrega) => {
        const data = new Date(dataEntrega);
        data.setDate(data.getDate() + 30);
        return data.toLocaleDateString("pt-BR");
    };

    const getStatusChip = (status) => {
        const statusConfig = {
            aberto: { color: "warning", icon: <ScheduleIcon /> },
            "em andamento": { color: "info", icon: <AccessTimeIcon /> },
            concluido: { color: "success", icon: <CheckCircleIcon /> },
            cancelado: { color: "error", icon: <WarningIcon /> }
        };

        const config = statusConfig[status.toLowerCase()] || statusConfig.aberto;

        return (
            <Chip
                icon={config.icon}
                label={status.charAt(0).toUpperCase() + status.slice(1)}
                color={config.color}
                sx={{ fontWeight: 'bold' }}
            />
        );
    };

    const formatarDadosCliente = (cliente) => {
        if (!cliente) return 'N/A';
        const { nome, email } = cliente;
        return `${nome}${email ? ` - ${email}` : ''}`;
    };

    return (
        <Box sx={{ padding: "20px", backgroundColor: "#f5f5f5", minHeight: "100vh" }}>
            {/* Cabeçalho */}
            <Paper sx={{ p: 2, mb: 3, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Button
                        variant="contained"
                        startIcon={<ArrowBackIcon />}
                        onClick={() => navigate(-1)}
                        sx={{ mr: 2 }}
                    >
                        Voltar
                    </Button>
                    <Typography variant="h4" color="primary" sx={{ fontWeight: 'bold' }}>
                        Pedido #{id.slice(-4).toUpperCase()}
                    </Typography>
                </Box>
                {getStatusChip(pedido.status)}
            </Paper>

            {/* Informações do Pedido */}
            <Grid container spacing={3}>
                {/* Status e Datas */}
                <Grid item xs={12} md={6}>
                    <Paper sx={{ p: 3, height: '100%' }}>
                        <Typography variant="h6" gutterBottom sx={{ color: 'primary.main', display: 'flex', alignItems: 'center' }}>
                            <AccessTimeIcon sx={{ mr: 1 }} />
                            Status e Datas
                        </Typography>
                        <Divider sx={{ my: 2 }} />
                        <List>
                            <ListItem>
                                <ListItemText
                                    primary={<Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Data de Cadastro</Typography>}
                                    secondary={new Date(pedido.createdAt).toLocaleDateString("pt-BR")}
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary={<Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Data de Entrega</Typography>}
                                    secondary={new Date(pedido.dataEntrega).toLocaleDateString("pt-BR")}
                                />
                            </ListItem>
                            {pedido.dataLimiteRetirada && (
                                <ListItem>
                                    <ListItemText
                                        primary={
                                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: 'warning.main', display: 'flex', alignItems: 'center' }}>
                                                <WarningIcon sx={{ mr: 1, fontSize: '1rem' }} />
                                                Data Limite para Retirada
                                            </Typography>
                                        }
                                        secondary={new Date(pedido.dataLimiteRetirada).toLocaleDateString("pt-BR")}
                                    />
                                </ListItem>
                            )}
                        </List>
                    </Paper>
                </Grid>

                {/* Valores */}
                <Grid item xs={12} md={6}>
                    <Paper sx={{ p: 3, height: '100%' }}>
                        <Typography variant="h6" gutterBottom sx={{ color: 'primary.main', display: 'flex', alignItems: 'center' }}>
                            <AttachMoneyIcon sx={{ mr: 1 }} />
                            Valores
                        </Typography>
                        <Divider sx={{ my: 2 }} />
                        <List>
                            <ListItem>
                                <ListItemText
                                    primary={<Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Valor Total</Typography>}
                                    secondary={formatarValor(pedido.total)}
                                />
                            </ListItem>
                            {pedido.valorUrgencia > 0 && (
                                <ListItem>
                                    <ListItemText
                                        primary={<Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Taxa de Urgência</Typography>}
                                        secondary={formatarValor(pedido.valorUrgencia)}
                                    />
                                </ListItem>
                            )}
                            <ListItem>
                                <ListItemText
                                    primary={<Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Valor a Pagar</Typography>}
                                    secondary={formatarValor(pedido.valorPagar)}
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary={<Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Status do Pagamento</Typography>}
                                    secondary={getStatusPagamentoLabel(pedido.statusPagamento)}
                                />
                            </ListItem>
                        </List>
                    </Paper>
                </Grid>

                {/* Serviços */}
                <Grid item xs={12}>
                    <Paper sx={{ p: 3 }}>
                        <Typography variant="h6" gutterBottom sx={{ color: 'primary.main', display: 'flex', alignItems: 'center' }}>
                            <DescriptionIcon sx={{ mr: 1 }} />
                            Serviços Solicitados
                        </Typography>
                        <Divider sx={{ my: 2 }} />
                        <List>
                            {pedido.servicos.map((servico, index) => (
                                <ListItem key={index}>
                                    <ListItemText
                                        primary={<Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>{servico.servicoId.nome}</Typography>}
                                        secondary={`Quantidade: ${servico.quantidade} | Subtotal: ${formatarValor(servico.subtotal)}`}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </Paper>
                </Grid>

                {/* ZoomPlus */}
                {pedido.isZoomPlus && (
                    <Grid item xs={12}>
                        <Paper sx={{ p: 3 }}>
                            <Typography variant="h6" gutterBottom sx={{ color: 'primary.main', display: 'flex', alignItems: 'center' }}>
                                <StarIcon sx={{ mr: 1 }} />
                                Informações ZoomPlus
                            </Typography>
                            <Divider sx={{ my: 2 }} />
                            <List>
                                <ListItem>
                                    <ListItemText
                                        primary={<Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Saldo Utilizado</Typography>}
                                        secondary={formatarValor(pedido.zoomPlus.saldoUtilizado)}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemText
                                        primary={<Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Saldo Restante</Typography>}
                                        secondary={formatarValor(pedido.zoomPlus.saldoRestante)}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemText
                                        primary={<Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Desconto Aplicado</Typography>}
                                        secondary={formatarValor(pedido.zoomPlus.descontoAplicado)}
                                    />
                                </ListItem>
                                {pedido.zoomPlus.beneficiosUtilizados && (
                                    <ListItem>
                                        <ListItemText
                                            primary={<Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Benefícios Utilizados</Typography>}
                                            secondary={
                                                <Box>
                                                    {pedido.zoomPlus.beneficiosUtilizados.taxaUrgenciaGratis &&
                                                        <Typography>• Taxa de Urgência Grátis</Typography>}
                                                    {pedido.zoomPlus.beneficiosUtilizados.modeloCompletaGratis &&
                                                        <Typography>• Modelo Completa Grátis</Typography>}
                                                </Box>
                                            }
                                        />
                                    </ListItem>
                                )}
                            </List>
                        </Paper>
                    </Grid>
                )}

                {/* Informações de Pagamento Asaas */}
                {pedido.asaas && pedido.asaas.paymentId && (
                    <Grid item xs={12}>
                        <Paper sx={{ p: 3 }}>
                            <Typography variant="h6" gutterBottom sx={{ color: 'primary.main', display: 'flex', alignItems: 'center' }}>
                                <PaymentIcon sx={{ mr: 1 }} />
                                Informações de Pagamento
                            </Typography>
                            <Divider sx={{ my: 2 }} />
                            <List>
                                <ListItem>
                                    <ListItemText
                                        primary={<Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Status do Pagamento Asaas</Typography>}
                                        secondary={pedido.asaas.paymentStatus}
                                    />
                                </ListItem>
                                {pedido.asaas.billingType && (
                                    <ListItem>
                                        <ListItemText
                                            primary={<Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Forma de Pagamento</Typography>}
                                            secondary={pedido.asaas.billingType}
                                        />
                                    </ListItem>
                                )}
                                {pedido.asaas.dueDate && (
                                    <ListItem>
                                        <ListItemText
                                            primary={<Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Data de Vencimento</Typography>}
                                            secondary={new Date(pedido.asaas.dueDate).toLocaleDateString("pt-BR")}
                                        />
                                    </ListItem>
                                )}
                                {pedido.asaas.paymentLink && (
                                    <ListItem>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            href={pedido.asaas.paymentLink}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Acessar Link de Pagamento
                                        </Button>
                                    </ListItem>
                                )}
                            </List>
                        </Paper>
                    </Grid>
                )}

                {/* Informações Adicionais */}
                <Grid item xs={12}>
                    <Paper sx={{ p: 3 }}>
                        <Typography variant="h6" gutterBottom sx={{ color: 'primary.main', display: 'flex', alignItems: 'center' }}>
                            <LocalShippingIcon sx={{ mr: 1 }} />
                            Informações Adicionais
                        </Typography>
                        <Divider sx={{ my: 2 }} />
                        <List>
                            <ListItem>
                                <ListItemText
                                    primary={
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <PersonIcon sx={{ mr: 1, color: 'primary.main' }} />
                                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Cliente</Typography>
                                        </Box>
                                    }
                                    secondary={formatarDadosCliente(pedido.clienteId)}
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary={
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <LocalShippingIcon sx={{ mr: 1, color: 'primary.main' }} />
                                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Retirada</Typography>
                                        </Box>
                                    }
                                    secondary={pedido.retirada ? "Sim" : "Não"}
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary={
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <WarningIcon sx={{ mr: 1, color: pedido.isUrgent ? 'error.main' : 'primary.main' }} />
                                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Urgente</Typography>
                                        </Box>
                                    }
                                    secondary={pedido.isUrgent ? "Sim" : "Não"}
                                />
                            </ListItem>
                            {pedido.mensagemResumo && (
                                <ListItem>
                                    <ListItemText
                                        primary={
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <DescriptionIcon sx={{ mr: 1, color: 'primary.main' }} />
                                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Mensagem</Typography>
                                            </Box>
                                        }
                                        secondary={pedido.mensagemResumo}
                                    />
                                </ListItem>
                            )}
                            {pedido.linkDrive && (
                                <ListItem>
                                    <ListItemText
                                        primary={
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <DescriptionIcon sx={{ mr: 1, color: 'primary.main' }} />
                                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Link do Drive</Typography>
                                            </Box>
                                        }
                                        secondary={
                                            <Button
                                                href={pedido.linkDrive}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                variant="text"
                                                color="primary"
                                            >
                                                Acessar Arquivos
                                            </Button>
                                        }
                                    />
                                </ListItem>
                            )}
                        </List>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
};

export default VisualizarPedido;
