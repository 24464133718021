import React from "react";
import PropTypes from "prop-types";
import {
  Autocomplete,
  TextField,
  Typography,
  Box,
  Paper,
  Chip,
  Grid,
  useTheme,
  Alert,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import StarIcon from "@mui/icons-material/Star";
import ZoomPlusCard from "./ZoomPlusCard";

export const ClienteSelect = ({ clientes, handleClienteChange, clienteZoomPlus, totais, disabled }) => {
  const theme = useTheme();

  // Ensure clientes is always an array
  const clientesList = Array.isArray(clientes) ? clientes : [];

  // Error handling for invalid client data
  const hasInvalidClientData = clienteZoomPlus?.isZoomPlus &&
    (typeof clienteZoomPlus.taxasUrgenciaDisponiveis === 'undefined' ||
      typeof clienteZoomPlus.usoModelosDisponiveis === 'undefined');

  return (
    <Box>
      {/* Cabeçalho */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: theme.palette.background.elevated,
          borderRadius: "8px",
          padding: "16px",
          mb: 3,
          boxShadow: theme.shadows[1],
        }}
      >
        <Box display="flex" alignItems="center">
          <AccountCircleIcon sx={{ fontSize: 20, color: "primary.main", mr: 2 }} />
          <Typography variant="h6" sx={{ fontWeight: "bold", color: theme.palette.text.primary }}>
            Informações do Cliente
          </Typography>
        </Box>
      </Box>

      {/* Área de seleção de cliente */}
      <Box sx={{ mb: 3, backgroundColor: theme.palette.background.elevated, p: 2, borderRadius: '8px' }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              options={clientesList}
              getOptionLabel={(option) => option?.nome || "Cliente inválido"}
              onChange={(event, value) => handleClienteChange(value?._id)}
              disabled={disabled}
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <AccountCircleIcon sx={{ color: theme.palette.text.secondary, mr: 2 }} />
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography variant="body1" sx={{ fontWeight: 500, color: theme.palette.text.primary }}>
                        {option.nome}
                      </Typography>
                      {option.email && (
                        <Typography variant="body2" color="text.secondary">
                          {option.email}
                        </Typography>
                      )}
                    </Box>
                    {option.isZoomPlus && (
                      <Chip
                        icon={<StarIcon />}
                        label="ZOOM+"
                        size="small"
                        sx={{
                          ml: 1,
                          backgroundColor: theme.palette.primary.main,
                          color: '#fff',
                          fontWeight: 'bold',
                          '& .MuiSvgIcon-root': {
                            color: '#fff',
                          },
                        }}
                      />
                    )}
                  </Box>
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Selecionar Cliente"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <>
                        <AccountCircleIcon sx={{ color: theme.palette.text.secondary, mr: 1 }} />
                        {params.InputProps.startAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>

      {/* Informações do cliente Zoom+ */}
      {clienteZoomPlus?.isZoomPlus && (
        <Paper
          elevation={0}
          sx={{
            mt: 2,
            backgroundColor: theme.palette.background.paper,
            borderRadius: '8px',
            overflow: 'hidden',
            border: `1px solid ${theme.palette.divider}`,
          }}
        >
          <Box
            sx={{
              p: 1,
              backgroundColor: theme.palette.primary.main,
              color: '#fff',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <StarIcon sx={{ mr: 1 }} />
            <Typography variant="h12" sx={{ fontWeight: 'bold' }}>
              Benefícios ZOOM+
            </Typography>
          </Box>
          <Box sx={{ p: 2 }}>
            {hasInvalidClientData ? (
              <Alert severity="warning">
                Alguns benefícios ZOOM+ podem estar indisponíveis. Por favor, atualize os dados do cliente.
              </Alert>
            ) : (
              <ZoomPlusCard
                clienteZoomPlus={clienteZoomPlus}
                totais={totais}
              />
            )}
          </Box>
        </Paper>
      )}
    </Box>
  );
};

ClienteSelect.propTypes = {
  clientes: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string.isRequired,
    nome: PropTypes.string.isRequired,
    email: PropTypes.string,
    isZoomPlus: PropTypes.bool,
  })),
  handleClienteChange: PropTypes.func.isRequired,
  clienteZoomPlus: PropTypes.shape({
    isZoomPlus: PropTypes.bool,
    taxasUrgenciaDisponiveis: PropTypes.number,
    usoModelosDisponiveis: PropTypes.number,
  }),
  totais: PropTypes.object,
  disabled: PropTypes.bool,
};

ClienteSelect.defaultProps = {
  clientes: [],
  clienteZoomPlus: null,
  totais: {},
  disabled: false,
};

export default ClienteSelect;
