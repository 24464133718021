import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import {
    Container,
    Paper,
    Typography,
    TextField,
    Button,
    Box,
    CircularProgress,
    Alert,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import TodoItem from '../components/TodoItem';
import AlertaReutilizavel from '../components/AlertaReutilizavel';
import api from '../api';

// Non-memo wrapper component for Droppable
const DroppableWrapper = ({ children, droppableId }) => (
    <Droppable droppableId={droppableId}>
        {(provided) => (
            <div
                {...provided.droppableProps}
                ref={provided.innerRef}
            >
                {children(provided)}
            </div>
        )}
    </Droppable>
);

const TodoList = () => {
    const theme = useTheme();
    const [todos, setTodos] = useState([]);
    const [newTodoTitle, setNewTodoTitle] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [alerta, setAlerta] = useState({
        aberto: false,
        mensagem: '',
        tipo: 'success'
    });

    // Fetch todos on component mount
    useEffect(() => {
        fetchTodos();
    }, []);

    const fetchTodos = async () => {
        try {
            setLoading(true);
            const response = await api.get('/todos');
            setTodos(response.data);
            setError(null);
        } catch (err) {
            console.error('Error fetching todos:', err);
            setError('Failed to load todos. Please try again later.');
            setAlerta({
                aberto: true,
                mensagem: 'Failed to load todos: ' + (err.response?.data?.message || err.message),
                tipo: 'error'
            });
        } finally {
            setLoading(false);
        }
    };

    const handleAddTodo = async (e) => {
        e.preventDefault();
        if (!newTodoTitle.trim()) return;

        try {
            const response = await api.post('/todos', { title: newTodoTitle.trim() });
            setTodos([...todos, response.data]);
            setNewTodoTitle('');
            setError(null);
            setAlerta({
                aberto: true,
                mensagem: 'Todo added successfully!',
                tipo: 'success'
            });
        } catch (err) {
            console.error('Error adding todo:', err);
            setError('Failed to add todo. Please try again.');
            setAlerta({
                aberto: true,
                mensagem: 'Failed to add todo: ' + (err.response?.data?.message || err.message),
                tipo: 'error'
            });
        }
    };

    const handleToggleTodo = async (id, completed) => {
        try {
            await api.patch(`/todos/${id}`, { completed });
            setTodos(todos.map(todo =>
                todo._id === id ? { ...todo, completed } : todo
            ));
            setError(null);
            setAlerta({
                aberto: true,
                mensagem: `Todo ${completed ? 'completed' : 'uncompleted'}!`,
                tipo: 'success'
            });
        } catch (err) {
            console.error('Error updating todo:', err);
            setAlerta({
                aberto: true,
                mensagem: 'Failed to update todo: ' + (err.response?.data?.message || err.message),
                tipo: 'error'
            });
        }
    };

    const handleDeleteTodo = async (id) => {
        try {
            await api.delete(`/todos/${id}`);
            setTodos(todos.filter(todo => todo._id !== id));
            setError(null);
            setAlerta({
                aberto: true,
                mensagem: 'Todo deleted successfully!',
                tipo: 'success'
            });
        } catch (err) {
            console.error('Error deleting todo:', err);
            setAlerta({
                aberto: true,
                mensagem: 'Failed to delete todo: ' + (err.response?.data?.message || err.message),
                tipo: 'error'
            });
        }
    };

    const handleDragEnd = async (result) => {
        if (!result.destination) return;

        const items = Array.from(todos);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setTodos(items);

        try {
            await api.post('/todos/reorder', {
                items: items.map((item, index) => ({
                    id: item._id,
                    order: index
                }))
            });
            setError(null);
        } catch (err) {
            console.error('Error reordering todos:', err);
            fetchTodos(); // Reload original order on error
            setAlerta({
                aberto: true,
                mensagem: 'Failed to reorder todos: ' + (err.response?.data?.message || err.message),
                tipo: 'error'
            });
        }
    };

    const handleFecharAlerta = () => {
        setAlerta({ ...alerta, aberto: false });
    };

    if (loading) {
        return (
            <Container maxWidth="sm" sx={{ mt: 4, textAlign: 'center' }}>
                <CircularProgress />
            </Container>
        );
    }

    return (
        <Container maxWidth="sm" sx={{ mt: 4, mb: 4 }}>
            <Paper
                elevation={3}
                sx={{
                    p: 3,
                    backgroundColor: theme.palette.background.default
                }}
            >
                <Typography variant="h4" component="h1" gutterBottom align="center" color="primary">
                    Lista de Tarefas
                </Typography>

                {error && (
                    <Alert severity="error" sx={{ mb: 2 }}>
                        {error}
                    </Alert>
                )}

                <Box component="form" onSubmit={handleAddTodo} sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Adicionar Uma Nova Tarefa..."
                        value={newTodoTitle}
                        onChange={(e) => setNewTodoTitle(e.target.value)}
                        sx={{ mb: 1 }}
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        disabled={!newTodoTitle.trim()}
                    >
                        Adicionar Tarefa
                    </Button>
                </Box>

                <DragDropContext onDragEnd={handleDragEnd}>
                    <DroppableWrapper droppableId="todos">
                        {(provided) => (
                            <>
                                {todos.map((todo, index) => (
                                    <TodoItem
                                        key={todo._id}
                                        todo={todo}
                                        index={index}
                                        onToggle={handleToggleTodo}
                                        onDelete={handleDeleteTodo}
                                    />
                                ))}
                                {provided.placeholder}
                            </>
                        )}
                    </DroppableWrapper>
                </DragDropContext>

                {todos.length === 0 && (
                    <Typography
                        variant="body1"
                        align="center"
                        color="textSecondary"
                        sx={{ mt: 2 }}
                    >
                        Sem Tarefas
                    </Typography>
                )}
            </Paper>

            <AlertaReutilizavel
                mensagem={alerta.mensagem}
                tipo={alerta.tipo}
                aberto={alerta.aberto}
                onFechar={handleFecharAlerta}
            />
        </Container>
    );
};

export default TodoList;
