import { useState, useEffect, useCallback, useRef } from "react";
import api from "../api";
import {
    calcularTotais,
    calcularDataEntregaNaoZoomPlus,
    calcularDataEntregaZoomPlus,
} from "../utils/calculations";

const totaisIniciais = {
    total: 0,
    taxaUrgencia: 0,
    totalComUrgencia: 0,
    valorPagar: 0,
    statusPagamento: "pendente",
    desconto: 0,
    totalFinal: 0,
    saldoUtilizado: 0,
    saldoRestante: 0
};

export const useCadastroPedidos = (mode = 'create') => {
    const [mostrarFormulario, setMostrarFormulario] = useState(false);
    const [clientes, setClientes] = useState([]);
    const [servicos, setServicos] = useState([]);
    const [modoEdicao, setModoEdicao] = useState(mode === 'edit');
    const [novoPedido, setNovoPedido] = useState({
        clienteId: "",
        servicos: [],
        isUrgent: false,
        linkDrive: "",
        retirada: false
    });
    const [clienteZoomPlus, setClienteZoomPlus] = useState(null);
    const [alerta, setAlerta] = useState({ mensagem: "", tipo: "info", aberto: false });
    const [totais, setTotais] = useState(totaisIniciais);
    const [dataEntrega, setDataEntrega] = useState("");
    const [dataLimiteRetirada, setDataLimiteRetirada] = useState(null);
    const dataFetchedRef = useRef(false);
    const listaPedidosRef = useRef();

    // Loading states
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingCliente, setIsLoadingCliente] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoadingPedido, setIsLoadingPedido] = useState(false);

    const resetForm = useCallback(() => {
        setNovoPedido({
            clienteId: "",
            servicos: [],
            isUrgent: false,
            linkDrive: "",
            retirada: false
        });
        setClienteZoomPlus(null);
        setTotais(totaisIniciais);
        setDataEntrega("");
        setDataLimiteRetirada(null);
    }, []);

    const toggleFormulario = useCallback(() => {
        setMostrarFormulario(prev => !prev);
        // Reset dataFetchedRef when showing the form
        if (!mostrarFormulario) {
            dataFetchedRef.current = false;
        }
        if (mostrarFormulario) {
            resetForm();
        }
    }, [mostrarFormulario, resetForm]);

    const fetchData = useCallback(async () => {

        if (dataFetchedRef.current) return;
        setIsLoading(true);
        try {
            const [clientesRes, servicosRes] = await Promise.all([
                api.get("/clientes"),
                api.get("/servicos"),
            ]);

            if (!clientesRes.data) {

                setAlerta({
                    mensagem: "Erro ao carregar clientes: dados não recebidos.",
                    tipo: "error",
                    aberto: true,
                });
                return;
            }

            // Ensure we have an array of clients
            const clientesData = Array.isArray(clientesRes.data) ? clientesRes.data :
                (clientesRes.data.clientes || []); // In case the data is wrapped in an object

            setClientes(clientesData);
            setServicos(servicosRes.data || []);
            dataFetchedRef.current = true;
        } catch (error) {

            setAlerta({
                mensagem: "Erro ao carregar clientes e serviços.",
                tipo: "error",
                aberto: true,
            });
        } finally {
            setIsLoading(false);
        }
    }, []);

    const handleClienteChange = useCallback(async (clienteId) => {
        if (!clienteId) {
            setClienteZoomPlus(null);
            setNovoPedido((prev) => ({ ...prev, clienteId: "" }));
            setDataEntrega("");
            setDataLimiteRetirada(null);
            return;
        }

        setIsLoadingCliente(true);
        const cliente = clientes.find((c) => c._id === clienteId);
        if (!cliente) {
            setClienteZoomPlus(null);
            setDataEntrega("");
            setDataLimiteRetirada(null);
            setIsLoadingCliente(false);
            return;
        }

        setNovoPedido((prev) => ({ ...prev, clienteId }));
        if (cliente.isZoomPlus && cliente.planoId) {
            try {
                const planoId =
                    typeof cliente.planoId === "string" ? cliente.planoId : cliente.planoId._id;
                const planoResponse = await api.get(`/planos/${planoId}`);
                const plano = planoResponse.data;

                setClienteZoomPlus({ ...cliente, plano });
                const dataEntregaCalculada = calcularDataEntregaZoomPlus(plano.prazoEntrega);
                setDataEntrega(dataEntregaCalculada);
                // Calcula data limite de retirada (data de entrega + 30 dias)
                const dataLimite = new Date(dataEntregaCalculada);
                dataLimite.setDate(dataLimite.getDate() + 30);
                setDataLimiteRetirada(dataLimite);
            } catch {
                setClienteZoomPlus(null);
                const dataEntregaCalculada = calcularDataEntregaNaoZoomPlus();
                setDataEntrega(dataEntregaCalculada);
                const dataLimite = new Date(dataEntregaCalculada);
                dataLimite.setDate(dataLimite.getDate() + 30);
                setDataLimiteRetirada(dataLimite);
            }
        } else {
            setClienteZoomPlus(null);
            const dataEntregaCalculada = calcularDataEntregaNaoZoomPlus();
            setDataEntrega(dataEntregaCalculada);
            const dataLimite = new Date(dataEntregaCalculada);
            dataLimite.setDate(dataLimite.getDate() + 30);
            setDataLimiteRetirada(dataLimite);
        }
        setIsLoadingCliente(false);
    }, [clientes]);

    const handleServicoAdd = useCallback((_, servico) => {
        if (!servico || novoPedido.servicos.some((s) => s.servicoId?._id === servico._id)) return;
        setNovoPedido((prev) => ({
            ...prev,
            servicos: [
                ...prev.servicos,
                {
                    servicoId: { _id: servico._id, nome: servico.nome, preco: servico.preco },
                    quantidade: 1,
                    subtotal: servico.preco,
                },
            ],
        }));
    }, [novoPedido.servicos]);

    const handleUrgenciaChange = useCallback((checked) => {
        setNovoPedido((prev) => ({
            ...prev,
            isUrgent: checked
        }));
    }, []);

    const handleQuantidadeChange = useCallback((index, quantidade) => {
        setNovoPedido((prev) => {
            const novosServicos = [...prev.servicos];
            const servico = novosServicos[index].servicoId;

            if (!servico || !servico.preco) {
                console.error("Preço do serviço não encontrado!");
                return prev;
            }

            novosServicos[index].quantidade = quantidade;
            novosServicos[index].subtotal = quantidade * servico.preco;

            return { ...prev, servicos: novosServicos };
        });
    }, []);

    const handleDeleteServico = useCallback((index) => {
        setNovoPedido(prev => {
            const servicosAtualizados = [...prev.servicos];
            servicosAtualizados.splice(index, 1);
            return { ...prev, servicos: servicosAtualizados };
        });
    }, []);

    const createPayload = useCallback(() => {
        const totaisFinais = calcularTotais(novoPedido, clienteZoomPlus, modoEdicao);

        const payloadBase = {
            clienteId: novoPedido.cliente?._id || novoPedido.clienteId,
            servicos: novoPedido.servicos.map((s) => ({
                servicoId: s.servicoId._id || s.servicoId,
                quantidade: Number(s.quantidade),
                subtotal: Number(s.subtotal),
            })),
            total: Number(totaisFinais.total),
            valorUrgencia: novoPedido.isUrgent ? Number(totaisFinais.valorUrgencia) : 0,
            valorPagar: Number(totaisFinais.valorPagar),
            retirada: Boolean(novoPedido.retirada),
            status: novoPedido.status || "aberto",
            statusPagamento: totaisFinais.statusPagamento,
            dataEntrega,
            dataLimiteRetirada,
            isUrgent: Boolean(novoPedido.isUrgent),
            linkDrive: novoPedido.linkDrive || "",
            isZoomPlus: Boolean(clienteZoomPlus),
        };

        // Adiciona campos específicos do ZoomPlus se aplicável
        if (clienteZoomPlus) {
            payloadBase.zoomPlus = {
                planoId: clienteZoomPlus.planoId,
                saldoUtilizado: totaisFinais.saldoUtilizado || 0,
                saldoRestante: totaisFinais.saldoRestante || 0,
                descontoAplicado: totaisFinais.descontoAplicado || 0,
                beneficiosUtilizados: {
                    taxaUrgenciaGratis: Boolean(totaisFinais.taxaUrgenciaGratis),
                    modeloCompletaGratis: Boolean(totaisFinais.modeloCompletaGratis)
                }
            };
        }

        return { payload: payloadBase, totaisFinais };
    }, [novoPedido, clienteZoomPlus, dataEntrega, dataLimiteRetirada, modoEdicao]);

    const handleSubmit = async (e, pedidoId = null, onSuccess = null) => {
        e.preventDefault();

        if (!novoPedido.clienteId || novoPedido.servicos.length === 0) {
            setAlerta({
                mensagem: "Cliente e pelo menos um serviço são obrigatórios.",
                tipo: "error",
                aberto: true,
            });
            return;
        }

        setIsSubmitting(true);
        const { payload, totaisFinais } = createPayload();

        try {
            let response;

            if (pedidoId) {
                response = await api.put(`/pedidos/${pedidoId}`, payload);
            } else {
                response = await api.post("/pedidos", payload);
            }

            setAlerta({
                mensagem: pedidoId ? "Pedido atualizado com sucesso!" : "Pedido cadastrado com sucesso!",
                tipo: "success",
                aberto: true,
            });

            if (onSuccess) {
                onSuccess();
            } else {
                resetForm();
                setMostrarFormulario(false);
            }

            if (listaPedidosRef.current?.carregarPedidos) {
                listaPedidosRef.current.carregarPedidos();
            }
        } catch (error) {
            setAlerta({
                mensagem: error.response?.data?.error || `Erro ao ${pedidoId ? 'atualizar' : 'cadastrar'} pedido.`,
                tipo: "error",
                aberto: true,
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    const loadPedidoForEdit = useCallback(async (pedidoId) => {
        if (!pedidoId) return;

        setIsLoadingPedido(true);
        try {
            const response = await api.get(`/pedidos/${pedidoId}`);
            const pedido = response.data;

            const clienteId = typeof pedido.clienteId === 'object' ? pedido.clienteId._id : pedido.clienteId;

            const clienteResponse = await api.get(`/clientes/${clienteId}`);
            const cliente = clienteResponse.data;

            let plano = null;
            if (cliente.isZoomPlus && cliente.planoId) {
                const planoResponse = await api.get(`/planos/${cliente.planoId}`);
                plano = planoResponse.data;

                setClienteZoomPlus({
                    ...cliente,
                    plano
                });
            }

            setNovoPedido(prev => ({
                ...prev,
                clienteId,
                cliente,
                servicos: pedido.servicos.map((s) => ({
                    servicoId: typeof s.servicoId === 'object' ? s.servicoId : { _id: s.servicoId },
                    quantidade: s.quantidade,
                    subtotal: s.subtotal,
                })),
                isUrgent: pedido.isUrgent,
                retirada: pedido.retirada || false,
                status: pedido.status,
                statusPagamento: pedido.statusPagamento || "pendente",
                linkDrive: pedido.linkDrive || ""
            }));

            setDataEntrega(pedido.dataEntrega);
            setDataLimiteRetirada(pedido.dataLimiteRetirada);

        } catch (error) {
            console.error("Erro detalhado ao carregar pedido:", error);
            setAlerta({
                mensagem: error.response?.data?.error || "Erro ao carregar pedido.",
                tipo: "error",
                aberto: true,
            });
        } finally {
            setIsLoadingPedido(false);
        }
    }, []);

    useEffect(() => {
        if ((mode === 'create' || mode === 'edit') && mostrarFormulario) {
            fetchData();
        }
        return () => {
            if (mode === 'create') {
                resetForm();
            }
        };
    }, [mode, fetchData, resetForm, mostrarFormulario]);

    useEffect(() => {
        const calculatedTotais = calcularTotais(novoPedido, clienteZoomPlus, modoEdicao);
        setTotais({ ...totaisIniciais, ...calculatedTotais });
    }, [novoPedido.servicos, clienteZoomPlus, novoPedido.isUrgent, modoEdicao]);

    return {
        mostrarFormulario,
        toggleFormulario,
        clientes,
        servicos,
        novoPedido,
        clienteZoomPlus,
        alerta,
        totais,
        dataEntrega,
        dataLimiteRetirada,
        handleClienteChange,
        handleServicoAdd,
        handleUrgenciaChange,
        handleQuantidadeChange,
        handleDeleteServico,
        handleSubmit,
        setAlerta,
        listaPedidosRef,
        fetchData,
        setNovoPedido,
        resetForm,
        modoEdicao,
        loadPedidoForEdit,
        // Loading states
        isLoading,
        isLoadingCliente,
        isSubmitting,
        isLoadingPedido
    };
};
