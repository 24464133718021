import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { Box, Typography, IconButton, Tooltip } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import PaymentsTable from '../components/PaymentsTable';
import paymentService from '../services/paymentService';
import { useWebSocket } from '../hooks/useWebSocket';
import { usePaymentHandling } from '../hooks/usePaymentHandling';

const Payments = () => {
    const [payments, setPayments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [totalCount, setTotalCount] = useState(0);
    const [filters, setFilters] = useState({
        status: '',
        billingType: '',
        customer: '',
        dateFrom: '',
        dateTo: '',
    });

    // Refs for component lifecycle
    const isMounted = useRef(true);
    const lastUpdateTime = useRef(Date.now());
    const initialLoadDone = useRef(false);
    const currentFilters = useRef(filters);
    const pendingUpdates = useRef(new Set());
    const isLoadingRef = useRef(false);

    // Update currentFilters ref when filters change
    useEffect(() => {
        currentFilters.current = filters;
    }, [filters]);

    // Cleanup on unmount
    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    const loadPayments = useCallback(async (skipLoading = false) => {
        if (!isMounted.current || isLoadingRef.current) return;

        if (!skipLoading) {
            setLoading(true);
        }
        isLoadingRef.current = true;

        try {
            const cleanFilters = {
                ...currentFilters.current,
                page,
                limit
            };

            console.log('[Payments] Loading with filters:', cleanFilters);
            const response = await paymentService.listPayments(cleanFilters);

            if (isMounted.current) {
                console.log('[Payments] Loaded payments:', response.data?.length);
                setPayments(response.data || []);
                setTotalCount(response.totalCount || 0);
                initialLoadDone.current = true;
                lastUpdateTime.current = Date.now();
                pendingUpdates.current.clear();
            }
        } catch (error) {
            console.error('[Payments] Error loading payments:', error);
            if (isMounted.current) {
                setPayments([]);
                setTotalCount(0);
            }
        } finally {
            if (isMounted.current && !skipLoading) {
                setLoading(false);
            }
            isLoadingRef.current = false;
        }
    }, [page, limit]);

    const {
        paymentDetails,
        showPaymentDetails,
        isLoadingPayment,
        handlePaymentAction,
        handlePaymentStatusChange,
        setShowPaymentDetails,
        getOptimisticState
    } = usePaymentHandling(loadPayments, (alert) => {
        console.log('Payment alert:', alert);
    });

    // WebSocket message handler with immediate updates for payment events
    const handleWebSocketMessage = useCallback(async (data) => {
        if (!isMounted.current) return;

        if (data.event === 'connected') {
            if (!initialLoadDone.current) {
                await loadPayments(true);
            }
            return;
        }

        // Immediate refresh for payment events
        if (data.event === 'payment_created' || data.event === 'payment_updated') {
            // Check customer filter if present
            if (currentFilters.current.customer &&
                data.data.customer &&
                !data.data.customer.toLowerCase().includes(currentFilters.current.customer.toLowerCase())) {
                return;
            }

            // Refresh immediately for payment events
            console.log(`[Payments] Processing ${data.event} immediately`);
            await loadPayments(true);
            return;
        }

        // For other events, use the pending updates system
        pendingUpdates.current.add(data.data?.id);

        // If no recent updates, refresh immediately
        const now = Date.now();
        const timeSinceLastUpdate = now - lastUpdateTime.current;

        if (timeSinceLastUpdate >= 300) { // 300ms minimum between updates for non-payment events
            console.log('[Payments] Processing pending updates');
            await loadPayments(true);
        } else {
            // Schedule a refresh if there are pending updates
            setTimeout(async () => {
                if (pendingUpdates.current.size > 0) {
                    console.log('[Payments] Processing delayed updates');
                    await loadPayments(true);
                }
            }, 300);
        }
    }, [loadPayments]);

    // Initialize WebSocket connection with reconnection handling
    const { isConnected, reconnect } = useWebSocket(handleWebSocketMessage, {
        reconnectOnClose: true,
        retryAttempts: 5
    });

    // Monitor WebSocket connection
    useEffect(() => {
        if (!isConnected) {
            reconnect();
        }
    }, [isConnected, reconnect]);

    // Initial data load
    useEffect(() => {
        if (!initialLoadDone.current) {
            loadPayments();
        }
    }, [loadPayments]);

    // Combined effect for filters, page, and limit changes
    useEffect(() => {
        if (initialLoadDone.current) {
            loadPayments();
        }
    }, [loadPayments, filters, page, limit]);

    const handleFilterChange = useCallback((newFilters) => {
        if (!isMounted.current) return;
        setFilters(prev => ({ ...prev, ...newFilters }));
        setPage(1); // Reset to page 1 when filters change
    }, []);

    const handlePageChange = useCallback((event, newPage) => {
        if (isMounted.current && !isLoadingRef.current) {
            console.log('[Payments] Changing to page:', newPage);
            setPage(newPage);
        }
    }, []);

    const handleLimitChange = useCallback((event) => {
        if (isMounted.current) {
            setLimit(event.target.value);
            setPage(1); // Reset to page 1 when changing items per page
        }
    }, []);

    const handleRefresh = useCallback(() => {
        if (isMounted.current) {
            loadPayments();
        }
    }, [loadPayments]);

    const filterOptions = useMemo(() => [
        {
            type: 'select',
            label: 'Status',
            name: 'status',
            options: [
                { value: '', label: 'Todos' },
                { value: 'PENDING', label: 'Aguardando Pagamento' },
                { value: 'RECEIVED', label: 'Recebido' },
                { value: 'CONFIRMED', label: 'Confirmado' },
                { value: 'OVERDUE', label: 'Vencido' },
                { value: 'CANCELLED', label: 'Cancelado' }
            ]
        },
        {
            type: 'select',
            label: 'Tipo',
            name: 'billingType',
            options: [
                { value: '', label: 'Todos' },
                { value: 'BOLETO', label: 'Boleto' },
                { value: 'CREDIT_CARD', label: 'Cartão de Crédito' },
                { value: 'PIX', label: 'PIX' }
            ]
        },
        {
            type: 'text',
            label: 'Cliente',
            name: 'customer'
        },
        {
            type: 'date',
            label: 'Data Inicial',
            name: 'dateFrom'
        },
        {
            type: 'date',
            label: 'Data Final',
            name: 'dateTo'
        }
    ], []);

    return (
        <Box sx={{ p: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                <Typography variant="h4" component="h1">
                    Pagamentos
                </Typography>
                <Tooltip title="Atualizar">
                    <IconButton onClick={handleRefresh} disabled={loading}>
                        <RefreshIcon />
                    </IconButton>
                </Tooltip>
            </Box>

            <PaymentsTable
                payments={payments}
                loading={loading || isLoadingPayment}
                onLoadPayments={handleRefresh}
                page={page}
                limit={limit}
                totalCount={totalCount}
                filters={filters}
                onFilterChange={handleFilterChange}
                onPageChange={handlePageChange}
                onLimitChange={handleLimitChange}
                filterOptions={filterOptions}
                showFilters={true}
                showPagination={true}
            />
        </Box>
    );
};

export default React.memo(Payments);
