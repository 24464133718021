import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { ThemeProvider } from "./contexts/ThemeContext";
import Layout from "./components/Layout";
import UserLayout from "./components/user/UserLayout";
import CadastroClientes from "./pages/CadastroClientes";
import CadastroServicos from "./pages/CadastroServicos";
import CadastroPedidos from "./pages/CadastroPedidos";
import Login from "./pages/Login";
import PrimeiroAcesso from "./pages/PrimeiroAcesso";
import ResetarSenha from "./pages/ResetarSenha";
import UserPage from "./pages/UserPage";
import UserOrdersPage from "./pages/user/UserOrdersPage";
import UserSettings from "./pages/user/UserSettings";
import UserPayments from "./pages/user/UserPayments";
import UserHelp from "./pages/user/UserHelp";
import KnowledgeBase from "./pages/user/KnowledgeBase";
import ArticleView from "./pages/user/ArticleView";
import Dashboard from "./pages/Dashboard";
import ZoomPlus from "./pages/ZoomPlus";
import EditarPedido from "./pages/EditarPedido";
import EditarCliente from "./pages/EditarCliente";
import DadosCliente from "./pages/DadosCliente";
import VisualizarPedido from "./pages/VisualizarPedido";
import LandingPage from "./pages/LandingPage";
import Payments from "./pages/Payments";
import TodoList from "./pages/TodoList";

const App = () => {
  return (
    <ThemeProvider>
      <Routes>
        {/* Rotas públicas */}
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/primeiro-acesso" element={<PrimeiroAcesso />} />
        <Route path="/redefinir-senha/:token" element={<ResetarSenha />} />

        {/* Rotas do usuário comum */}
        <Route path="/user" element={<UserLayout />}>
          <Route path=":id" element={<UserPage />} />
          <Route path="orders" element={<UserOrdersPage />} />
          <Route path="payments" element={<UserPayments />} />
          <Route path="settings" element={<UserSettings />} />
          <Route path="help" element={<UserHelp />} />
          <Route path="pedido/:id" element={<VisualizarPedido />} />
        </Route>

        { /* Rotas Base de Conhecimento */}
        <Route path="/base-de-conhecimento" element={<KnowledgeBase />} />
        <Route path="/base-de-conhecimento/artigo/:articleId" element={<ArticleView />} />

        {/* Rotas protegidas - Admin */}
        <Route
          path="/dashboard"
          element={
            <Layout>
              <Dashboard />
            </Layout>
          }
        />
        <Route
          path="/cadastro-clientes"
          element={
            <Layout>
              <CadastroClientes />
            </Layout>
          }
        />
        <Route
          path="/cadastro-pedidos"
          element={
            <Layout>
              <CadastroPedidos />
            </Layout>
          }
        />
        <Route
          path="/cadastro-servicos"
          element={
            <Layout>
              <CadastroServicos />
            </Layout>
          }
        />
        <Route
          path="/payments"
          element={
            <Layout>
              <Payments />
            </Layout>
          }
        />
        <Route
          path="/todos"
          element={
            <Layout>
              <TodoList />
            </Layout>
          }
        />

        {/* Outras rotas protegidas */}
        <Route
          path="/zoom-plus"
          element={
            <Layout>
              <ZoomPlus />
            </Layout>
          }
        />
        <Route
          path="/editar-pedido/:id"
          element={
            <Layout>
              <EditarPedido />
            </Layout>
          }
        />
        <Route
          path="/editar-cliente/:id"
          element={
            <Layout>
              <EditarCliente />
            </Layout>
          }
        />
        <Route
          path="/dados-cliente/:id"
          element={
            <Layout>
              <DadosCliente />
            </Layout>
          }
        />

        {/* Rota para qualquer outro caminho não definido */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </ThemeProvider>
  );
};

export default App;
