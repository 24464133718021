export const getToken = () => localStorage.getItem('token');

export const isUserAdmin = () => {
    const token = getToken();
    if (!token) return false;

    try {
        const payload = JSON.parse(atob(token.split('.')[1]));
        return payload.isAdmin === true;
    } catch {
        return false;
    }
};

export const getUserId = () => {
    const token = getToken();
    if (!token) return null;

    try {
        const payload = JSON.parse(atob(token.split('.')[1]));
        return payload.id;
    } catch {
        return null;
    }
};
