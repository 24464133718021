import paymentService from '../services/paymentService';

export const formatarValor = (valor) => `R$ ${valor?.toFixed(2) || "0.00"}`;

export const getStatusPagamentoLabel = (statusPagamento, valorPagar, asaasStatus) => {
    // First check internal payment status
    const statusMap = {
        "pendente": "Pendente",
        "pago": "Pago",
        "pago_com_saldo": "Pago com Saldo",
        "pago_parcial_saldo": "Pago Parcialmente com Saldo"
    };

    // If we have an internal status, use it
    if (statusPagamento && statusMap[statusPagamento]) {
        return statusMap[statusPagamento];
    }

    // If there's no asaasStatus (no invoice), return "Pendente"
    if (!asaasStatus) {
        return "Pendente";
    }

    // If there is an asaasStatus (has invoice), use payment service formatting
    return paymentService.formatPaymentStatus(asaasStatus);
};

export const meses = [
    { value: "1", label: "Janeiro" },
    { value: "2", label: "Fevereiro" },
    { value: "3", label: "Março" },
    { value: "4", label: "Abril" },
    { value: "5", label: "Maio" },
    { value: "6", label: "Junho" },
    { value: "7", label: "Julho" },
    { value: "8", label: "Agosto" },
    { value: "9", label: "Setembro" },
    { value: "10", label: "Outubro" },
    { value: "11", label: "Novembro" },
    { value: "12", label: "Dezembro" }
];
