import React, { useState, useEffect } from "react";
import api from "../api";
import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Paper,
  InputAdornment,
  CircularProgress,
  Tooltip,
  Fade,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DescriptionIcon from "@mui/icons-material/Description";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AlertaReutilizavel from "../components/AlertaReutilizavel";
import ListaServicos from "../components/ListaServicos";

const CadastroServicos = () => {
  const [servicos, setServicos] = useState([]);
  const [servico, setServico] = useState({ nome: "", descricao: "", preco: "" });
  const [alerta, setAlerta] = useState({ mensagem: "", tipo: "info", aberto: false });
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const fetchServicos = async () => {
    setLoading(true);
    try {
      const response = await api.get("/servicos");
      setServicos(response.data);
    } catch (error) {
      console.error("Erro ao buscar serviços:", error);
      setAlerta({
        mensagem: "Erro ao carregar serviços.",
        tipo: "error",
        aberto: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchServicos();
  }, []);

  const formatarPreco = (valor) => {
    valor = valor.replace(/\D/g, "");
    const numero = parseInt(valor) / 100;
    return numero.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const handlePrecoChange = (e) => {
    const valorFormatado = formatarPreco(e.target.value);
    setServico({ ...servico, preco: valorFormatado });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!servico.nome || !servico.preco) {
      setAlerta({ mensagem: "Nome e preço são obrigatórios.", tipo: "error", aberto: true });
      return;
    }

    setIsSubmitting(true);
    try {
      const precoNumerico = servico.preco.replace(/\./g, "").replace(",", ".");
      await api.post("/servicos", { ...servico, preco: precoNumerico });
      setAlerta({ mensagem: "Serviço cadastrado com sucesso!", tipo: "success", aberto: true });
      setServico({ nome: "", descricao: "", preco: "" });
      fetchServicos();
    } catch (error) {
      setAlerta({
        mensagem: error.response?.data?.error || "Erro ao cadastrar serviço.",
        tipo: "error",
        aberto: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const fecharAlerta = () => {
    setAlerta({ ...alerta, aberto: false });
  };

  return (
    <Box sx={{ animation: "fadeIn 0.5s ease-in-out" }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper
            elevation={3}
            sx={{
              padding: "24px",
              height: "100%",
              transition: "transform 0.2s ease-in-out",
              "&:hover": {
                transform: "translateY(-5px)",
              },
            }}
          >
            <Typography
              variant="h4"
              color="primary"
              gutterBottom
              sx={{
                textAlign: "center",
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 1,
              }}
            >
              <ShoppingCartIcon sx={{ fontSize: 35 }} />
              Cadastro de Serviços
            </Typography>

            {(servico.nome || servico.preco || servico.descricao) && (
              <Paper
                elevation={2}
                sx={{
                  p: 2,
                  mb: 3,
                  backgroundColor: "background.paper",
                  borderRadius: 1,
                  border: 1,
                  borderColor: "divider",
                  borderStyle: "dashed"
                }}
              >
                <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                  Preview do Serviço
                </Typography>
                <Typography variant="h6" color="primary">
                  {servico.nome || "Nome do Serviço"}
                </Typography>
                {servico.descricao && (
                  <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                    {servico.descricao}
                  </Typography>
                )}
                {servico.preco && (
                  <Typography variant="h6" color="secondary" sx={{ mt: 1 }}>
                    R$ {servico.preco}
                  </Typography>
                )}
              </Paper>
            )}

            <Box
              component="form"
              sx={{
                "& > :not(style)": { marginBottom: "16px", width: "100%" },
              }}
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <TextField
                required
                id="nome"
                label="Nome do Serviço"
                variant="outlined"
                value={servico.nome}
                onChange={(e) => setServico({ ...servico, nome: e.target.value })}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AddCircleOutlineIcon color="primary" />
                    </InputAdornment>
                  ),
                }}
                helperText="Digite o nome do serviço"
              />

              <TextField
                id="descricao"
                label="Descrição"
                variant="outlined"
                multiline
                rows={3}
                value={servico.descricao}
                onChange={(e) => setServico({ ...servico, descricao: e.target.value })}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <DescriptionIcon color="primary" />
                    </InputAdornment>
                  ),
                }}
                helperText="Adicione uma descrição detalhada do serviço"
              />

              <TextField
                required
                id="preco"
                label="Preço"
                variant="outlined"
                value={servico.preco}
                onChange={handlePrecoChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AttachMoneyIcon color="primary" />
                      R$
                    </InputAdornment>
                  ),
                }}
                helperText="Digite o preço do serviço"
              />

              <Tooltip title="Cadastrar novo serviço" arrow>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  sx={{
                    height: "56px",
                    transition: "transform 0.2s",
                    "&:hover": {
                      transform: "scale(1.02)",
                    },
                  }}
                >
                  {isSubmitting ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    <>
                      <AddCircleOutlineIcon sx={{ mr: 1 }} />
                      Cadastrar Serviço
                    </>
                  )}
                </Button>
              </Tooltip>
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Fade in={true} timeout={500}>
            <Box>
              {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="200px">
                  <CircularProgress />
                </Box>
              ) : (
                <ListaServicos
                  servicos={servicos}
                  fetchServicos={fetchServicos}
                />
              )}
            </Box>
          </Fade>
        </Grid>
      </Grid>

      <AlertaReutilizavel
        mensagem={alerta.mensagem}
        tipo={alerta.tipo}
        aberto={alerta.aberto}
        onFechar={fecharAlerta}
      />

      <style>
        {`
          @keyframes fadeIn {
            from {
              opacity: 0;
              transform: translateY(20px);
            }
            to {
              opacity: 1;
              transform: translateY(0);
            }
          }
        `}
      </style>
    </Box>
  );
};

export default CadastroServicos;
