import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Paper,
  Grid,
  Box,
  Typography,
  Divider,
  Button,
} from "@mui/material";
import {
  AccountCircle as AccountCircleIcon,
  Email as EmailIcon,
  Description as DescriptionIcon,
  Phone as PhoneIcon,
  Home as HomeIcon,
  ListAlt as ListAltIcon,
} from "@mui/icons-material";
import ZoomPlusStyling from "./ZoomPlusStyling";
import ClientZoomPlusCard from "./ClientZoomPlusCard";
import PaymentsTable from "./PaymentsTable";
import paymentService from "../services/paymentService";

const ClientDetails = ({ cliente }) => {
  const [subscriptionPayments, setSubscriptionPayments] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleViewSubscriptionPayments = async () => {
    if (cliente.subscriptionId) {
      setLoading(true);
      try {
        const response = await paymentService.listSubscriptionPayments(cliente.subscriptionId);
        setSubscriptionPayments(response.data);
      } catch (error) {
        console.error('Error fetching subscription payments:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const payments = subscriptionPayments || cliente.pagamentos || [];

  return (
    <Paper sx={{ padding: "20px", marginBottom: "20px", borderRadius: "12px" }}>
      <Grid container spacing={3}>
        {/* Informações Básicas */}
        <Grid item xs={12}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <AccountCircleIcon color="primary" />
            <ZoomPlusStyling
              name={cliente.nome}
              planId={cliente.planoId}
              isZoomPlus={cliente.isZoomPlus}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <EmailIcon color="primary" />
            <Typography>Email: {cliente.email}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <DescriptionIcon color="primary" />
            <Typography>Documento: {cliente.documento || "Não informado"}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <PhoneIcon color="primary" />
            <Typography>Telefone: {cliente.telefone || "Não informado"}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <HomeIcon color="primary" />
            <Typography>Endereço: {cliente.endereco || "Não informado"}</Typography>
          </Box>
        </Grid>
      </Grid>

      <Divider sx={{ marginY: "20px", opacity: 0 }} />

      {/* Dados Zoom+ */}
      {cliente.isZoomPlus && (
        <ClientZoomPlusCard cliente={cliente} />
      )}

      <Divider sx={{ marginY: "20px", opacity: 0 }} />

      {/* Pagamentos */}
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: 2 }}>
        <Typography variant="h6" color="primary">
          Pagamentos
        </Typography>
        {cliente.subscriptionId && (
          <Button
            startIcon={<ListAltIcon />}
            onClick={handleViewSubscriptionPayments}
            disabled={loading}
            variant="outlined"
            size="small"
          >
            {loading ? "Carregando..." : "Ver Pagamentos da Assinatura"}
          </Button>
        )}
      </Box>

      <PaymentsTable
        payments={payments}
        loading={loading}
        showFilters={false}
        showPagination={false}
      />
    </Paper>
  );
};

ClientDetails.propTypes = {
  cliente: PropTypes.shape({
    nome: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    documento: PropTypes.string,
    telefone: PropTypes.string,
    endereco: PropTypes.string,
    isZoomPlus: PropTypes.bool,
    dataInicioPlano: PropTypes.string,
    diaVencimento: PropTypes.string,
    dataFinalPlano: PropTypes.string,
    saldo: PropTypes.number,
    taxasUrgenciaDisponiveis: PropTypes.number,
    usoModelosDisponiveis: PropTypes.number,
    subscriptionId: PropTypes.string,
    pagamentos: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        status: PropTypes.string,
        value: PropTypes.number,
        dueDate: PropTypes.string,
        paymentDate: PropTypes.string,
        billingType: PropTypes.string,
        description: PropTypes.string,
        invoiceUrl: PropTypes.string,
        dateCreated: PropTypes.string,
        customerDetails: PropTypes.shape({
          name: PropTypes.string
        }),
        externalReference: PropTypes.string,
      })
    ),
  }).isRequired,
};

export default ClientDetails;
