import React from 'react';

import { alpha, Typography, useTheme } from '@mui/material';

const SectionTitle = ({ children, width, mb, ...props }) => {
    const theme = useTheme();
    return (
        <Typography
            {...props}
            mb={mb || 4}
            sx={{
                fontWeight: 600,
                color: alpha(theme.palette.text.primary, 0.9),
                position: 'relative',
                '&::after': {
                    content: '""',
                    position: 'absolute',
                    bottom: -8,
                    left: 0,
                    width,
                    height: 3,
                    borderRadius: '2px',
                    background: `linear-gradient(90deg, ${theme.palette.primary.main}, ${alpha(theme.palette.primary.main, 0)})`
                }
            }}
        >
            {children}
        </Typography>
    );
};


export default SectionTitle;