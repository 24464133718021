import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { GlobalStyles } from '@mui/material';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <GlobalStyles
      styles={{
        "html, body": {
          margin: 0,
          padding: 0,
          width: "100%",
          height: "100%",
          boxSizing: "border-box",
        },
        "#root": {
          height: "100%",
        },
      }}
    />
    <App />
  </BrowserRouter>
);
