import React from 'react';
import {
    Grid,
    TextField,
    Select,
    MenuItem,
    InputAdornment,
    FormControlLabel,
    Switch,
} from '@mui/material';

const PlanConfigForm = ({
    cliente,
    onChange,
    planos = [],
    formErrors = {},
    disabled = false,
    selectedPlano = null,
    showAdminSwitch = false
}) => {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={cliente.isZoomPlus}
                            onChange={(e) => onChange('isZoomPlus', e.target.checked)}
                            disabled={disabled && cliente.isZoomPlus}
                        />
                    }
                    label="Assinante do Plano Zoom+"
                />
            </Grid>

            {showAdminSwitch && (
                <Grid item xs={12} md={6}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={cliente.isAdmin}
                                onChange={(e) => onChange('isAdmin', e.target.checked)}
                            />
                        }
                        label="Administrador"
                    />
                </Grid>
            )}

            {cliente.isZoomPlus && (
                <>
                    <Grid item xs={12} md={6}>
                        <Select
                            value={cliente.planoId || ""}
                            onChange={(e) => onChange('planoId', e.target.value)}
                            fullWidth
                            displayEmpty
                            error={!!formErrors.planoId}
                            disabled={disabled}
                            label="Plano"
                        >
                            <MenuItem value="">Selecione um plano</MenuItem>
                            {planos.map((plano) => (
                                <MenuItem key={plano._id} value={plano._id}>
                                    {plano.nome}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Data Início do Plano"
                            type="date"
                            value={cliente.dataInicioPlano}
                            onChange={(e) => onChange('dataInicioPlano', e.target.value)}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            error={!!formErrors.dataInicioPlano}
                            helperText={formErrors.dataInicioPlano}
                            disabled={disabled}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Data Final do Plano"
                            type="date"
                            value={cliente.dataFinalPlano}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            disabled={true}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Dia do Vencimento"
                            type="number"
                            value={cliente.diaVencimento}
                            fullWidth
                            InputProps={{
                                inputProps: { min: 1, max: 31 }
                            }}
                            disabled={true}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Valor da Mensalidade"
                            type="number"
                            value={selectedPlano?.valorMensalidade || cliente.valorMensalidade}
                            fullWidth
                            InputProps={{
                                startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                                readOnly: true
                            }}
                            disabled={true}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Saldo Atual"
                            type="number"
                            value={cliente.saldo}
                            onChange={(e) => onChange('saldo', parseFloat(e.target.value))}
                            fullWidth
                            InputProps={{
                                startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                                inputProps: { min: 0 }
                            }}
                            disabled={disabled}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Taxas de Urgência Disponíveis"
                            type="number"
                            value={cliente.taxasUrgenciaDisponiveis}
                            onChange={(e) => onChange('taxasUrgenciaDisponiveis', parseInt(e.target.value))}
                            fullWidth
                            InputProps={{
                                inputProps: { min: 0 }
                            }}
                            disabled={disabled}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Usos de Modelos Disponíveis"
                            type="number"
                            value={cliente.usoModelosDisponiveis}
                            onChange={(e) => onChange('usoModelosDisponiveis', parseInt(e.target.value))}
                            fullWidth
                            InputProps={{
                                inputProps: { min: 0 }
                            }}
                            disabled={disabled}
                        />
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export default PlanConfigForm;
