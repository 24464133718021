import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography, Box, CircularProgress } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import api from '../api';

const ZoomPlusStyling = ({ name, planId, isZoomPlus }) => {
  const [plan, setPlan] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Only fetch plan if client is Zoom+ and has a valid planId
    if (!isZoomPlus || !planId || !/^[0-9a-fA-F]{24}$/.test(planId)) {
      setPlan(null);
      setLoading(false);
      return;
    }

    const fetchPlan = async () => {
      setLoading(true);

      try {
        const response = await api.get(`/planos/${planId}`);
        setPlan(response.data);
      } catch (error) {
        console.error('Erro ao buscar o plano:', error.message);
        setPlan(null);
      } finally {
        setLoading(false);
      }
    };

    fetchPlan();
  }, [planId, isZoomPlus]);

  const getPlanStyle = (planName) => {
    if (!planName) return null;
    switch (planName.toLowerCase()) {
      case 'essencial':
        return { color: '#CD7F32', label: 'Zoom+ Essencial' }; // Bronze
      case 'profissional':
        return { color: '#C0C0C0', label: 'Zoom+ Profissional' }; // Prata
      case 'premium':
        return { color: '#FFD700', label: 'Zoom+ Premium' }; // Dourado
      default:
        return null;
    }
  };

  // Only get plan style if client is Zoom+
  const planStyle = isZoomPlus ? getPlanStyle(plan?.nome) : null;

  const starStyle = {
    color: planStyle?.color ? planStyle.color : 'transparent',
    animation: 'spin 10s linear infinite, glow 2s ease-in-out infinite',
  };

  return (
    <Box display="flex" alignItems="center" gap={1}>
      <Typography variant="body1" fontWeight="bold" color="text.primary">
        {name}
      </Typography>
      {loading ? (
        <CircularProgress size={16} />
      ) : (isZoomPlus && planStyle) ? (
        <StarIcon
          style={starStyle}
          titleAccess={planStyle.label}
        />
      ) : null}
    </Box>
  );
};

ZoomPlusStyling.propTypes = {
  name: PropTypes.string.isRequired,
  planId: PropTypes.string,
  isZoomPlus: PropTypes.bool.isRequired, // Added isZoomPlus as required prop
};

export default ZoomPlusStyling;
