export const STATUS_OPTIONS = {
    ABERTO: "aberto",
    EM_ANDAMENTO: "em andamento",
    CONCLUIDO: "concluido",
    CANCELADO: "cancelado",
};

export const PAYMENT_STATUS = {
    RECEIVED: "RECEIVED",
    CONFIRMED: "CONFIRMED",
    RECEIVED_IN_CASH: "RECEIVED_IN_CASH",
};

export const STYLES = {
    header: {
        fontWeight: "normal",
        backgroundColor: "background.default",
        color: "text.secondary",
        textTransform: "none",
        fontSize: "14px",
        padding: "16px",
        borderBottom: "1px solid #e0e0e0",
    }
};
