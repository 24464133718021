import React from 'react';
import {
    Box,
    Container,
    Typography,
    Paper,
    useTheme,
    IconButton,
    Breadcrumbs,
    Link as MuiLink,
    Stack
} from '@mui/material';
import { ArrowBack, Article as ArticleIcon } from '@mui/icons-material';
import { useParams, useNavigate, Link } from 'react-router-dom';
import SectionTitle from '../../components/SectionTitle';
import ThemeToggle from '../../components/ThemeToggle';
import articleContent from '../../data/articleContent';

const ArticleView = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { articleId } = useParams();

    const article = articleContent[articleId];

    if (!article) {
        return (
            <Box sx={{
                minHeight: "100vh",
                background: theme.palette.background.default,
                p: { xs: 2, sm: 4 }
            }}>
                <Container maxWidth="lg">
                    <Typography variant="h5">Artigo não encontrado</Typography>
                </Container>
            </Box>
        );
    }

    return (
        <Box sx={{
            minHeight: "100vh",
            background: theme.palette.background.default,
            p: { xs: 2, sm: 4 }
        }}>
            <Container maxWidth="lg">
                {/* Header with back button and theme toggle */}
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ mb: 3 }}
                >
                    <IconButton
                        onClick={() => navigate(-1)}
                        sx={{
                            backgroundColor: theme.palette.primary.main,
                            color: 'white',
                            '&:hover': {
                                backgroundColor: theme.palette.primary.dark,
                            },
                            width: 40,
                            height: 40
                        }}
                    >
                        <ArrowBack />
                    </IconButton>
                    <ThemeToggle />
                </Stack>

                {/* Article Title */}
                <SectionTitle
                    variant="h4"
                    mb={6}
                    width="auto"
                    sx={{
                        fontSize: { xs: '1.5rem', sm: '2rem' },
                        fontWeight: 600,
                        color: theme.palette.primary.main
                    }}
                >
                    {article.title}
                </SectionTitle>

                {/* Article Content */}
                <Paper
                    elevation={0}
                    sx={{
                        p: { xs: 3, sm: 5 },
                        background: theme.palette.background.elevated,
                        borderRadius: '16px',
                        border: `1px solid ${theme.palette.divider}`,
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                        '& p': {
                            mb: 3,
                            lineHeight: 1.8,
                            fontSize: '1.1rem',
                            color: theme.palette.text.primary,
                            letterSpacing: '0.3px'
                        },
                        '& ul': {
                            mb: 3,
                            pl: 3,
                            listStyleType: 'none'
                        },
                        '& li': {
                            mb: 1.5,
                            position: 'relative',
                            paddingLeft: '1.5rem',
                            '&::before': {
                                content: '""',
                                position: 'absolute',
                                left: 0,
                                top: '0.5rem',
                                width: '6px',
                                height: '6px',
                                backgroundColor: theme.palette.primary.main,
                                borderRadius: '50%'
                            }
                        }
                    }}
                >
                    {article.content.split('\n\n').map((paragraph, index) => (
                        <React.Fragment key={index}>
                            {paragraph.startsWith('-') ? (
                                <ul>
                                    {paragraph.split('\n').map((item, i) => (
                                        <li key={i}>{item.replace('- ', '')}</li>
                                    ))}
                                </ul>
                            ) : (
                                <Typography paragraph>
                                    {paragraph}
                                </Typography>
                            )}
                        </React.Fragment>
                    ))}
                </Paper>
            </Container>
        </Box>
    );
};

export default ArticleView;
