import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import api from "../api";
import {
  Box,
  TextField,
  Button,
  Typography,
  Alert,
  Grid,
  Paper,
  FormControlLabel,
  Checkbox,
  Link,
  useTheme,
} from "@mui/material";
import Header from "../components/Header";
import { isUserAdmin, getUserId } from "../utils/auth";
import logoHorizontalLight from "../assets/logos/Logo Branco com Laranja.svg";
import logoHorizontalDark from "../assets/logos/Logo Preto com Laranja.svg";
import logoSimbolo from "../assets/logos/simbolo laranja.png";
import ThemeToggle from "../components/ThemeToggle";

const Login = () => {
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [mensagem, setMensagem] = useState("");
  const [requiresFirstAccess, setRequiresFirstAccess] = useState(false);
  const [lembrarLogin, setLembrarLogin] = useState(false);
  const [resetEmailSent, setResetEmailSent] = useState(false);
  const navigate = useNavigate();
  const heroRef = useRef(null);
  const theme = useTheme();

  useEffect(() => {
    setIsVisible(true);

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('animate-in');
          }
        });
      },
      { threshold: 0.1 }
    );

    document.querySelectorAll('.animate-on-scroll').forEach((el) => {
      observer.observe(el);
    });

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    const savedEmail = localStorage.getItem("savedEmail");
    const savedPassword = localStorage.getItem("savedPassword");

    if (savedEmail && savedPassword) {
      setEmail(savedEmail);
      setSenha(savedPassword);
      setLembrarLogin(true);
    }
  }, []);

  useEffect(() => {
    localStorage.removeItem("token");
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    console.log("Login attempt with:", { email });
    setMensagem("");
    setRequiresFirstAccess(false);

    try {
      console.log("Making API request to /auth/login");
      const response = await api.post("/auth/login", { email, senha });
      console.log("Login response:", response.data);
      const { token } = response.data;

      if (lembrarLogin) {
        localStorage.setItem("savedEmail", email);
        localStorage.setItem("savedPassword", senha);
      } else {
        localStorage.removeItem("savedEmail");
        localStorage.removeItem("savedPassword");
      }

      localStorage.setItem("token", token);

      const admin = isUserAdmin();
      const userId = getUserId();

      console.log("Token decoded - Admin:", admin, "UserId:", userId);

      if (!userId) {
        throw new Error("Failed to get user ID from token");
      }

      setMensagem("Login realizado com sucesso!");

      if (admin) {
        console.log("Navigating to dashboard");
        navigate("/dashboard", { replace: true });
      } else {
        console.log("Navigating to user page:", `/user/${userId}`);
        navigate(`/user/${userId}`, { replace: true });
      }
    } catch (error) {
      console.error("Login error:", error.response || error);
      if (error.response?.status === 403 && error.response?.data?.requiresFirstAccess) {
        setRequiresFirstAccess(true);
        setMensagem(error.response.data.mensagem);
      } else if (error.response?.status === 404) {
        setMensagem("E-mail não encontrado no sistema.");
      } else {
        setMensagem(
          error.response?.data?.mensagem ||
          error.message ||
          "Erro ao realizar login. Tente novamente."
        );
      }
    }
  };

  const handlePrimeiroAcesso = () => {
    navigate("/primeiro-acesso", { state: { email } });
  };

  const handleEsqueciSenha = async () => {
    if (!email) {
      setMensagem("Por favor, insira seu e-mail para redefinir a senha.");
      return;
    }

    try {
      const response = await api.post("/auth/esqueci-senha", { email });
      setResetEmailSent(true);
      setMensagem("Instruções para redefinição de senha foram enviadas para seu email.");
    } catch (error) {
      setMensagem(
        error.response?.data?.mensagem ||
        "Erro ao solicitar redefinição de senha. Tente novamente."
      );
    }
  };

  const [isVisible, setIsVisible] = useState(false);

  return (
    <Box
      sx={{
        minHeight: '100vh',
        bgcolor: theme.palette.background.default,
        color: theme.palette.text.primary,
        overflow: 'hidden',
        position: 'relative',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '200%',
          height: '200%',
          background: `radial-gradient(circle, ${theme.palette.primary.main} 0%, transparent 50%)`,
          opacity: 0.15,
          top: '-25%',
          left: '-25%'
        },
        '&::after': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '200%',
          height: '200%',
          background: `radial-gradient(circle, ${theme.palette.secondary.main} 0%, transparent 40%)`,
          opacity: 0.2,
          top: '-25%',
          left: '-25%'
        },
      }}
    >
      <Box
        ref={heroRef}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: 'calc(100vh - 80px)', // Subtract header height
          padding: '20px',
          position: 'relative',
          zIndex: 1,
        }}
      >
        <Paper
          sx={{
            padding: '30px',
            maxWidth: '400px',
            width: '100%',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            background: `rgba(255,255,255,0.03)`,
            backdropFilter: 'blur(10px)',
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: '20px',
            position: 'relative',
            '& .animate-on-scroll': {
              opacity: 0,
              transform: 'translateY(20px)',
              '&.animate-in': {
                opacity: 1,
                transform: 'translateY(0)',
                transition: 'all 0.8s cubic-bezier(0.4, 0, 0.2, 1) 0.3s'
              }
            }
          }}
          elevation={0}
        >
          <Box
            sx={{
              position: 'absolute',
              top: 16,
              right: 16,
            }}
          >
            <ThemeToggle />
          </Box>
          <Box
            component="img"
            src={theme.palette.mode === 'dark' ? logoHorizontalLight : logoHorizontalDark}
            alt="Zoompro Studio"
            sx={{
              width: '150px',
              height: '150px',
              display: { xs: 'none', md: 'block' },
            }}
          />
          <Typography
            variant="h5"
            sx={{
              fontFamily: '"Poppins", sans-serif',
              fontWeight: 600,
              mb: 2,
              background: theme.palette.primary.main,
              backgroundClip: 'text',
              textFillColor: 'transparent',
            }}
          >
            Login
          </Typography>
          <Typography
            variant="body2"
            color={theme.palette.text.secondary}
            align="center"
            sx={{ mb: 3 }}
          >
            Faça o login para acessar sua página
          </Typography>
          <form onSubmit={handleLogin}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  fullWidth
                  required
                  sx={{
                    '& .MuiInputBase-input': {
                      color: theme.palette.text.primary,
                    },
                    '& .MuiInputBase-root': {
                      '& fieldset': {
                        borderColor: theme.palette.divider,
                      },
                      '&:hover fieldset': {
                        borderColor: theme.palette.primary.main,
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: theme.palette.primary.main,
                      },
                    },
                    '& label': {
                      color: theme.palette.text.secondary,
                    },
                    '& label.Mui-focused': {
                      color: theme.palette.primary.main,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Senha"
                  type="password"
                  value={senha}
                  onChange={(e) => setSenha(e.target.value)}
                  fullWidth
                  required
                  sx={{
                    '& .MuiInputBase-input': {
                      color: theme.palette.text.primary,
                    },
                    '& .MuiInputBase-root': {
                      '& fieldset': {
                        borderColor: theme.palette.divider,
                      },
                      '&:hover fieldset': {
                        borderColor: theme.palette.primary.main,
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: theme.palette.primary.main,
                      },
                    },
                    '& label': {
                      color: theme.palette.text.secondary,
                    },
                    '& label.Mui-focused': {
                      color: theme.palette.primary.main,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={lembrarLogin}
                      onChange={(e) => setLembrarLogin(e.target.checked)}
                      color="primary"
                      sx={{
                        '& .MuiSvgIcon-root': {
                          color: theme.palette.text.secondary,
                        },
                        '&.Mui-checked .MuiSvgIcon-root': {
                          color: theme.palette.primary.main,
                        },
                      }}
                    />
                  }
                  label="Lembrar login"
                  sx={{
                    '& .MuiTypography-root': {
                      color: theme.palette.text.secondary,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={{
                    bgcolor: theme.palette.primary.main,
                    color: theme.palette.background.paper,
                    py: 2,
                    px: 4,
                    borderRadius: '50px',
                    position: 'relative',
                    overflow: 'hidden',
                    '&::before': {
                      content: '""',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      background: `linear-gradient(45deg, transparent 0%, rgba(255,255,255,0.2) 50%, transparent 100%)`,
                      transform: 'translateX(-100%)',
                      transition: 'transform 0.6s',
                    },
                    '&:hover': {
                      bgcolor: theme.palette.primary.main,
                      transform: 'scale(1.05)',
                      transition: 'transform 0.3s',
                      '&::before': {
                        transform: 'translateX(100%)',
                      },
                    },
                  }}
                >
                  Entrar
                </Button>
              </Grid>
            </Grid>
          </form>
          {mensagem && (
            <Alert
              sx={{ marginTop: "20px" }}
              severity={mensagem.includes("sucesso") ? "success" : "error"}
              action={
                requiresFirstAccess && (
                  <Button
                    color="inherit"
                    size="small"
                    onClick={handlePrimeiroAcesso}
                  >
                    Ir para Primeiro Acesso
                  </Button>
                )
              }
            >
              {mensagem}
            </Alert>
          )}
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between', gap: 2 }}>
            <Link
              component="button"
              variant="body2"
              onClick={handleEsqueciSenha}
              sx={{ textDecoration: 'none', color: theme.palette.text.secondary }}
            >
              Esqueci minha senha
            </Link>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => navigate("/primeiro-acesso")}
              sx={{ color: theme.palette.text.secondary, borderColor: theme.palette.text.secondary }}
            >
              Primeiro Acesso
            </Button>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default Login;
