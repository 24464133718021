import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    Grid,
    Box,
    Chip,
    Divider,
    Paper,
    CircularProgress,
    useTheme,
    alpha,
} from '@mui/material';
import paymentService from '../services/paymentService';

const PaymentDetailsDialog = ({ open, onClose, payment }) => {
    const theme = useTheme();
    const [customerName, setCustomerName] = useState('');
    const [subscriptionDetails, setSubscriptionDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const [paymentDetails, setPaymentDetails] = useState(null);

    useEffect(() => {
        const fetchDetails = async () => {
            if (!payment) return;

            setLoading(true);
            try {
                // Fetch full payment details only if we have just the ID
                let fullPaymentDetails = payment;
                if (payment.id && Object.keys(payment).length === 1) {
                    try {
                        fullPaymentDetails = await paymentService.getPayment(payment.id);
                        setPaymentDetails(fullPaymentDetails);
                    } catch (error) {
                        console.error('Error fetching payment details:', error);
                    }
                }

                // Handle customer name
                if (fullPaymentDetails.customer) {
                    if (typeof fullPaymentDetails.customer === 'string') {
                        // If customer is an ID, fetch the details
                        const customerData = await paymentService.getCustomer(fullPaymentDetails.customer);
                        setCustomerName(customerData.name);
                    } else if (fullPaymentDetails.customer.name) {
                        // If customer object is already provided
                        setCustomerName(fullPaymentDetails.customer.name);
                    } else if (fullPaymentDetails.customerDetails?.name) {
                        // If customer details are nested
                        setCustomerName(fullPaymentDetails.customerDetails.name);
                    }
                }

                // Handle subscription details
                if (fullPaymentDetails.subscription || (fullPaymentDetails.externalReference && fullPaymentDetails.externalReference.startsWith('subscription-'))) {
                    try {
                        const subscriptionId = fullPaymentDetails.subscription || fullPaymentDetails.externalReference.replace('subscription-', '');
                        const subscriptionData = await paymentService.getSubscription(subscriptionId);
                        setSubscriptionDetails(subscriptionData);
                    } catch (error) {
                        console.error('Error fetching subscription details:', error);
                    }
                }
            } catch (error) {
                console.error('Error fetching details:', error);
                setCustomerName('Nome não disponível');
            } finally {
                setLoading(false);
            }
        };

        if (open) {
            fetchDetails();
        }
    }, [payment, open]);

    // Reset states when dialog is closed
    useEffect(() => {
        if (!open) {
            setCustomerName('');
            setSubscriptionDetails(null);
            setPaymentDetails(null);
            setLoading(false);
        }
    }, [open]);

    if (!payment) return null;

    const formatDate = (dateString) => {
        if (!dateString) return null;
        return new Date(dateString).toLocaleDateString('pt-BR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
    };

    const getStatusColor = (status) => {
        const statusColors = {
            PENDING: theme.palette.warning.main,
            RECEIVED: theme.palette.success.main,
            CONFIRMED: theme.palette.success.main,
            OVERDUE: theme.palette.error.main,
            CANCELLED: theme.palette.grey[500],
            REFUNDED: theme.palette.purple?.[400] || '#7e57c2',
            FAILED: theme.palette.error.main,
            RECEIVED_IN_CASH: theme.palette.success.main,
            REFUND_REQUESTED: theme.palette.warning.main,
            CHARGEBACK_REQUESTED: theme.palette.error.main,
            CHARGEBACK_DISPUTE: theme.palette.error.main,
            AWAITING_CHARGEBACK_REVERSAL: theme.palette.warning.main,
            DUNNING_REQUESTED: theme.palette.warning.main,
            DUNNING_RECEIVED: theme.palette.success.main,
            AWAITING_RISK_ANALYSIS: theme.palette.warning.main
        };
        return statusColors[status] || theme.palette.grey[500];
    };

    const displayPayment = paymentDetails || payment;

    const getPaymentStatus = () => {
        if (displayPayment.invoiceUrl) {
            return 'Aguardando pagamento';
        }
        return 'Aguardando fatura';
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
            PaperProps={{
                sx: {
                    borderRadius: 2,
                    boxShadow: theme.shadows[4]
                }
            }}
        >
            <DialogTitle sx={{
                borderBottom: `1px solid ${theme.palette.divider}`,
                pb: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
            }}>
                <Typography variant="h6" component="div" sx={{ fontWeight: 600 }}>
                    Detalhes do Pagamento
                </Typography>
                <Chip
                    label={displayPayment.status === 'PENDING' ? getPaymentStatus() : paymentService.formatPaymentStatus(displayPayment.status)}
                    sx={{
                        backgroundColor: getStatusColor(displayPayment.status),
                        color: theme.palette.getContrastText(getStatusColor(displayPayment.status)),
                        fontWeight: 500
                    }}
                />
            </DialogTitle>
            <DialogContent sx={{ pt: 3 }}>
                <Paper elevation={0} sx={{
                    p: 3,
                    bgcolor: alpha(theme.palette.background.default, 0.6),
                    borderRadius: 2,
                    mb: 3
                }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                Cliente
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, minHeight: '32px' }}>
                                {loading ? (
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <CircularProgress size={20} />
                                        <Typography>Carregando...</Typography>
                                    </Box>
                                ) : (
                                    <Typography variant="h6" sx={{ fontWeight: 500 }}>
                                        {customerName || displayPayment.customerDetails?.name || 'Nome não disponível'}
                                    </Typography>
                                )}
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                Valor
                            </Typography>
                            <Typography variant="h5" sx={{ color: theme.palette.success.main, fontWeight: 600 }}>
                                R$ {Number(displayPayment.value || 0).toFixed(2)}
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>

                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Box sx={{ mb: 3 }}>
                            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                ID do Pagamento
                            </Typography>
                            <Typography variant="body1" sx={{ fontFamily: 'monospace' }}>
                                {displayPayment.id}
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid item xs={6}>
                        <Box sx={{ mb: 3 }}>
                            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                Forma de Pagamento
                            </Typography>
                            <Typography variant="body1" sx={{ fontWeight: 500 }}>
                                {paymentService.formatBillingType(displayPayment.billingType)}
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid item xs={6}>
                        <Box sx={{ mb: 3 }}>
                            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                Data de Vencimento
                            </Typography>
                            <Typography variant="body1">
                                {formatDate(displayPayment.dueDate)}
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid item xs={6}>
                        <Box sx={{ mb: 3 }}>
                            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                Data de Pagamento
                            </Typography>
                            <Typography variant="body1">
                                {displayPayment.paymentDate ? formatDate(displayPayment.paymentDate) : '-'}
                            </Typography>
                        </Box>
                    </Grid>

                    {displayPayment.externalReference && (
                        <Grid item xs={12}>
                            <Box sx={{ mb: 3 }}>
                                <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                    Referência Externa
                                </Typography>
                                <Typography variant="body1" sx={{ fontFamily: 'monospace' }}>
                                    {displayPayment.externalReference}
                                </Typography>
                            </Box>
                        </Grid>
                    )}

                    {subscriptionDetails && (
                        <>
                            <Grid item xs={12}>
                                <Divider sx={{ my: 2 }} />
                                <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 2 }}>
                                    Detalhes da Assinatura
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Box sx={{ mb: 3 }}>
                                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                        ID da Assinatura
                                    </Typography>
                                    <Typography variant="body1" sx={{ fontFamily: 'monospace' }}>
                                        {subscriptionDetails.id}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box sx={{ mb: 3 }}>
                                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                        Status da Assinatura
                                    </Typography>
                                    <Chip
                                        label={paymentService.formatPaymentStatus(subscriptionDetails.status)}
                                        sx={{
                                            backgroundColor: getStatusColor(subscriptionDetails.status),
                                            color: theme.palette.getContrastText(getStatusColor(subscriptionDetails.status)),
                                            fontWeight: 500
                                        }}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box sx={{ mb: 3 }}>
                                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                        Próximo Vencimento
                                    </Typography>
                                    <Typography variant="body1">
                                        {formatDate(subscriptionDetails.nextDueDate)}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box sx={{ mb: 3 }}>
                                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                        Data Final
                                    </Typography>
                                    <Typography variant="body1">
                                        {subscriptionDetails.endDate ? formatDate(subscriptionDetails.endDate) : '-'}
                                    </Typography>
                                </Box>
                            </Grid>
                        </>
                    )}

                    {displayPayment.description && (
                        <Grid item xs={12}>
                            <Divider sx={{ my: 2 }} />
                            <Box sx={{ mb: 3 }}>
                                <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                    Descrição
                                </Typography>
                                <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap' }}>
                                    {displayPayment.description}
                                </Typography>
                            </Box>
                        </Grid>
                    )}

                    {displayPayment.invoiceUrl && (
                        <Grid item xs={12}>
                            <Box sx={{ mb: 2 }}>
                                <Button
                                    href={displayPayment.invoiceUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    variant="contained"
                                    color="primary"
                                    sx={{
                                        borderRadius: 2,
                                        textTransform: 'none',
                                        fontWeight: 500
                                    }}
                                >
                                    Abrir Fatura
                                </Button>
                            </Box>
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions sx={{ p: 2, borderTop: `1px solid ${theme.palette.divider}` }}>
                <Button
                    onClick={onClose}
                    variant="outlined"
                    color="primary"
                    sx={{
                        borderRadius: 2,
                        textTransform: 'none',
                        fontWeight: 500
                    }}
                >
                    Fechar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PaymentDetailsDialog;
