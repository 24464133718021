import React, { useState, useRef, useEffect } from 'react';
import { TextField, IconButton, Tooltip } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { isMobile } from 'react-device-detect';

const SearchInput = ({ value, onChange, disabled }) => {
    const [localValue, setLocalValue] = useState(value);
    const inputRef = useRef(null);

    useEffect(() => {
        setLocalValue(value);
    }, [value]);

    const handleChange = (e) => {
        const newValue = e.target.value;
        setLocalValue(newValue);
        onChange(newValue); // Immediate update since filtering is now client-side
    };

    return (
        <Tooltip title="Buscar por nome, email ou documento" placement="top">
            <TextField
                key="search-input"
                inputRef={inputRef}
                label="Buscar cliente"
                placeholder="Nome, email ou documento"
                variant="outlined"
                size="small"
                value={localValue}
                onChange={handleChange}
                disabled={disabled}
                sx={{
                    flex: isMobile ? 1 : "2 1 250px",
                    "& .MuiOutlinedInput-root": {
                        "&:hover fieldset": {
                            borderColor: localValue ? "primary.main" : undefined,
                        },
                    },
                }}
                InputProps={{
                    endAdornment: localValue && (
                        <IconButton
                            size="small"
                            onClick={() => {
                                setLocalValue("");
                                onChange("");
                            }}
                            disabled={disabled}
                        >
                            <ClearIcon fontSize="small" />
                        </IconButton>
                    ),
                }}
            />
        </Tooltip>
    );
};

export default React.memo(SearchInput, (prev, next) =>
    prev.value === next.value && prev.disabled === next.disabled
);
