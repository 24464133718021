import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Button,
  Stack,
} from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonIcon from "@mui/icons-material/Person";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AssignmentIcon from "@mui/icons-material/Assignment";
import LogoutIcon from "@mui/icons-material/Logout";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Payments as PaymentIcon } from '@mui/icons-material';
import ChecklistIcon from '@mui/icons-material/Checklist';
import logoDark from '../assets/logos/Logo Preto com Laranja.svg';
import logoLight from '../assets/logos/Logo Branco com Laranja.svg';
import { isUserAdmin } from "../utils/auth";
import ThemeToggle from './ThemeToggle';
import { useTheme } from '../contexts/ThemeContext';

const drawerWidth = 240;

const Menu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const admin = isUserAdmin();
  const { mode } = useTheme();

  if (!admin) return null;

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  const menuItems = [
    { text: "Dashboard", icon: <DashboardIcon />, path: "/dashboard" },
    { text: "Clientes", icon: <PersonIcon />, path: "/cadastro-clientes" },
    { text: "Pedidos", icon: <ShoppingCartIcon />, path: "/cadastro-pedidos" },
    { text: "Pagamentos", icon: <PaymentIcon />, path: "/payments" },
    { text: "Tarefas", icon: <ChecklistIcon />, path: "/todos" },
    { text: "Serviços", icon: <AssignmentIcon />, path: "/cadastro-servicos" },
    { text: "Zoom+", icon: <AddCircleOutlineIcon />, path: "/zoom-plus" },
  ];

  return (
    <Box
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        position: "fixed",
        height: "100vh",
        backgroundColor: "background.paper",
        borderRight: 1,
        borderColor: "divider",
        display: "flex",
        flexDirection: "column",
        zIndex: 1201,
      }}
    >
      <Box
        sx={{
          padding: 2,
          textAlign: "center",
          borderBottom: 1,
          borderColor: "divider"
        }}
      >
        <img
          src={mode === 'dark' ? logoLight : logoDark}
          alt="Logo"
          style={{ width: 150, objectFit: 'contain', paddingTop: 10 }}
        />
      </Box>
      <List>
        {menuItems.map((item) => (
          <ListItem
            key={item.text}
            disablePadding
            selected={location.pathname === item.path}
          >
            <ListItemButton
              onClick={() => navigate(item.path)}
              sx={{
                '&.Mui-selected': {
                  backgroundColor: 'primary.light',
                },
                '&:hover': {
                  backgroundColor: 'action.hover',
                },
              }}
            >
              <ListItemIcon sx={{ color: location.pathname === item.path ? "primary.main" : "inherit" }}>
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={item.text}
                primaryTypographyProps={{
                  color: location.pathname === item.path ? "primary" : "inherit",
                  fontWeight: location.pathname === item.path ? "bold" : "normal",
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Box sx={{ flexGrow: 1 }} />
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="center"
        sx={{ p: 2 }}
      >
        <Button
          variant="text"
          startIcon={<LogoutIcon />}
          sx={{
            color: 'text.secondary',
            transition: 'all 0.3s ease',
            borderRadius: '8px',
            padding: '8px 16px',
            '&:hover': {
              backgroundColor: 'action.hover',
              transform: 'translateY(-1px)',
              color: 'text.primary'
            }
          }}
          onClick={handleLogout}
        >
          Sair
        </Button>
        <ThemeToggle />
      </Stack>
    </Box>
  );
};

export default Menu;
