import React, { useState, useEffect, useRef } from 'react';
import {
    Button,
    Typography,
    Container,
    Box,
    useTheme,
    Grid,
} from '@mui/material';
import {
    ArrowForward,
    Camera,
    PlayCircle,
    Star,
    MenuBook,
    CameraEnhance,
    ThreeSixty,
    Login
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import logoHorizontal from '../assets/logos/Logo Branco com Laranja.svg';
import logoSimbolo from '../assets/logos/simbolo laranja.png';

const LandingPage = () => {
    const [isVisible, setIsVisible] = useState(false);
    const navigate = useNavigate();
    const heroRef = useRef(null);

    useEffect(() => {
        setIsVisible(true);

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('animate-in');
                    }
                });
            },
            { threshold: 0.1 }
        );

        document.querySelectorAll('.animate-on-scroll').forEach((el) => {
            observer.observe(el);
        });

        return () => observer.disconnect();
    }, []);

    const handleWhatsAppClick = () => {
        window.open('https://wa.me/5537991867595', '_blank');
    };

    const services = [
        {
            icon: Camera,
            title: "Fotografia Profissional",
            description: "Fotos de alta qualidade que destacam os detalhes e diferenciais do seu produto.",
            gradient: "linear-gradient(135deg, #eb5b25 0%, #ff8f6b 100%)"
        },
        {
            icon: PlayCircle,
            title: "Mercado Livre Clips",
            description: "Vídeos curtos e dinâmicos otimizados para o Mercado Livre, aumentando em 5x as visitas aos seus anúncios.",
            gradient: "linear-gradient(135deg, #ff8f6b 0%, #ffa07d 100%)"
        },
        {
            icon: Star,
            title: "Clube de Assinatura",
            description: "Benefícios exclusivos com descontos, prazos reduzidos e atendimento prioritário para parceiros mensais.",
            gradient: "linear-gradient(135deg, #ffa07d 0%, #ffb38f 100%)"
        },
        {
            icon: MenuBook,
            title: "Catálogos Digitais",
            description: "Layouts profissionais e atrativos para seu catálogo digital, com organização que valoriza cada produto da sua coleção.",
            gradient: "linear-gradient(135deg, #eb5b25 0%, #ff8f6b 100%)"
        },
        {
            icon: CameraEnhance,
            title: "Fotos Ambientadas",
            description: "Fotografias em cenários que valorizam seu produto e criam conexão emocional com seu público-alvo.",
            gradient: "linear-gradient(135deg, #ff8f6b 0%, #ffa07d 100%)"
        },
        {
            icon: ThreeSixty,
            title: "Vídeos 360°",
            description: "Apresentação completa do produto com rotação 360° para melhor visualização, perfeito para e-commerce.",
            gradient: "linear-gradient(135deg, #ffa07d 0%, #ffb38f 100%)"
        }
    ];

    return (
        <Box sx={{
            minHeight: '100vh',
            bgcolor: '#121212',
            color: 'white',
            overflow: 'hidden'
        }}>
            {/* Minimal Top Bar */}
            <Box sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                zIndex: 1000,
                background: 'rgba(18, 18, 18, 0.8)',
                backdropFilter: 'blur(10px)',
                borderBottom: '1px solid rgba(255, 110, 64, 0.1)',
                py: 4
            }}>
                <Container sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}>
                    <Box
                        component="img"
                        src={logoHorizontal}
                        alt="Zoompro Studio"
                        sx={{
                            height: '40px',
                            filter: 'brightness(1.2)',
                            transition: 'transform 0.3s',
                            '&:hover': {
                                transform: 'scale(1.05)'
                            }
                        }}
                    />
                    <Button
                        onClick={() => navigate('/login')}
                        startIcon={<Login />}
                        sx={{
                            color: '#eb5b25',
                            borderRadius: '50px',
                            px: 3,
                            border: '2px solid #eb5b25',
                            '&:hover': {
                                bgcolor: '#eb5b25',
                                color: 'white'
                            }
                        }}
                    >
                        Login
                    </Button>
                </Container>
            </Box>

            {/* Hero Section */}
            <Container ref={heroRef} sx={{
                pt: 20,
                pb: 15,
                position: 'relative'
            }}>

                {/* Animated background gradients */}
                <Box sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    overflow: 'hidden',
                    '&::before': {
                        content: '""',
                        position: 'absolute',
                        width: '150%',
                        height: '150%',
                        background: 'radial-gradient(circle, #eb5b25 0%, transparent 50%)',
                        opacity: 0.15,
                        animation: 'rotate 20s linear infinite',
                        top: '-25%',
                        left: '-25%'
                    },
                    '&::after': {
                        content: '""',
                        position: 'absolute',
                        width: '150%',
                        height: '150%',
                        background: 'radial-gradient(circle, #222224 0%, transparent 40%)',
                        opacity: 0.2,
                        animation: 'rotate 15s linear infinite reverse',
                        top: '-25%',
                        left: '-25%'
                    },
                    '@keyframes rotate': {
                        '0%': { transform: 'rotate(0deg)' },
                        '100%': { transform: 'rotate(360deg)' }
                    }
                }} />

                {/* Main Content */}
                <Box sx={{
                    position: 'relative',
                    zIndex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center'
                }}>
                    <Box
                        component="img"
                        src={logoSimbolo}
                        sx={{
                            width: '200px',
                            height: '200px',
                            transform: isVisible ? 'translateY(0)' : 'translateY(-40px)',
                            display: { xs: 'block', md: 'block' },
                            opacity: isVisible ? 1 : 0,
                            transition: 'all 1s cubic-bezier(0.4, 0, 0.2, 1)'
                        }}
                    >
                    </Box>
                    <Typography
                        variant="h1"
                        sx={{
                            fontSize: { xs: '2.5rem', md: '4.5rem' },
                            fontWeight: 900,
                            background: 'linear-gradient(45deg, #eb5b25 30%, #ff8f6b 90%)',
                            backgroundClip: 'text',
                            textFillColor: 'transparent',
                            mb: 3,
                            transform: isVisible ? 'translateY(0)' : 'translateY(40px)',
                            opacity: isVisible ? 1 : 0,
                            transition: 'all 1s cubic-bezier(0.4, 0, 0.2, 1)',
                            fontFamily: '"Poppins", sans-serif',
                            lineHeight: 1.2
                        }}
                    >
                        Eleve seu E-commerce ao
                        <br />
                        Próximo Nível
                    </Typography>

                    <Typography
                        variant="h4"
                        className="animate-on-scroll"
                        sx={{
                            color: 'rgba(255,255,255,0.9)',
                            mb: 6,
                            maxWidth: '800px',
                            opacity: 0,
                            transform: 'translateY(20px)',
                            '&.animate-in': {
                                opacity: 1,
                                transform: 'translateY(0)',
                                transition: 'all 0.8s cubic-bezier(0.4, 0, 0.2, 1) 0.3s'
                            },
                            fontFamily: '"Inter", sans-serif',
                            lineHeight: 1.5
                        }}
                    >
                        Fotografias profissionais que transformam produtos em experiências visuais únicas
                    </Typography>

                    <Button
                        onClick={handleWhatsAppClick}
                        variant="contained"
                        endIcon={<ArrowForward />}
                        sx={{
                            bgcolor: '#eb5b25',
                            color: 'white',
                            py: 2,
                            px: 4,
                            fontSize: '1.2rem',
                            borderRadius: '50px',
                            position: 'relative',

                            transform: isVisible ? 'translateY(0)' : 'translateY(-40px)',
                            opacity: isVisible ? 1 : 0,
                            transition: 'all 0.8s cubic-bezier(0.4, 0, 0.2, 1) 0.3s',

                            overflow: 'hidden',
                            '&::before': {
                                content: '""',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                background: 'linear-gradient(45deg, transparent 0%, rgba(255,255,255,0.2) 50%, transparent 100%)',
                                transform: 'translateX(-100%)',
                                transition: 'transform 0.6s'
                            },
                            '&:hover': {
                                bgcolor: '#eb5b25',
                                transform: 'scale(1.05)',
                                transition: 'transform 0.3s',
                                '&::before': {
                                    transform: 'translateX(100%)'
                                }
                            }
                        }}
                    >
                        Comece Agora
                    </Button>
                </Box>
            </Container>

            {/* Stats Section with Glassmorphism */}
            <Box sx={{
                py: 12,
                background: 'linear-gradient(180deg, #121212 0%, #1a1a1a 100%)',
                position: 'relative',
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: 'radial-gradient(circle at 50% 50%, rgba(255,110,64,0.1) 0%, transparent 70%)',
                }
            }}>
                <Container>
                    <Box sx={{
                        display: 'grid',
                        gridTemplateColumns: { xs: '1fr', md: 'repeat(4, 1fr)' },
                        gap: 4
                    }}>
                        {[
                            { number: '200+', text: 'Clientes Satisfeitos' },
                            { number: '6+', text: 'Anos de Experiência' },
                            { number: '7 Dias', text: 'Prazo de Entrega' },
                            { number: '+10 mil', text: 'Imagens Entregues' }
                        ].map((stat, index) => (
                            <Box
                                key={index}
                                className="animate-on-scroll"
                                sx={{
                                    textAlign: 'center',
                                    p: 4,
                                    borderRadius: '20px',
                                    background: 'rgba(255,255,255,0.03)',
                                    backdropFilter: 'blur(10px)',
                                    border: '1px solid rgba(255,255,255,0.1)',
                                    opacity: 0,
                                    transform: 'translateY(20px)',
                                    '&.animate-in': {
                                        opacity: 1,
                                        transform: 'translateY(0)',
                                        transition: `all 0.8s cubic-bezier(0.4, 0, 0.2, 1) ${index * 0.2}s`
                                    },
                                    '&:hover': {
                                        transform: 'translateY(-5px)',
                                        transition: 'transform 0.3s'
                                    }
                                }}
                            >
                                <Typography
                                    variant="h3"
                                    sx={{
                                        color: '#eb5b25',
                                        fontWeight: 'bold',
                                        mb: 1,
                                        fontFamily: '"Poppins", sans-serif'
                                    }}
                                >
                                    {stat.number}
                                </Typography>
                                <Typography
                                    sx={{
                                        color: 'rgba(255,255,255,0.9)',
                                        fontFamily: '"Inter", sans-serif'
                                    }}
                                >
                                    {stat.text}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                </Container>
            </Box>

            {/* Services Section */}
            <Box sx={{
                py: 15,
                background: '#161616',
                position: 'relative',
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: 'radial-gradient(circle at 0% 0%, rgba(255,110,64,0.1) 0%, transparent 50%)',
                    pointerEvents: 'none'
                }
            }}>
                <Container>
                    <Typography
                        variant="h2"
                        align="center"
                        sx={{
                            fontFamily: '"Poppins", sans-serif',
                            fontWeight: 800,
                            mb: 2,
                            background: 'linear-gradient(45deg, #eb5b25 30%, #ff8f6b 90%)',
                            backgroundClip: 'text',
                            textFillColor: 'transparent',
                        }}
                    >
                        Nossos Serviços
                    </Typography>
                    <Typography
                        variant="h5"
                        align="center"
                        sx={{
                            color: 'rgba(255,255,255,0.7)',
                            mb: 8,
                            fontFamily: '"Inter", sans-serif'
                        }}
                    >
                        Soluções completas para impulsionar suas vendas
                    </Typography>

                    <Grid container spacing={4}>
                        {services.map((service, index) => (
                            <Grid item xs={12} md={4} key={index}>
                                <Box
                                    className="animate-on-scroll"
                                    sx={{
                                        p: 4,
                                        borderRadius: '24px',
                                        background: 'rgba(255,255,255,0.03)',
                                        backdropFilter: 'blur(10px)',
                                        border: '1px solid rgba(255,255,255,0.1)',
                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        position: 'relative',
                                        overflow: 'hidden',
                                        opacity: 0,
                                        transform: 'translateY(20px)',
                                        '&.animate-in': {
                                            opacity: 1,
                                            transform: 'translateY(0)',
                                            transition: `all 0.8s cubic-bezier(0.4, 0, 0.2, 1) ${index * 0.2}s`
                                        },
                                        '&:hover': {
                                            transform: 'translateY(-5px)',
                                            transition: 'all 0.3s',
                                            '& .service-icon': {
                                                transform: 'scale(1.1) rotate(5deg)',
                                                background: service.gradient,
                                                '& svg': {
                                                    color: 'white'
                                                }
                                            }
                                        }
                                    }}
                                >
                                    <Box
                                        className="service-icon"
                                        sx={{
                                            width: 80,
                                            height: 80,
                                            borderRadius: '20px',
                                            background: 'rgba(255,110,64,0.1)',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            mb: 3,
                                            transition: 'all 0.3s ease',
                                        }}
                                    >
                                        <service.icon sx={{
                                            fontSize: 40,
                                            color: '#eb5b25',
                                            transition: 'color 0.3s ease'
                                        }} />
                                    </Box>
                                    <Typography
                                        variant="h5"
                                        sx={{
                                            fontFamily: '"Poppins", sans-serif',
                                            fontWeight: 600,
                                            mb: 2,
                                            color: 'white'
                                        }}
                                    >
                                        {service.title}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            color: 'rgba(255,255,255,0.7)',
                                            fontFamily: '"Inter", sans-serif',
                                            lineHeight: 1.6
                                        }}
                                    >
                                        {service.description}
                                    </Typography>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </Box>

            {/* Call to Action Section */}
            <Box sx={{
                py: 15,
                background: 'linear-gradient(135deg, #121212 0%, #1a1a1a 100%)',
                position: 'relative',
                overflow: 'hidden'
            }}>
                <Container maxWidth="lg">
                    <Box
                        sx={{
                            position: 'relative',
                            zIndex: 1,
                            textAlign: 'center',
                            '&::before': {
                                content: '""',
                                position: 'absolute',
                                top: '-100%',
                                left: '-50%',
                                width: '200%',
                                height: '200%',
                                background: 'radial-gradient(circle, rgba(255,110,64,0.1) 0%, transparent 50%)',
                                transform: 'rotate(-45deg)',
                                zIndex: -1
                            }
                        }}
                    >
                        <Typography
                            variant="h2"
                            className="animate-on-scroll"
                            sx={{
                                fontFamily: '"Poppins", sans-serif',
                                fontWeight: 800,
                                mb: 3,
                                background: 'linear-gradient(45deg, #eb5b25 30%, #ff8f6b 90%)',
                                backgroundClip: 'text',
                                textFillColor: 'transparent',
                                opacity: 0,
                                transform: 'translateY(20px)',
                                '&.animate-in': {
                                    opacity: 1,
                                    transform: 'translateY(0)',
                                    transition: 'all 0.8s cubic-bezier(0.4, 0, 0.2, 1)'
                                }
                            }}
                        >
                            Pronto para Transformar seu E-commerce?
                        </Typography>
                        <Typography
                            variant="h5"
                            className="animate-on-scroll"
                            sx={{
                                color: 'rgba(255,255,255,0.7)',
                                mb: 6,
                                maxWidth: '800px',
                                mx: 'auto',
                                fontFamily: '"Inter", sans-serif',
                                opacity: 0,
                                transform: 'translateY(20px)',
                                '&.animate-in': {
                                    opacity: 1,
                                    transform: 'translateY(0)',
                                    transition: 'all 0.8s cubic-bezier(0.4, 0, 0.2, 1) 0.2s'
                                }
                            }}
                        >
                            Entre em contato agora e descubra como podemos elevar a qualidade visual do seu negócio
                        </Typography>
                        <Button
                            onClick={handleWhatsAppClick}
                            variant="contained"
                            endIcon={<ArrowForward />}
                            className="animate-on-scroll"
                            sx={{
                                bgcolor: '#eb5b25',
                                color: 'white',
                                py: 2,
                                px: 6,
                                fontSize: '1.2rem',
                                borderRadius: '50px',
                                position: 'relative',
                                overflow: 'hidden',
                                opacity: 0,
                                transform: 'translateY(20px)',
                                '&.animate-in': {
                                    opacity: 1,
                                    transform: 'translateY(0)',
                                    transition: 'all 0.8s cubic-bezier(0.4, 0, 0.2, 1) 0.4s'
                                },
                                '&::before': {
                                    content: '""',
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    background: 'linear-gradient(45deg, transparent 0%, rgba(255,255,255,0.2) 50%, transparent 100%)',
                                    transform: 'translateX(-100%)',
                                    transition: 'transform 0.6s'
                                },
                                '&:hover': {
                                    bgcolor: '#eb5b25',
                                    transform: 'scale(1.05)',
                                    transition: 'transform 0.3s',
                                    '&::before': {
                                        transform: 'translateX(100%)'
                                    }
                                }
                            }}
                        >
                            Fale Conosco
                        </Button>
                    </Box>
                </Container>
            </Box>

            {/* Footer */}
            <Box sx={{
                py: 8,
                bgcolor: '#0a0a0a',
                borderTop: '1px solid rgba(255,255,255,0.1)'
            }}>
                <Container>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        textAlign: { xs: 'center', md: 'left' }
                    }}>
                        <Box
                            component="img"
                            src={logoHorizontal}
                            alt="Zoompro Studio"
                            sx={{
                                height: '30px',
                                mb: { xs: 3, md: 0 },
                                filter: 'brightness(1.2)'
                            }}
                        />
                        <Typography
                            sx={{
                                color: 'rgba(255,255,255,0.5)',
                                fontFamily: '"Inter", sans-serif'
                            }}
                        >
                            © 2024 Zoompro Studio. Todos os direitos reservados.
                        </Typography>
                    </Box>
                </Container>
            </Box>
        </Box>
    );
};

export default LandingPage;